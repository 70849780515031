import * as React from 'react';

import { AlertOutlined } from '@ant-design/icons';
import { PageWrapper } from '@components/pageWrapper';

export const NotAuthorizedPage: React.FC<unknown> = (props) => {

    return (
        <PageWrapper
            isNonIdeal={true}
            nonIdealHeader="Unauthorized"
            nonIdealSubheader="You don't have the required permissions to be here"
            nonIdealIconType={<AlertOutlined />}
            nonIdealActions={['back', 'home']}
        />
    );
};