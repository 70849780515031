import * as React from 'react';

import { authenticate, clearUserFacilities, getUserFacilities } from '@/store/modules';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

export const useAuthenticate = (): void => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector((state) => state.security.isAuthenticated);

    React.useEffect(() => {
        const authAsync = async (): Promise<void> => {
            if (isAuthenticated) {
                await dispatch(authenticate());

                await dispatch(getUserFacilities());
            } else {
                dispatch(clearUserFacilities());
            }
        };

        authAsync();
    }, [isAuthenticated]);
};