import * as React from 'react';

import { Alert, Col, Row, notification } from 'antd';
import { Badge, Badge2 } from '@/components/badge';
import { CheckCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { createEmployeeVisit, createExternalVisitorVisit } from '@/store/modules/visitModule';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AnimatedHeader } from '@/components/animatedHeader';
import { AppButton } from '@/components/appButton';
import { EmployeeVisit } from 'drawbridge.shared/models/dataModels/employeeVisit';
import { ExternalVisitorVisit } from 'drawbridge.shared/models/dataModels/externalVisitorVisit';
import { Guest } from 'drawbridge.shared/models/fortinet/guest';
import { PageWrapper } from '@/components/pageWrapper';
import { WelcomeHeader } from '@/components/pageHeader';
import { setIsSignedIn } from '@/store/modules/signInModule';
import { useApiErrorMessage } from '@/hooks/useApiErrorMessage';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';

interface SignedInPageProps {

}

export const SignedInPage: React.FC<SignedInPageProps> = (props) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useApiErrorMessage();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const appRoutes = useAppRoutes();

    const deviceConfig = useAppSelector((state) => state.deviceConfig.deviceConfig);
    const visitor = useAppSelector((state) => state.signIn.visitor);
    const visitDetails = useAppSelector((state) => state.signIn.visitDetails);
    const isPerformingWork = useAppSelector((state) => state.signIn.isPerformingWork);
    const safetyQuestionnaire = useAppSelector((state) => state.signIn.safetyQuestionnaire);
    const isRequestingGuestWifi = useAppSelector((state) => state.signIn.isRequestingGuestWifi);
    const facilityId = useAppSelector((state) => state.deviceConfig.deviceConfig?.facilityId);
    const isSignedIn = useAppSelector((state) => state.signIn.isSignedIn);

    const [isLoading, setIsLoading] = React.useState(false);
    const [visit, setVisit] = React.useState<EmployeeVisit | ExternalVisitorVisit | undefined>(undefined);
    const [guest, setGuest] = React.useState<Guest | undefined>(undefined);

    const badgeRef = React.useRef<HTMLDivElement>(null);

    const handlePrint = useReactToPrint({
        content: () => badgeRef.current,
    });

    React.useEffect(() => {
        const signInAsync = async (): Promise<void> => {
            if (isSignedIn || !visitDetails || !facilityId) {
                // If we already signed in on mount, or if we're missing required data, just go home
                navigate(appRoutes.home.path, { replace: true });

                return;
            }

            setIsLoading(true);

            if (visitor?.visitorType === 'employee') {
                const action = await dispatch(createEmployeeVisit({
                    employee: visitor,
                    visitDetails: visitDetails,
                    facilityId: facilityId,
                }));

                if (createEmployeeVisit.fulfilled.match(action)) {
                    setVisit(action.payload);
                    dispatch(setIsSignedIn(true));
                } else {
                    notification.error({
                        message: t('Pages.SignedInPage.error_header'),
                        description: getErrorMessage(action.payload),
                    });
                }

            } else if (visitor?.visitorType === 'external' && isPerformingWork !== undefined) {
                const action = await dispatch(createExternalVisitorVisit({
                    externalVisitor: visitor,
                    isPerformingWork: isPerformingWork,
                    visitDetails: visitDetails,
                    safetyQuestionnaire: safetyQuestionnaire,
                    facilityId: facilityId,
                    isRequestingGuestWifi: isRequestingGuestWifi,
                }));

                if (createExternalVisitorVisit.fulfilled.match(action)) {
                    setVisit(action.payload.visit);
                    setGuest(action.payload.guest);

                    dispatch(setIsSignedIn(true));
                } else {
                    notification.error({
                        message: t('Pages.SignedInPage.error_header'),
                        description: getErrorMessage(action.payload),
                    });
                }
            }

            setIsLoading(false);
        };

        signInAsync();
    }, []);

    return (
        <PageWrapper
            pageTitle={<WelcomeHeader />}
            isLoading={isLoading}
            loadingMessage={t('Pages.SignedInPage.loading_message')}
        >
            {isSignedIn && !!visit?.visitor &&
                <Row
                    align="middle"
                    justify="space-around"
                    gutter={20}
                >
                    <Col span={24}>
                        <AnimatedHeader>
                            {t('Pages.SignedInPage.header')}
                        </AnimatedHeader>
                    </Col>

                    {(deviceConfig?.badgeType === undefined || deviceConfig.badgeType === 'original') &&
                        <Col>
                            <div
                                style={{
                                    width: '4in',
                                    height: '4.5in',
                                }}
                            >
                                <Badge
                                    ref={badgeRef}
                                    visit={visit}
                                    guest={guest}
                                    isPerformingWork={isPerformingWork}
                                />
                            </div>
                        </Col>
                    }
                    {deviceConfig?.badgeType === 'long' &&
                        <Col>
                            <Badge2
                                ref={badgeRef}
                                visit={visit}
                                guest={guest}
                                isPerformingWork={isPerformingWork}
                            />
                        </Col>
                    }

                    {isRequestingGuestWifi && !guest && !isLoading &&
                        // Guest Wifi was requested, but no guest was returned: something went wrong
                        <Col span={24}>
                            <Row
                                align="middle"
                                justify="center"
                            >
                                <Col
                                    xxl={12}
                                    xl={16}
                                    lg={18}
                                    md={18}
                                    sm={24}
                                    xs={24}
                                >
                                    <Alert
                                        message={t('Common.error')}
                                        description={t('Pages.SignedInPage.error_obtaining_guest_wifi_message')}
                                        showIcon={true}
                                        type="error"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    }

                    <Col span={24}>
                        <Row
                            align="middle"
                            justify="space-around"
                        >
                            {deviceConfig?.isPrintingEnabled === true &&
                                <Col
                                    style={{ marginTop: 20 }}
                                    xxl={8}
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={24}
                                    xs={24}
                                >
                                    <AppButton
                                        icon={<PrinterOutlined />}
                                        style={{ width: '100%' }}
                                        onClick={() => handlePrint()}
                                    >
                                        {t('Pages.SignedInPage.print_badge_button_text')}
                                    </AppButton>
                                </Col>
                            }

                            <Col
                                style={{ marginTop: 20 }}
                                xxl={8}
                                xl={12}
                                lg={12}
                                md={12}
                                sm={24}
                                xs={24}
                            >
                                <AppButton
                                    icon={<CheckCircleOutlined />}
                                    style={{ width: '100%' }}
                                    onClick={() => navigate(appRoutes.home.path)}
                                >
                                    {t('Pages.SignedInPage.done_button_text')}
                                </AppButton>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            }
        </PageWrapper>
    );
};