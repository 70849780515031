import './agreementPage.less';

import * as React from 'react';

import { AimOutlined, CheckCircleOutlined, CloseCircleOutlined, EnvironmentOutlined, HeartOutlined, MedicineBoxOutlined, NotificationOutlined, SafetyOutlined, SolutionOutlined } from '@ant-design/icons';
import { Alert, Card, Col, ColProps, Modal, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AnimatedHeader } from '@/components/animatedHeader';
import { AppButton } from '@/components/appButton';
import { PageWrapper } from '@/components/pageWrapper';
import { WelcomeHeader } from '@/components/pageHeader';
import { Zoom } from 'react-awesome-reveal';
import { setHasAcceptedGlobalDisclaimer } from '@/store/modules/signInModule';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface AgreementPageProps {

}

export const AgreementPage: React.FC<AgreementPageProps> = (props) => {
    const { t } = useTranslation();

    const appRoutes = useAppRoutes();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const visitor = useAppSelector((state) => state.signIn.visitor);
    const visitDetails = useAppSelector((state) => state.signIn.visitDetails);

    React.useEffect(() => {
        if (!visitor || !visitDetails) {
            navigate(appRoutes.home.path, { replace: true });
        }
    }, []);

    const policyColProps: ColProps = {
        xxl: 12,
        xl: 12,
        lg: 12,
        md: 12,
        sm: 24,
        xs: 24,
    };

    return (
        <PageWrapper
            pageTitle={<WelcomeHeader />}
            containerClassName="agreement-page"
            scrollBehaviorOnMount="top"
        >
            <Row
                align="middle"
                justify="center"
                gutter={20}
            >
                <Col
                    span={24}
                >
                    <AnimatedHeader>
                        {t('Pages.AgreementPage.header')}
                    </AnimatedHeader>
                </Col>


                <Col span={24}>
                    <Alert
                        type="warning"
                        message={<span style={{ fontWeight: 'bold' }}>{t('Pages.AgreementPage.info_main_text')}</span>}
                        description={t('Pages.AgreementPage.info_sub_text')}
                        style={{ textAlign: 'center' }}
                    />
                </Col>

                <Col span={24}>
                    <Zoom
                        duration={300}
                        triggerOnce={true}
                    >
                        <Card>
                            <div
                                style={{ textAlign: 'center' }}
                            >
                                <p
                                    style={{ fontSize: '35px', fontWeight: 'lighter' }}
                                >
                                    {t('Pages.AgreementPage.agreement_statement')}
                                </p>

                                <p
                                    style={{ fontSize: '20px' }}
                                >
                                    {t('Pages.AgreementPage.agreement_policy_coverage')}
                                </p>

                                <p
                                    style={{ fontSize: '20px' }}
                                >
                                    {t('Pages.AgreementPage.agreement_reporting_policy')}
                                </p>

                                <p
                                    style={{ fontSize: '20px' }}
                                >
                                    {t('Pages.AgreementPage.agreement_policy_review')}
                                </p>
                            </div>
                            <Row
                                align="middle"
                                justify="space-between"
                                gutter={15}
                            >
                                <Col
                                    {...policyColProps}
                                >
                                    <Card>
                                        <Card.Meta
                                            title={t('Pages.AgreementPage.health_and_safety_policy_title')}
                                            avatar={<MedicineBoxOutlined />}
                                        />
                                    </Card>
                                </Col>

                                <Col
                                    {...policyColProps}
                                >
                                    <Card>
                                        <Card.Meta
                                            title={t('Pages.AgreementPage.environmental_policy_title')}
                                            avatar={<EnvironmentOutlined />}
                                        />
                                    </Card>
                                </Col>

                                <Col
                                    {...policyColProps}
                                >
                                    <Card>
                                        <Card.Meta
                                            title={t('Pages.AgreementPage.workplace_violence_policy_title')}
                                            avatar={<AimOutlined />}
                                        />
                                    </Card>
                                </Col>

                                <Col
                                    {...policyColProps}
                                >
                                    <Card>
                                        <Card.Meta
                                            title={t('Pages.AgreementPage.workplace_harassment_policy_title')}
                                            avatar={<NotificationOutlined />}
                                        />
                                    </Card>
                                </Col>

                                <Col
                                    {...policyColProps}
                                >
                                    <Card>
                                        <Card.Meta
                                            title={t('Pages.AgreementPage.working_conditions_policy_title')}
                                            avatar={<SafetyOutlined />}
                                        />
                                    </Card>
                                </Col>

                                <Col
                                    {...policyColProps}
                                >
                                    <Card>
                                        <Card.Meta
                                            title={t('Pages.AgreementPage.code_of_conduct_policy_title')}
                                            avatar={<SolutionOutlined />}
                                        />
                                    </Card>
                                </Col>

                                <Col
                                    {...policyColProps}
                                >
                                    <Card>
                                        <Card.Meta
                                            style={{ whiteSpace: 'normal' }}
                                            title={t('Pages.AgreementPage.aoda_policy_title')}
                                            avatar={<HeartOutlined />}
                                        />
                                    </Card>
                                </Col>
                            </Row>

                            <Row
                                align="middle"
                                justify="center"
                                style={{ marginTop: '20px' }}
                            >
                                <Col>
                                    <p
                                        style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}
                                    >
                                        {t('Pages.AgreementPage.agreement_acceptance_message')}
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Zoom>

                </Col>

                <Col span={24}>
                    <Row
                        align="middle"
                        justify="space-between"
                        gutter={20}
                    >
                        <Col
                            xxl={10}
                            xl={10}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={24}
                        >
                            <AppButton
                                style={{ width: '100%', minHeight: '120px', margin: '10px 0' }}
                                icon={<CloseCircleOutlined />}
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Confirmation',
                                        content: (
                                            <div>
                                                <p>{t('Pages.AgreementPage.decline_agreement_warning')}</p>
                                                <p>{t('Pages.AgreementPage.decline_agreement_confirmation')}</p>
                                            </div>
                                        ),
                                        onOk: () => {
                                            navigate(appRoutes.home.path);
                                        },
                                        okText: t('Pages.AgreementPage.decline_agreement_confirmation_button_text'),
                                        cancelText: t('Common.go_back'),
                                    });

                                }}
                            >
                                {t('Common.decline')}
                            </AppButton>
                        </Col>

                        <Col
                            xxl={10}
                            xl={10}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={24}
                        >
                            <AppButton
                                style={{ width: '100%', minHeight: '120px', margin: '10px 0' }}
                                icon={<CheckCircleOutlined />}
                                onClick={() => {
                                    dispatch(setHasAcceptedGlobalDisclaimer(true));
                                    navigate(appRoutes.signedIn.path);
                                }}
                            >
                                {t('Common.accept')}
                            </AppButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </PageWrapper>
    );
};