import * as React from 'react';

import { Col, Row } from 'antd';

import { AdminHeader } from '@/components/pageHeader';
import { AnimatedHeader } from '@/components/animatedHeader';
import { OfflineEmergencyAttendanceViewer } from '@/components/emergencyAttendanceViewer/offlineEmergencyAttendanceViewer';
import { PageWrapper } from '@/components/pageWrapper';
import { useTranslation } from 'react-i18next';

interface OfflineEmergencyAttendancePageProps {

}

export const OfflineEmergencyAttendancePage: React.FC<OfflineEmergencyAttendancePageProps> = (props) => {
    const { t } = useTranslation();

    return (
        <PageWrapper
            pageTitle={<AdminHeader />}
            loadingMessage={t('Common.loading')}
        >
            <Row
                align="middle"
                justify="center"
            >
                <Col span={24}>
                    <AnimatedHeader>
                        {t('Pages.OfflineEmergencyAttendancePage.header')}
                    </AnimatedHeader>
                </Col>

                <Col span={24}>
                    <OfflineEmergencyAttendanceViewer />
                </Col>
            </Row>
        </PageWrapper>
    );
};