import * as React from 'react';

import { Col, Form, FormProps, Row } from 'antd';

import { AppButton } from '@/components/appButton';
import { Employee } from 'drawbridge.shared/models/dataModels/employee';
import { EmployeeSearch } from '@/components/employeeSearch';
import { nameof } from 'ts-simple-nameof';
import { useTranslation } from 'react-i18next';

interface LinamarEmployeeFormValues {
    employee: Employee;
}


interface LinamarEmployeeFormProps extends FormProps<LinamarEmployeeFormValues> {
    isLoading?: boolean;
    employee?: Employee;
}

export const LinamarEmployeeForm: React.VFC<LinamarEmployeeFormProps> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm<LinamarEmployeeFormValues>();

    const { isLoading, employee, ...formProps } = props;

    return (
        <Form
            className="linamar-employee-form"
            layout="vertical"
            labelAlign="right"
            autoComplete="off"
            {...formProps}
            form={form}
        >
            <Form.Item
                label={t('Components.Forms.LinamarEmployeeForm.employee_label')}
                className="visit-details-form-item"
                name={nameof<LinamarEmployeeFormValues>(x => x.employee)}
                rules={[{ required: true }]}
                initialValue={employee}
            >
                <EmployeeSearch
                    searchMode={['name', 'employeeId', 'username']}
                    placeholder={t('Components.Forms.LinamarEmployeeForm.employee_placeholder')}
                    showFacility={true}
                />
            </Form.Item>

            <Form.Item>
                <Row>
                    <Col
                        span={24}
                        style={{ textAlign: 'center' }}
                    >
                        <AppButton
                            htmlType="submit"
                            size="middle"
                            className="linamar-employee-form-button"
                            disabled={isLoading}
                            loading={isLoading}
                        >
                            {t('Common.next')}
                        </AppButton>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};