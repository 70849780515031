import * as React from 'react';

import { Alert, Col, Modal, Row, notification } from 'antd';
import { CheckCircleOutlined, FileSearchOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons';
import { createOfflineEmergencyAttendance, endOfflineEmergencyAttendance, removeCompletedOfflineEmergencyAttendance, setOfflineEmergencyAttendanceVisits } from '@/store/modules/offlineEmergencyAttendanceModule';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AppButton } from '@/components/appButton';
import { OfflineAttendanceCard } from '@/components/attendanceCard/offlineAttendanceCard';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface OfflineEmergencyAttendanceViewerProps {

}

export const OfflineEmergencyAttendanceViewer: React.FC<OfflineEmergencyAttendanceViewerProps> = (props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const appRoutes = useAppRoutes();

    const currentLocale = useAppSelector((state) => state.locale.currentLocale);
    const currentVisits = useAppSelector((state) => state.visit.currentVisits);
    const facilityId = useAppSelector((state) => state.deviceConfig.deviceConfig?.facilityId);
    const offlineEmergencyAttendance = useAppSelector((state) => state.offlineEmergencyAttendance.emergencyAttendance);
    const completedEmergencyAttendance = useAppSelector((state) => state.offlineEmergencyAttendance.completedEmergencyAttendance);

    const allItems = [
        ...offlineEmergencyAttendance?.emergencyAttendanceEmployeeVisits ?? [],
        ...offlineEmergencyAttendance?.emergencyAttendanceExternalVisitorVisits ?? [],
    ].sort((a, b) => (a.visit?.visitor?.fullName ?? '').localeCompare((b.visit?.visitor?.fullName ?? '')));

    if (!offlineEmergencyAttendance) {
        return (
            <Row
                align="middle"
                justify="center"
            >
                <Col
                    xxl={18}
                    xl={18}
                    lg={20}
                    md={20}
                    sm={24}
                    xs={24}
                    style={{ textAlign: 'center' }}
                >
                    <AppButton
                        size="middle"
                        icon={<FileSearchOutlined />}
                        onClick={async () => {
                            if (!!facilityId) {
                                if (currentVisits.length === 0) {
                                    notification.warn({
                                        message: t('Components.OfflineEmergencyAttendanceViewer.no_visits_header'),
                                        description: t('Components.OfflineEmergencyAttendanceViewer.no_visits_message'),
                                    });

                                    return;
                                }

                                if (!!completedEmergencyAttendance) {
                                    Modal.confirm({
                                        title: t('Components.OfflineEmergencyAttendanceViewer.unsaved_attendance_exists_header'),
                                        content: (
                                            <div>
                                                <p>
                                                    {t('Components.OfflineEmergencyAttendanceViewer.unsaved_attendance_exists_message', { date: completedEmergencyAttendance.emergencyAttendance.startedAt.toLocaleString(currentLocale, { dateStyle: 'medium', timeStyle: 'medium' }) })}
                                                </p>
                                                <p>
                                                    {t('Components.OfflineEmergencyAttendanceViewer.unsaved_attendance_exists_confirmation')}
                                                </p>
                                            </div>
                                        ),
                                        onOk: () => {
                                            dispatch(removeCompletedOfflineEmergencyAttendance());

                                            dispatch(createOfflineEmergencyAttendance({
                                                facilityId: facilityId,
                                            }));

                                            // Set the attendance visits to whatever current visits we currently have
                                            dispatch(setOfflineEmergencyAttendanceVisits({
                                                visits: currentVisits,
                                            }));
                                        },
                                    });
                                } else {
                                    dispatch(createOfflineEmergencyAttendance({
                                        facilityId: facilityId,
                                    }));

                                    // Set the attendance visits to whatever current visits we currently have
                                    dispatch(setOfflineEmergencyAttendanceVisits({
                                        visits: currentVisits,
                                    }));
                                }
                            } else {
                                notification.error({
                                    message: t('Common.error'),
                                    description: t('Components.EmergencyAttendanceViewer.device_not_configured'),
                                });
                            }
                        }}
                    >
                        {t('Components.EmergencyAttendanceViewer.start_attendance_session_button_text')}
                    </AppButton>
                </Col>
            </Row>
        );
    }

    return (
        <Row
            align="middle"
            justify="center"
        >
            <Col
                span={24}
            >
                <div
                    style={{ fontWeight: 'lighter', fontSize: '1.3em', textTransform: 'uppercase' }}
                    className="primary"
                >
                    <strong>{t('Components.OfflineEmergencyAttendanceViewer.offline_session')}</strong> {t('Components.OfflineEmergencyAttendanceViewer.started_at', { date: offlineEmergencyAttendance?.startedAt.toLocaleString(currentLocale, { dateStyle: 'long', timeStyle: 'short' }) })}
                </div>

                <Alert
                    type="warning"
                    style={{ textAlign: 'center' }}
                    message={<strong>{t('Components.OfflineEmergencyAttendanceViewer.offline_attendance_info_header')}</strong>}
                    description={(
                        <div>
                            <p>
                                {t('Components.OfflineEmergencyAttendanceViewer.offline_attendance_info_message1')}
                            </p>
                            <p>
                                {t('Components.OfflineEmergencyAttendanceViewer.offline_attendance_info_message2')}
                            </p>
                        </div>
                    )}
                />
            </Col>

            <Col span={24}>
                {allItems.map((visit) => {
                    if (!visit) {
                        return null;
                    }

                    return (
                        <Row
                            key={`${visit.visit?.visitType}.${visit.id}`}
                            align="middle"
                            justify="center"
                        >
                            <Col
                                span={24}
                            >
                                <OfflineAttendanceCard
                                    emergencyAttendanceVisit={visit}
                                />
                            </Col>
                        </Row>
                    );
                })}
            </Col>

            <Col span={24}>
                <Row
                    align="middle"
                    justify="center"
                    gutter={25}
                >
                    <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ margin: '20px 0', textAlign: 'center' }}
                    >
                        <AppButton
                            size="middle"
                            icon={<StopOutlined />}
                            style={{ width: '100%' }}
                            danger={true}
                            onClick={async () => {
                                const numberOfMissingVisitors = allItems.filter(x => !x.isPresent).length;
                                if (numberOfMissingVisitors > 0) {
                                    // Not everyone was accounted for
                                    Modal.confirm({
                                        icon: <WarningOutlined />,
                                        title: t('Components.EmergencyAttendanceViewer.missing_visitors_title'),
                                        content: (
                                            <div>
                                                <div>
                                                    {t('Components.EmergencyAttendanceViewer.missing_visitors_message', { numberOfMissingVisitors: numberOfMissingVisitors })}
                                                </div>
                                                <br />
                                                <div>
                                                    {t('Components.EmergencyAttendanceViewer.end_session_confirmation_message')}
                                                </div>
                                            </div>
                                        ),
                                        okText: t('Components.EmergencyAttendanceViewer.end_session_confirmation_button_text'),
                                        onOk: async () => {
                                            // End it
                                            dispatch(endOfflineEmergencyAttendance());

                                            notification.success({
                                                message: t('Common.success'),
                                                description: t('Components.EmergencyAttendanceViewer.session_ended', { endTime: new Date().toLocaleString(currentLocale, { dateStyle: 'medium', timeStyle: 'short' }) }),
                                            });

                                            navigate(appRoutes.home.path);
                                        },
                                    });
                                } else {
                                    // End it
                                    Modal.confirm({
                                        icon: <CheckCircleOutlined />,
                                        title: t('Common.confirm'),
                                        content: (
                                            <div>
                                                <div>
                                                    {t('Components.EmergencyAttendanceViewer.all_visitors_found_message')}
                                                </div>
                                                <br />
                                                <div>
                                                    {t('Components.EmergencyAttendanceViewer.end_session_confirmation_message')}
                                                </div>
                                            </div>
                                        ),
                                        okText: t('Components.EmergencyAttendanceViewer.end_session_confirmation_button_text'),
                                        onOk: async () => {
                                            dispatch(endOfflineEmergencyAttendance());

                                            notification.success({
                                                message: t('Common.success'),
                                                description: t('Components.EmergencyAttendanceViewer.session_ended', { endTime: new Date().toLocaleString(currentLocale, { dateStyle: 'medium', timeStyle: 'short' }) }),
                                            });

                                        },
                                    });
                                }
                            }}
                        >
                            {t('Components.EmergencyAttendanceViewer.end_attendance_session_button_text')}
                        </AppButton>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};