import { callDeviceAuthApi, callUserAuthApi } from '@/utility/api';

import { AxiosResponse } from 'axios';
import { GlobalConfig } from 'drawbridge.shared/models/dataModels/globalConfig';
import { GlobalConfigState } from '@/store/state/globalConfigState';
import { HttpMethod } from 'drawbridge.shared/constants/httpMethod';
import { createApiThunk } from '@/types/reduxHelpers';
import { createReducer } from '@reduxjs/toolkit';

export const getGlobalConfig = createApiThunk<GlobalConfig, { authMode?: 'user' | 'device' } | void>('/globalConfig/getGlobalConfig', async (args, thunkApi) => {
    const auth = args?.authMode ?? 'device';

    let response: AxiosResponse<GlobalConfig>;

    if (auth === 'device') {
        response = await callDeviceAuthApi<GlobalConfig>('/globalConfig/getGlobalConfig');
    } else {
        response = await callUserAuthApi<GlobalConfig>('/globalConfig/getGlobalConfig');
    }

    return response.data;
});

export const uploadGlobalHealthAndSafetyPdf = createApiThunk<GlobalConfig, { filename: string, file: File }>('/globalConfig/uploadGlobalHealthAndSafetyPdf', async (args, thunkApi) => {
    const formData = new FormData();

    formData.append('healthAndSafetyUpload', args.file, args.filename);

    const response = await callUserAuthApi<GlobalConfig>('/globalConfig/uploadGlobalHealthAndSafetyPdf', HttpMethod.POST, formData);

    return response.data;
});

const initialState: GlobalConfigState = {
    isLoading: false,
    hasError: false,
    globalConfig: undefined,
};

export const globalConfigReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getGlobalConfig.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getGlobalConfig.fulfilled, (state, action) => {
            state.isLoading = false;
            state.hasError = false;
            state.globalConfig = action.payload;
        })
        .addCase(getGlobalConfig.rejected, (state, action) => {
            state.isLoading = false;
            state.hasError = true;
            state.globalConfig = undefined;
        })
        .addCase(uploadGlobalHealthAndSafetyPdf.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(uploadGlobalHealthAndSafetyPdf.fulfilled, (state, action) => {
            state.isLoading = false;
            state.hasError = false;
            state.globalConfig = action.payload;
        })
        .addCase(uploadGlobalHealthAndSafetyPdf.rejected, (state, action) => {
            state.isLoading = false;
            state.hasError = true;
        });
});