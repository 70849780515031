import { EmployeeVisit, isEmployeeVisit } from 'drawbridge.shared/models/dataModels/employeeVisit';
import { ExternalVisitorVisit, isExternalVisitorVisit } from 'drawbridge.shared/models/dataModels/externalVisitorVisit';
import { OfflineEmergencyAttendanceEmployeeVisit, OfflineEmergencyAttendanceExternalVisitorVisit } from 'drawbridge.shared/models/dataModels/emergencyAttendance';
import { Visit, VisitType } from 'drawbridge.shared/models/visit';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { OfflineEmergencyAttendanceState } from '@/store/state/offlineEmergencyAttendanceState';

export const createOfflineEmergencyAttendance = createAction<{ facilityId: number }>('offlineEmergencyAttendance/createOfflineEmergencyAttendance');
export const dismissCompletedOfflineEmergencyAttendance = createAction<void>('offlineEmergencyAttendance/dismissCompletedOfflineEmergencyAttendance');
export const endOfflineEmergencyAttendance = createAction<void>('offlineEmergencyAttendance/endOfflineEmergencyAttendance');
export const removeCompletedOfflineEmergencyAttendance = createAction<void>('offlineEmergencyAttendance/removeCompletedOfflineEmergencyAttendance');
export const setOfflineEmergencyAttendanceVisits = createAction<{ visits: Array<Visit> }>('offlineEmergencyAttendance/setOfflineEmergencyAttendanceVisits');
export const updateOfflineEmergencyAttendanceVisitPresence = createAction<{ emergencyAttendanceVisit: OfflineEmergencyAttendanceEmployeeVisit | OfflineEmergencyAttendanceExternalVisitorVisit; isPresent: boolean; visitType: VisitType }>('offlineEmergencyAttendance/updateOfflineEmergencyAttendanceVisitPresence');

const initialState: OfflineEmergencyAttendanceState = {
    emergencyAttendance: undefined,
    completedEmergencyAttendance: undefined,
};

export const offlineEmergencyAttendanceReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(createOfflineEmergencyAttendance, (state, action) => {
            state.emergencyAttendance = {
                facilityId: action.payload.facilityId,
                emergencyAttendanceEmployeeVisits: [],
                emergencyAttendanceExternalVisitorVisits: [],
                startedAt: new Date(),
            };
        })
        .addCase(dismissCompletedOfflineEmergencyAttendance, (state, action) => {
            if (!!state.completedEmergencyAttendance) {
                state.completedEmergencyAttendance = {
                    ...state.completedEmergencyAttendance,
                    isDismissed: true,
                };
            }
        })
        .addCase(endOfflineEmergencyAttendance, (state, action) => {
            if (!!state.emergencyAttendance) {
                state.completedEmergencyAttendance = {
                    emergencyAttendance: {
                        ...state.emergencyAttendance,
                        endedAt: new Date(),
                    },
                    isDismissed: false,
                };
                state.emergencyAttendance = undefined;
            }
        })
        .addCase(removeCompletedOfflineEmergencyAttendance, (state, action) => {
            state.completedEmergencyAttendance = undefined;
        })
        .addCase(setOfflineEmergencyAttendanceVisits, (state, action) => {
            const emergencyAttendanceEmployeeVisits: Array<OfflineEmergencyAttendanceEmployeeVisit> = action.payload.visits.filter((visit): visit is EmployeeVisit => isEmployeeVisit(visit))
                .map((visit) => {
                    return {
                        employeeVisitId: visit.id,
                        isPresent: false,
                        visit: visit,
                    };
                });

            const emergencyAttendanceExternalVisitorVisits: Array<OfflineEmergencyAttendanceExternalVisitorVisit> = action.payload.visits.filter((visit): visit is ExternalVisitorVisit => isExternalVisitorVisit(visit)).map((visit, index) => {
                return {
                    externalVisitorVisitId: visit.id,
                    isPresent: false,
                    visit: visit,
                };
            });

            if (!!state.emergencyAttendance) {
                state.emergencyAttendance.emergencyAttendanceEmployeeVisits = [...emergencyAttendanceEmployeeVisits];
                state.emergencyAttendance.emergencyAttendanceExternalVisitorVisits = [...emergencyAttendanceExternalVisitorVisits];
            }

        })
        .addCase(updateOfflineEmergencyAttendanceVisitPresence, (state, action) => {
            if (!!state.emergencyAttendance) {
                if (action.payload.visitType === 'employee') {
                    const updatedVisit = state.emergencyAttendance.emergencyAttendanceEmployeeVisits?.find(x => x.visit?.id === action.payload.emergencyAttendanceVisit.visit?.id);

                    if (!!updatedVisit) {
                        const modified = {
                            ...updatedVisit,
                            isPresent: action.payload.isPresent,
                        };

                        state.emergencyAttendance.emergencyAttendanceEmployeeVisits = [...(state.emergencyAttendance.emergencyAttendanceEmployeeVisits?.map(x => x.visit?.id === action.payload.emergencyAttendanceVisit.visit?.id ? modified : x)) ?? []];
                    }

                } else if (action.payload.visitType === 'external') {
                    const updatedVisit = state.emergencyAttendance.emergencyAttendanceExternalVisitorVisits?.find(x => x.visit?.id === action.payload.emergencyAttendanceVisit.visit?.id);

                    if (!!updatedVisit) {
                        const modified = {
                            ...updatedVisit,
                            isPresent: action.payload.isPresent,
                        };

                        state.emergencyAttendance.emergencyAttendanceExternalVisitorVisits = [...(state.emergencyAttendance.emergencyAttendanceExternalVisitorVisits?.map(x => x.visit?.id === action.payload.emergencyAttendanceVisit.visit?.id ? modified : x)) ?? []];
                    }
                }
            }
        });
});