import * as React from 'react';

import { Empty, Select, SelectProps, Spin } from 'antd';

import Highlighter from 'react-highlight-words';
import { LabeledValue } from 'antd/lib/select';
import { Vendor } from 'drawbridge.shared/models/dataModels/vendor';
import classNames from 'classnames';
import { debounce } from 'throttle-debounce';
import { findVendorsByPartialVendorName } from '@/store/modules/vendorModule';
import { useAppDispatch } from '@/types/reduxHelpers';
import { useTranslation } from 'react-i18next';

interface VendorSearchProps extends SelectProps {
    value?: Vendor;
    onChange?: (value: Vendor | undefined) => void;
}

export const VendorSearch: React.FC<VendorSearchProps> = (props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [foundVendors, setFoundVendors] = React.useState<Array<Vendor>>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');
    const [labeledValue, setLabeledValue] = React.useState<LabeledValue | undefined>(undefined);

    const fetchRef = React.useRef(0);

    const triggerChange = (changedValue: Vendor | undefined): void => {
        props.onChange?.(changedValue);
    };

    React.useEffect(() => {
        if (!!props.value?.id) {
            setLabeledValue({
                key: props.value.id.toString(),
                value: props.value.id,
                label: (
                    <span>{props.value.vendorName}</span>
                ),
            });
        }
    }, [props.value]);

    React.useEffect(() => {
        const searchAsync = async (): Promise<void> => {
            fetchRef.current += 1;

            const fetchId = fetchRef.current;

            if (!!searchString) {
                setIsLoading(true);

                const action = await dispatch(findVendorsByPartialVendorName({ vendorName: searchString }));

                if (findVendorsByPartialVendorName.fulfilled.match(action)) {
                    // Make sure we're getting results from the last request, and not an old one that took its sweet time to complete
                    if (fetchId === fetchRef.current) {
                        setFoundVendors([...action.payload].sort((a, b) => a.vendorName.localeCompare(b.vendorName)));
                    }
                }

                setIsLoading(false);
            } else {
                setFoundVendors([]);
            }
        };

        searchAsync();
    }, [searchString]);

    const { ...selectProps } = props;

    const debouncedSearch = React.useCallback(debounce(500, (searchString: string) => {
        setSearchString(searchString);
    }), []);

    return (
        <Select<LabeledValue>
            {...selectProps}
            className={classNames('vendor-search', selectProps.className)}
            showSearch={true}
            filterOption={false}
            labelInValue={true}
            allowClear={true}
            value={labeledValue}
            showArrow={false}
            loading={isLoading}
            onBlur={() => {
                setSearchString('');
                setFoundVendors([]);
            }}
            onChange={(newValue) => {
                setLabeledValue(newValue);

                const vendor = foundVendors.find(x => x.id === newValue.value);

                triggerChange(vendor);

                // Clear out search when selected
                setSearchString('');
                setFoundVendors([]);
            }}
            notFoundContent={
                isLoading
                    ? <span><Spin size="small" /> {t('Common.searching')}</span>
                    : !!searchString
                        ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No matches found'} />
                        : null
            }
            onSearch={(value) => {
                // Debounce so we d ont hammer the server with searches for every keypress
                debouncedSearch(value);
            }}
        >
            {foundVendors.map((vendor) => {
                return (
                    <Select.Option
                        key={vendor.id}
                        value={vendor.id}
                    >
                        <span>
                            <Highlighter
                                highlightClassName={classNames({ 'search-input-highlight': !!searchString })}
                                searchWords={!!searchString ? [searchString] : []}
                                textToHighlight={vendor.vendorName}
                            />
                        </span>
                    </Select.Option>
                );
            })}
        </Select>
    );
};