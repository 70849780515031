import { EmergencyAttendance, OfflineEmergencyAttendance } from 'drawbridge.shared/models/dataModels/emergencyAttendance';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { EmergencyAttendanceState } from '@/store/state/emergencyAttendanceState';
import { HttpMethod } from 'drawbridge.shared/constants/httpMethod';
import { VisitType } from 'drawbridge.shared/models/visit';
import { callUserAuthApi } from '@/utility/api';
import { createApiThunk } from '@/types/reduxHelpers';

export const getActiveEmergencyAttendance = createApiThunk<EmergencyAttendance | undefined, { facilityId: number }>('/emergencyAttendance/getActiveEmergencyAttendance', async (args, thunkApi) => {
    const response = await callUserAuthApi<EmergencyAttendance | undefined>(`/emergencyAttendance/facility/${args.facilityId}`);

    return response.data;
});

export const createEmergencyAttendance = createApiThunk<EmergencyAttendance, { facilityId: number }>('/emergencyAttendance/createEmergencyAttendance', async (args, thunkApi) => {
    const response = await callUserAuthApi<EmergencyAttendance>('/emergencyAttendance/createEmergencyAttendance', HttpMethod.POST, {
        facilityId: args.facilityId,
    });

    return response.data;
});

export const saveOfflineEmergencyAttendance = createApiThunk<OfflineEmergencyAttendance, { offlineEmergencyAttendance: OfflineEmergencyAttendance; shouldEmail: boolean }>('emergencyAttendance/saveOfflineEmergencyAttendance', async (params, thunkApi) => {
    const response = await callUserAuthApi<EmergencyAttendance>('/emergencyAttendance/saveOfflineEmergencyAttendance', HttpMethod.POST, {
        offlineEmergencyAttendance: params.offlineEmergencyAttendance,
        shouldEmail: params.shouldEmail,
    });

    return response.data;
});

export const endEmergencyAttendance = createApiThunk<EmergencyAttendance, { emergencyAttendanceId: number }>('/emergencyAttendance/endEmergencyAttendance', async (args, thunkApi) => {
    const response = await callUserAuthApi<EmergencyAttendance>('/emergencyAttendance/endEmergencyAttendance', HttpMethod.POST, {
        emergencyAttendanceId: args.emergencyAttendanceId,
    });

    return response.data;
});

export const updateEmergencyAttendanceVisitPresence = createApiThunk<EmergencyAttendance, { emergencyAttendanceId: number, emergencyAttendanceVisitId: number, isPresent: boolean, visitType: VisitType }>('/emergencyAttendance/updateEmergencyAttendanceVisitPresence', async (args, thunkApi) => {
    const response = await callUserAuthApi<EmergencyAttendance>('/emergencyAttendance/updateEmergencyAttendanceVisitPresence', HttpMethod.PUT, {
        emergencyAttendanceId: args.emergencyAttendanceId,
        emergencyAttendanceVisitId: args.emergencyAttendanceVisitId,
        isPresent: args.isPresent,
        visitType: args.visitType,
    });

    return response.data;
});

export const emailEmergencyAttendance = createApiThunk<void, { emergencyAttendanceId: number; emailAddresses: Array<string> }>('/emergencyAttendance/emailEmergencyAttendance', async (args, thunkApi) => {
    const response = await callUserAuthApi<void>('/emergencyAttendance/emailEmergencyAttendance', HttpMethod.POST, {
        emergencyAttendanceId: args.emergencyAttendanceId,
        emailAddresses: args.emailAddresses,
    });

    return response.data;
});

export const emergencyAttendanceStarted = createAction<EmergencyAttendance>('/emergencyAttendance/emergencyAttendanceStarted');
export const emergencyAttendanceEnded = createAction<EmergencyAttendance>('/emergencyAttendance/emergancyAttendanceEnded');
export const emergencyAttendanceUpdated = createAction<EmergencyAttendance>('/emergencyAttendance/emergencyAttendanceUpdated');

const initialState: EmergencyAttendanceState = {
    hasError: false,
    isLoading: false,
    emergencyAttendance: undefined,
};

export const emergencyAttendanceReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getActiveEmergencyAttendance.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getActiveEmergencyAttendance.fulfilled, (state, action) => {
            state.isLoading = false;
            state.emergencyAttendance = action.payload;
            state.hasError = false;
        })
        .addCase(getActiveEmergencyAttendance.rejected, (state, action) => {
            state.isLoading = false;
            state.emergencyAttendance = undefined;
            state.hasError = true;
        })
        .addCase(createEmergencyAttendance.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(createEmergencyAttendance.fulfilled, (state, action) => {
            state.isLoading = false;
            state.emergencyAttendance = action.payload;
            state.hasError = false;
        })
        .addCase(createEmergencyAttendance.rejected, (state, action) => {
            state.isLoading = false;
            state.emergencyAttendance = undefined;
            state.hasError = true;
        })
        .addCase(endEmergencyAttendance.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(endEmergencyAttendance.fulfilled, (state, action) => {
            state.isLoading = false;
            state.hasError = false;
            state.emergencyAttendance = undefined;
        })
        .addCase(endEmergencyAttendance.rejected, (state, action) => {
            state.isLoading = false;
            state.hasError = true;
        })
        .addCase(updateEmergencyAttendanceVisitPresence.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(updateEmergencyAttendanceVisitPresence.fulfilled, (state, action) => {
            state.isLoading = false;
            state.emergencyAttendance = action.payload;
            state.hasError = false;
        })
        .addCase(updateEmergencyAttendanceVisitPresence.rejected, (state, action) => {
            state.isLoading = false;
            state.hasError = true;
        })
        .addCase(emergencyAttendanceStarted, (state, action) => {
            state.emergencyAttendance = action.payload;
        })
        .addCase(emergencyAttendanceEnded, (state, action) => {
            state.emergencyAttendance = undefined;
        })
        .addCase(emergencyAttendanceUpdated, (state, action) => {
            state.emergencyAttendance = action.payload;
        });
});