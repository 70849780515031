import '@/wdyr';
import 'core-js/stable';
import './styles/main.less';
import '@/i18n/config';

import * as React from 'react';
import * as serviceWorkerRegistration from '@/serviceWorkerRegistration';

import { Modal, message, notification } from 'antd';
import { RootState, configureStore } from './store/createStore';

import { AppWrapper } from './appWrapper';
import { pdfjs } from 'react-pdf';
import reportWebVitals from './reportWebVitals';
import { updateServiceWorker } from '@modules/serviceWorkerModule';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const initialState: RootState = window.__INITIAL_STATE__;

const configuredStore = configureStore(initialState);

// PDF Worker for anywhere we may be displaying PDFs in the app
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/scripts/pdf.worker.min.js`;

export const store = configuredStore.store;
export const persistor = configuredStore.persistor;

export const App: React.FC = (props) => {
    const { t } = useTranslation();

    notification.config({
        placement: 'top',
    });

    useEffect(() => {
        serviceWorkerRegistration.register({
            onUpdate: async (registration) => {
                Modal.info({
                    title: t('Components.App.update_available_title'),
                    okText: t('Components.App.reload_now_text'),
                    content: t('Components.App.update_available_description'),
                    onOk: async () => {
                        // Makes Workbox call skipWaiting()
                        if (!!registration.waiting) {
                            await registration.unregister();
                            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                        }

                        // Once the service worker is unregistered, we can reload the page to let
                        // the browser download a fresh copy of our app (invalidating the cache)
                        window.location.reload();

                    },
                    onCancel: () => {
                        message.info(t('Components.App.update_cancelled_message'));
                    },
                });
                store.dispatch(updateServiceWorker());
            },
        });
    }, []);

    return (
        <AppWrapper store={store} />
    );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default App;
