import * as React from 'react';

import { Col, Modal, Row } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppButton } from '@/components/appButton';
import { useAppRoutes } from '@/hooks';
import { useAppSelector } from '@/types/reduxHelpers';
import { useIdleTimer } from 'react-idle-timer';
import { useTranslation } from 'react-i18next';

interface IdleTimeoutProps {

}

const DEFAULT_TIMEOUT_MILLISECONDS = 1000 * 60 * 5; // 5 mins
const PROMPT_BEFORE_IDLE_MILLISECONDS = 1000 * 30; // 30 seconds

export const IdleTimeout: React.FC<IdleTimeoutProps> = (props) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const appRoutes = useAppRoutes();
    const location = useLocation();

    const deviceConfig = useAppSelector((state) => state.deviceConfig.deviceConfig);

    const [isPromptOpen, setIsPromptOpen] = React.useState(false);
    const [remainingSeconds, setRemainingSeconds] = React.useState(deviceConfig?.idleTimeoutSeconds ?? (DEFAULT_TIMEOUT_MILLISECONDS / 1000));

    const [isEnabled, setIsEnabled] = React.useState(false);

    const { getRemainingTime, activate, pause, start } = useIdleTimer({
        promptBeforeIdle: PROMPT_BEFORE_IDLE_MILLISECONDS,
        timeout: !!deviceConfig?.idleTimeoutSeconds
            ? deviceConfig.idleTimeoutSeconds * 1000
            : DEFAULT_TIMEOUT_MILLISECONDS,
        onIdle: () => {
            setIsPromptOpen(false);

            navigate(appRoutes.home.path, { replace: true });
        },
        onActive: () => {
            setIsPromptOpen(false);
        },
        onPrompt: () => {
            setIsPromptOpen(true);
        },
    });

    React.useEffect(() => {
        const enabled = deviceConfig?.isIdleTimeoutEnabled === true &&
            location.pathname !== appRoutes.home.path &&
            location.pathname !== appRoutes.login.path &&
            !location.pathname.toLowerCase().includes('/admin');

        setIsEnabled(enabled);
    }, [location.pathname, deviceConfig?.isIdleTimeoutEnabled]);

    React.useEffect(() => {
        let interval: NodeJS.Timeout | undefined;

        if (isEnabled) {
            start();

            interval = setInterval(() => {
                setRemainingSeconds(Math.ceil(getRemainingTime() / 1000));
            }, 500);
        } else {
            pause();
        }

        return () => {
            if (!!interval) {
                clearInterval(interval);
            }
        };
    }, [isEnabled]);

    return (
        <Modal
            visible={isPromptOpen}
            footer={null}
            closable={false}
        >
            <Row
                align="middle"
                justify="center"
                gutter={[20, 20]}
            >
                <Col
                    span={24}
                    style={{
                        textAlign: 'center',
                        fontSize: '2em',
                    }}
                >
                    {t('Components.IdleTimeout.header')}
                </Col>

                <Col
                    span={24}
                    style={{ textAlign: 'center' }}
                >
                    {t('Components.IdleTimeout.subheader', { seconds: remainingSeconds })}
                </Col>

                <Col
                    span={24}
                    style={{ textAlign: 'center' }}
                >
                    <AppButton
                        size="middle"
                        type="primary"
                        onClick={() => {
                            activate();
                        }}
                    >
                        {t('Components.IdleTimeout.activate_button_text')}
                    </AppButton>
                </Col>

                <Col
                    span={24}
                    style={{ textAlign: 'center' }}
                >
                    <AppButton
                        size="small"
                        onClick={() => {
                            setIsPromptOpen(false);
                            navigate(appRoutes.home.path, { replace: true });
                        }}
                    >
                        {t('Components.IdleTimeout.go_home_button_text')}
                    </AppButton>
                </Col>
            </Row>
        </Modal>
    );
};