import { Facility } from './facility';
import { Visitor } from '../visitor';

export interface Employee {
    id: number;
    employeeId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    jobCode?: string;
    jobTitle?: string;
    departmentName?: string;
    facilityId: number;
    username: string;
    isActive: boolean;
    dateCreated?: Date;
    createdBy?: string;
    dateModified?: Date;
    modifiedBy?: string;
    displayName: string;
    emailAddress: string;
    notes?: string;

    facility?: Facility;

    readonly visitorType: 'employee';
}

export const isEmployee = (visitor?: Visitor): visitor is Employee => {
    if (!visitor) {
        return false;
    }

    return visitor.visitorType === 'employee';
};