
export const getPdfBlobFromRawData = (input: string | ArrayBuffer | undefined): Blob | undefined => {
    if (!input) {
        return undefined;
    }

    let stringInput = '';

    if (typeof input === 'string') {
        stringInput = input;
    } else if (input instanceof ArrayBuffer) {
        stringInput = Buffer.from(input).toString('base64');
    }

    const byteCharacters = atob(stringInput);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    return blob;
};