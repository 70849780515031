import * as React from 'react';

import { Col, Row } from 'antd';
import { HistoryOutlined, UserOutlined } from '@ant-design/icons';

import { AnimatedButton } from '@/components/animatedButton';
import { AnimatedHeader } from '@/components/animatedHeader';
import { PageWrapper } from '@/components/pageWrapper';
import { WelcomeHeader } from '@/components/pageHeader';
import { useAppRoutes } from '@/hooks';
import { useAppSelector } from '@/types/reduxHelpers';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ExternalVisitorPageProps {

}

export const ExternalVisitorPage: React.FC<ExternalVisitorPageProps> = (props) => {
    const { t } = useTranslation();

    const appRoutes = useAppRoutes();

    const navigate = useNavigate();

    const visitDetails = useAppSelector((state) => state.signIn.visitDetails);

    React.useEffect(() => {
        if (!visitDetails) {
            navigate(appRoutes.home.path, { replace: true });
        }
    }, []);

    return (
        <PageWrapper
            containerClassName="external-visitor-page"
            pageTitle={<WelcomeHeader />}
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col span={24}>
                    <AnimatedHeader>
                        {t('Pages.ExternalVisitorPage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    style={{ marginTop: 20 }}
                    xxl={8}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                >
                    <AnimatedButton
                        icon={<UserOutlined />}
                        animationDelay={0}
                        onClick={() => navigate(appRoutes.firstTimeVisitor.path)}
                    >
                        {t('Pages.ExternalVisitorPage.first_time_visitor_button_text')}
                    </AnimatedButton>
                </Col>

                <Col
                    style={{ marginTop: 20 }}
                    xxl={8}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                >
                    <AnimatedButton
                        icon={<HistoryOutlined />}
                        animationDelay={50}
                        onClick={() => navigate(appRoutes.returningVisitor.path)}
                    >
                        {t('Pages.ExternalVisitorPage.returning_visitor_button_text')}
                    </AnimatedButton>
                </Col>
            </Row>
        </PageWrapper>
    );
};