import 'moment/locale/es';
import 'moment/locale/zh-cn';
import 'moment/locale/bg';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/hu';

import { createAction, createReducer } from '@reduxjs/toolkit';
import { getObjectFromLocalStorage, saveObjectToLocalStorage } from '@utility/localStorage';

import { AppThunkAction } from '@reduxHelpers';
import { Locale } from 'antd/es/locale-provider';
import { LocaleState } from '@state/localeState';
import bgBG from 'antd/es/locale/bg_BG';
import deDE from 'antd/es/locale/de_DE';
import enUS from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';
import frFR from 'antd/es/locale/fr_FR';
import huHU from 'antd/es/locale/hu_HU';
import { i18n } from '@/i18n';
import moment from 'moment';
import zhCN from 'antd/es/locale/zh_CN';

// *************************
// Notes for adding new languages:
// The majority of the translation work is handled by i18n in @/i18n/config
// This will take care of all of the application-specific translations via translation files in @/i18n/locales/*
// However, antd components need some additional configuration in order to translate strings coming directly from their library.
//
// To add a new language to the application:
// 1. Create a new translations.json file for the new language in @/i18n/locales/[language code]/translations.json
// 2. Add the new langauge to 'supportedLanguages' and 'resources' objects in @/i18n/config.ts - Resources are assigned the json file added in the previous step
// 3. Import the respective locale from both moment and antd above
// 4. Update the mappings below for getAntdLocale and getMomentLocale with their respective imports 
// *************************

interface LocalePayload {
    localeKey: string;
    antdLocale: Locale;
    momentLocale: string;
}

const changeLanguage = createAction<LocalePayload>('locale/changeLanguage');

const getAntdLocale = (localeKey?: string): Locale => {
    switch (localeKey) {
        case 'es': {
            return esES;
        }
        case 'zh': {
            return zhCN;
        }
        case 'bg': {
            return bgBG;
        }
        case 'de': {
            return deDE;
        }
        case 'fr': {
            return frFR;
        }
        case 'hu': {
            return huHU;
        }
        case 'en':
        default: {
            return enUS;
        }
    }
};

const getMomentLocale = (localeKey?: string): string => {
    switch (localeKey) {
        case 'es': {
            return 'es';
        }
        case 'zh': {
            return 'zh-cn';
        }
        case 'bg': {
            return 'bg';
        }
        case 'de': {
            return 'de';
        }
        case 'fr': {
            return 'fr';
        }
        case 'hu': {
            return 'hu';
        }
        case 'en':
        default: {
            return 'en';
        }
    }
};

export const updateLanguage = (localeKey: string): AppThunkAction<LocalePayload> => {
    return (dispatch, getState) => {

        const antdLocale = getAntdLocale(localeKey);
        const momentLocale = getMomentLocale(localeKey);

        i18n.changeLanguage(localeKey);
        moment.locale(momentLocale);

        dispatch(changeLanguage({
            antdLocale: antdLocale,
            localeKey: localeKey,
            momentLocale: momentLocale,
        }));

        saveObjectToLocalStorage<string>('drawbridge-locale', getState().locale.currentLocale);

        return {
            antdLocale: antdLocale,
            localeKey: localeKey,
            momentLocale: momentLocale,
        };
    };
};

const getInitialState = (): LocaleState => {
    // TODO: I'd like to have redux-persist handle this the same way we're persisting other slices of state, but it becomes annoyingly complex
    // to then handle the necessary calls to i18n and moment when the state is rehydrated (you'd wind up with either side-effects in the reducer or the need for a middleware)
    // Going to keep as-is for now
    const savedLocale = getObjectFromLocalStorage<string>('drawbridge-locale') ?? 'en';

    const antdLocale = getAntdLocale(savedLocale);
    const momentLocale = getMomentLocale(savedLocale);

    // Set our saved locale for i18n and moment translations
    // Antd-specific component translations will update via the ConfigProvider in CoreLayout.tsx automatically due to our redux props so no changes needed here
    i18n.changeLanguage(savedLocale);
    moment.locale(momentLocale);

    return {
        currentLocale: savedLocale,
        antdLocale: antdLocale,
        momentLocale: momentLocale,
    };
};

export const localeReducer = createReducer(getInitialState(), (builder) => {
    builder
        .addCase(changeLanguage, (state, action) => {
            state.currentLocale = action.payload.localeKey;
            state.antdLocale = action.payload.antdLocale;
            state.momentLocale = action.payload.momentLocale;
        });
});