import './emergencyAttendanceCard.less';

import * as React from 'react';

import { Avatar, Card, CardProps, Col, Divider, List, Row, Statistic } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { EmergencyAttendance, OfflineEmergencyAttendance } from 'drawbridge.shared/models/dataModels/emergencyAttendance';

import classNames from 'classnames';
import { isEmployee } from 'drawbridge.shared/models/dataModels/employee';
import { useAppSelector } from '@/types/reduxHelpers';
import { useTranslation } from 'react-i18next';

interface EmergencyAttendanceCardProps extends CardProps {
    emergencyAttendance: EmergencyAttendance | OfflineEmergencyAttendance;
}

export const EmergencyAttendanceCard: React.FC<EmergencyAttendanceCardProps> = (props) => {
    const { t } = useTranslation();

    const currentLocale = useAppSelector((state) => state.locale.currentLocale);

    const { emergencyAttendance, ...cardProps } = props;

    const allEmergencyAttendanceVisits = [
        ...(emergencyAttendance.emergencyAttendanceEmployeeVisits ?? []),
        ...(emergencyAttendance.emergencyAttendanceExternalVisitorVisits ?? []),
    ].sort((a, b) => (a.visit?.visitor?.fullName ?? '').localeCompare((b.visit?.visitor?.fullName ?? '')));

    return (
        <Card
            {...cardProps}
            className={classNames('emergency-attendance-card', cardProps?.className)}
        >
            <Row
                align="middle"
                justify="center"
            >
                <Col
                    span={12}
                    style={{ textAlign: 'center' }}
                >
                    <Statistic
                        title={t('Components.EmergencyAttendanceCard.started_at_label')}
                        value={emergencyAttendance.startedAt.toLocaleString(currentLocale, { dateStyle: 'medium', timeStyle: 'medium' })}
                    />
                </Col>

                <Col
                    span={12}
                    style={{ textAlign: 'center' }}
                >
                    <Statistic
                        title={t('Components.EmergencyAttendanceCard.ended_at_label')}
                        value={emergencyAttendance.endedAt?.toLocaleString(currentLocale, { dateStyle: 'medium', timeStyle: 'medium' })}
                    />
                </Col>

                <Divider />

                <Col
                    span={24}
                >
                    <List
                        itemLayout="horizontal"
                        dataSource={allEmergencyAttendanceVisits}
                        renderItem={(emergencyAttendanceVisit) => {
                            return (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar className={classNames('presence-avatar', { 'is-present': emergencyAttendanceVisit.isPresent })} icon={emergencyAttendanceVisit.isPresent ? <CheckCircleOutlined /> : <CloseCircleOutlined />} />}
                                        title={emergencyAttendanceVisit.visit?.visitor?.fullName}
                                        description={(
                                            <span><strong>{isEmployee(emergencyAttendanceVisit.visit?.visitor) ? `${emergencyAttendanceVisit.visit?.visitor.facility?.facilityName} (Linamar)` : emergencyAttendanceVisit.visit?.visitor?.company}</strong> - {t('Components.EmergencyAttendanceCard.visiting_contact', { contact: emergencyAttendanceVisit.visit?.facilityContact?.fullName })}</span>
                                        )}
                                    />

                                    <div>
                                        {emergencyAttendanceVisit.isPresent ? t('Components.EmergencyAttendanceCard.present') : t('Components.EmergencyAttendanceCard.not_present')}
                                    </div>
                                </List.Item>
                            );
                        }}
                    />
                </Col>
            </Row>
        </Card>
    );
};