import * as React from 'react';

import { AimOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Upload } from 'antd';

import { useTranslation } from 'react-i18next';

interface PdfFileSelectorProps {
    onChange?: (file?: File | undefined) => void;
    enableDrag?: boolean;
}

export const PdfFileSelector: React.FC<PdfFileSelectorProps> = (props) => {
    const { t } = useTranslation();

    const enableDrag = props.enableDrag ?? true;

    return (
        <Upload
            // Prevent the Upload component from trying to upload by itself
            beforeUpload={() => false}
            multiple={false}
            maxCount={1}
            accept=".pdf"
            type={enableDrag ? 'drag' : 'select'}
            onChange={(info) => {
                props.onChange?.(info.fileList[0]?.originFileObj);
            }}
        >
            <Row
                align="middle"
                justify="center"
            >
                <Col span={24}>
                    <Button>
                        <UploadOutlined /> {t('Components.AppSettings.GlobalSettings.GlobalHealthAndSafetySettings.file_select_button_text')}
                    </Button>
                </Col>

                {enableDrag &&
                    <Col
                        span={24}
                        style={{ fontWeight: 'lighter', textTransform: 'uppercase', marginTop: 5 }}
                    >
                        <AimOutlined /> {t('Components.AppSettings.GlobalSettings.GlobalHealthAndSafetySettings.file_select_drag_text')}
                    </Col>
                }

            </Row>
        </Upload>
    );
};