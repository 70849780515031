import './visitCard.less';

import * as React from 'react';

import { Badge, Badge2 } from '@/components/badge';
import { BadgeType, isBadgeType } from 'drawbridge.shared/models/badgeType';
import { Card, Col, ColProps, Divider, Modal, Radio, Row, Statistic, notification } from 'antd';
import { ContactsOutlined, LogoutOutlined, PrinterOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AppButton } from '@/components/appButton';
import { ReactComponent as LinamarIcon } from '@/assets/linamarLogo.svg';
import { Visit } from 'drawbridge.shared/models/visit';
import { signOutVisit } from '@/store/modules';
import { useApiErrorMessage } from '@/hooks/useApiErrorMessage';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';

interface VisitCardProps {
    visit: Visit;
    allowSignOut?: boolean;
    allowPrintBadge?: boolean;
}

export const VisitCard: React.FC<VisitCardProps> = (props) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useApiErrorMessage();

    const dispatch = useAppDispatch();

    const currentLocale = useAppSelector((state) => state.locale.currentLocale);

    const [isPrintModalOpen, setIsPrintModalOpen] = React.useState(false);
    const [visitBadgeToPrint, setVisitBadgeToPrint] = React.useState<Visit | undefined>(undefined);
    const [badgeType, setBadgeType] = React.useState<BadgeType>('original');

    const badgeRef = React.useRef<HTMLDivElement>(null);

    const handlePrint = useReactToPrint({
        content: () => badgeRef.current,
    });

    const infoColProps: ColProps = {
        xxl: 8,
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
        style: { marginTop: '15px' },
    };

    const valueStyle: React.CSSProperties = {
        fontSize: '1em',
    };

    const { visit, allowSignOut, allowPrintBadge } = props;

    return (
        <Card
            key={`${visit.visitType}.${visit.id}`}
            className="visit-card"
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col
                    flex={0}
                    style={{ textAlign: 'center' }}
                >
                    {visit.visitType === 'employee' ? <LinamarIcon className="primary-fill" style={{ width: 35, height: 35 }} /> : <ContactsOutlined className="primary" style={{ fontSize: 30 }} />}
                </Col>

                <Col
                    flex={1}
                >
                    <span className="visit-card-name">{visit.visitor?.fullName}</span>
                </Col>

                {!visit.signedOutAt &&
                    <Col
                        flex={0}
                    >
                        <span className="visit-card-current">
                            {t('Components.VisitCard.current_visit_text')}
                        </span>
                    </Col>
                }
            </Row>

            <Row
                align="middle"
                justify="end"
                gutter={10}
            >
                {!!visit.id && !visit.signedOutAt && allowPrintBadge === true &&
                    <Col
                        xxl={4}
                        xl={6}
                        lg={8}
                        md={10}
                        sm={12}
                        xs={24}
                    >
                        <AppButton
                            size="small"
                            type="primary"
                            icon={<PrinterOutlined />}
                            style={{ width: '100%' }}
                            onClick={() => {
                                setVisitBadgeToPrint(visit);
                                setIsPrintModalOpen(true);
                            }}
                        >
                            {t('Components.VisitCard.print_badge_button_text')}
                        </AppButton>
                    </Col>
                }

                {!!visit.id && !visit.signedOutAt && allowSignOut === true &&
                    <Col
                        xxl={4}
                        xl={6}
                        lg={8}
                        md={10}
                        sm={12}
                        xs={24}
                    >
                        <AppButton
                            size="small"
                            danger={true}
                            type="primary"
                            style={{ width: '100%' }}
                            icon={<LogoutOutlined />}
                            onClick={() => {
                                if (!!visit.id) {
                                    const visitId = visit.id;

                                    Modal.confirm({
                                        title: t('Components.VisitCard.sign_out_modal_title'),
                                        content: t('Components.VisitCard.sign_out_modal_confirmation', { name: visit.visitor?.fullName }),
                                        cancelText: t('Common.cancel'),
                                        okText: t('Common.confirm'),
                                        onOk: async () => {
                                            const action = await dispatch(signOutVisit({
                                                visitId: visitId,
                                                visitType: visit.visitType,
                                                authMode: 'user',
                                            }));

                                            if (signOutVisit.rejected.match(action)) {
                                                notification.error({
                                                    message: t('Common.error'),
                                                    description: getErrorMessage(action.payload),
                                                });
                                            } else {
                                                notification.success({
                                                    message: t('Common.success'),
                                                    description: t('Components.VisitCard.sign_out_successful_message', { name: visit.visitor?.fullName }),
                                                });
                                            }
                                        },
                                    });
                                }
                            }}
                        >
                            {t('Components.VisitCard.sign_out_button_text')}
                        </AppButton>
                    </Col>
                }
            </Row>

            <Divider style={{ margin: 10 }} />

            <Row
                align="middle"
                justify="start"
                gutter={20}
            >
                <Col
                    {...infoColProps}
                >
                    <Statistic
                        title={t('Components.VisitCard.signed_in_at_label')}
                        value={visit.signedInAt.toLocaleString(currentLocale, { dateStyle: 'medium', timeStyle: 'short' })}
                        valueStyle={valueStyle}
                    />

                </Col>

                {!!visit.signedOutAt &&
                    <Col
                        {...infoColProps}
                    >
                        <Statistic
                            title={t('Components.VisitCard.signed_out_at_label')}
                            value={visit.signedOutAt.toLocaleString(currentLocale, { dateStyle: 'medium', timeStyle: 'short' })}
                            valueStyle={valueStyle}
                        />
                    </Col>
                }

                <Col
                    {...infoColProps}
                >
                    <Statistic
                        title={visit.visitType === 'employee' ? t('Components.VisitCard.facility_label') : t('Components.VisitCard.company_label')}
                        value={visit.visitType === 'employee' ? (visit.visitor?.facility?.facilityName ?? 'Linamar') : visit.visitor?.company}
                        valueStyle={valueStyle}
                    />
                </Col>

                <Col
                    {...infoColProps}
                >
                    <Statistic
                        title={t('Components.VisitCard.contact_label')}
                        value={visit.facilityContact?.fullName}
                        valueStyle={valueStyle}
                    />
                </Col>

                <Col
                    {...infoColProps}
                >
                    <Statistic
                        title={t('Components.VisitCard.visit_reason_label')}
                        value={t(`Types.VisitReason.${visit.visitReason}`)}
                        valueStyle={valueStyle}
                    />
                </Col>
            </Row>

            <Modal
                title={t('Components.VisitCard.print_badge_modal_title')}
                visible={isPrintModalOpen}
                okText={t('Components.VisitCard.print_badge_modal_ok_text')}
                onCancel={() => {
                    setIsPrintModalOpen(false);
                    setVisitBadgeToPrint(undefined);
                }}
                onOk={() => {
                    handlePrint();

                    setIsPrintModalOpen(false);
                    setVisitBadgeToPrint(undefined);
                }}
                width={600}
            >
                {!!visitBadgeToPrint &&
                    <Row
                        align="middle"
                        justify="center"
                    >
                        <Col
                            span={24}
                            style={{
                                margin: '5px 0',
                            }}
                        >
                            <Radio.Group
                                value={badgeType}
                                onChange={(e) => isBadgeType(e.target.value) ? setBadgeType(e.target.value) : setBadgeType('original')}
                            >
                                <Radio
                                    value={'original'}
                                >
                                    {t('Types.BadgeType.original')}
                                </Radio>

                                <Radio
                                    value={'long'}
                                >
                                    {t('Types.BadgeType.long')}
                                </Radio>
                            </Radio.Group>
                        </Col>

                        <Col>
                            {badgeType === 'long'
                                ?
                                <Badge2
                                    ref={badgeRef}
                                    visit={visitBadgeToPrint}
                                />
                                :
                                <div
                                    style={{
                                        width: '4in',
                                        height: '4.5in',
                                    }}
                                >
                                    <Badge
                                        ref={badgeRef}
                                        visit={visitBadgeToPrint}
                                    />
                                </div>

                            }

                        </Col>
                    </Row>

                }
            </Modal>
        </Card>
    );
};