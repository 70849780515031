import * as React from 'react';

import { Card, Checkbox, Col, Form, FormProps, Radio, Row, Select, Space } from 'antd';

import { AppButton } from '@/components/appButton';
import { SafetyQuestionnaire } from 'drawbridge.shared/models/safetyQuestionnaire';
import { ValidateMessages } from 'rc-field-form/lib/interface';
import { nameof } from 'ts-simple-nameof';
import { useFormValidateMessages } from '@/hooks';
import { useTranslation } from 'react-i18next';

interface SafetyFormValues extends SafetyQuestionnaire { }
interface SafetyFormProps extends FormProps<SafetyFormValues> {
    isLoading?: boolean;
    safetyQuesionnaire?: SafetyQuestionnaire;
}

export const SafetyForm: React.FC<SafetyFormProps> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm<SafetyFormValues>();

    const defaultFormValidateMessages = useFormValidateMessages();
    // Don't really want to use our default validateMessages since they include the
    // field names which just look weird here
    const validateMessages: ValidateMessages = {
        ...defaultFormValidateMessages,
        required: t('Common.validation.required_field_generic_message'),
    };

    const { isLoading, safetyQuesionnaire, ...formProps } = props;

    return (
        <Form
            className="safety-form"
            layout="vertical"
            validateMessages={validateMessages}
            requiredMark={false}
            {...formProps}
            form={form}
            onFinishFailed={(errorInfo) => form.scrollToField(errorInfo.errorFields[0]?.name ?? '', { behavior: 'smooth', block: 'center', inline: 'start' })}
        >
            <Card>
                <Form.Item
                    id="construction"
                    label={
                        <span className="safety-form-label">
                            {t('Components.Forms.SafetyForm.is_construction_work_label')}
                        </span>
                    }
                    className="safety-form-item"
                    name={nameof<SafetyFormValues>(x => x.isConstructionWork)}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            type: 'boolean',
                            warningOnly: true,
                            validator: (_rule, value) => {
                                if (value === true) {
                                    return Promise.reject(t('Components.Forms.SafetyForm.ehs_plan_needed'));
                                }

                                return Promise.resolve();
                            },
                        },
                    ]}
                    initialValue={safetyQuesionnaire?.isConstructionWork}
                >
                    <Radio.Group
                        buttonStyle="solid"
                    >
                        <Space
                            direction="horizontal"
                        >
                            <Radio.Button
                                value={false}
                                className="safety-radio"
                            >
                                {t('Common.no')}
                            </Radio.Button>

                            <Radio.Button
                                value={true}
                                className="safety-radio"
                            >
                                {t('Common.yes')}
                            </Radio.Button>
                        </Space>

                    </Radio.Group>
                </Form.Item>
            </Card>

            <Card>
                <Form.Item
                    label={
                        <span className="safety-form-label">
                            {t('Components.Forms.SafetyForm.permit_required_work_label')}
                        </span>
                    }
                    className="safety-form-item"
                    name={nameof<SafetyFormValues>(x => x.permitRequiredWork)}
                    rules={[{ required: true }]}
                    initialValue={safetyQuesionnaire?.permitRequiredWork}
                >
                    <Select>

                        <Select.Option
                            key="no-permit"
                            value={false}
                        >
                            {t('Types.PermitType.none')}
                        </Select.Option>

                        <Select.OptGroup
                            label={t('Types.PermitType.label')}
                        >
                            <Select.Option
                                key="hot-work"
                                value={'Hot Work'}
                            >
                                {t('Types.PermitType.hot_work')}
                            </Select.Option>

                            <Select.Option
                                key="confined-space"
                                value={'Confined Space'}
                            >
                                {t('Types.PermitType.confined_space')}
                            </Select.Option>

                            <Select.Option
                                key="laser-maintenance"
                                value={'Laser Maintenance'}
                            >
                                {t('Types.PermitType.laser_maintenance')}
                            </Select.Option>
                        </Select.OptGroup>
                    </Select>
                </Form.Item>
            </Card>

            <Card>
                <Form.Item
                    label={
                        <span className="safety-form-label">
                            {t('Components.Forms.SafetyForm.is_working_at_heights_label')}
                        </span>
                    }
                    className="safety-form-item"
                    name={nameof<SafetyFormValues>(x => x.isWorkingAtHeights)}
                    rules={[{ required: true }]}
                    initialValue={safetyQuesionnaire?.isWorkingAtHeights}
                >
                    <Radio.Group
                        buttonStyle="solid"
                    >
                        <Space
                            direction="horizontal"
                        >
                            <Radio.Button
                                value={false}
                                className="safety-radio"
                            >
                                {t('Common.no')}
                            </Radio.Button>

                            <Radio.Button
                                value={true}
                                className="safety-radio"
                            >
                                {t('Common.yes')}
                            </Radio.Button>
                        </Space>

                    </Radio.Group>
                </Form.Item>
            </Card>

            <Card>
                <Form.Item
                    label={
                        <span className="safety-form-label">
                            {t('Components.Forms.SafetyForm.is_risk_of_spill_label')}
                        </span>
                    }
                    className="safety-form-item"
                    name={nameof<SafetyFormValues>(x => x.isRiskOfSpill)}
                    rules={[{ required: true }]}
                    initialValue={safetyQuesionnaire?.isRiskOfSpill}
                >
                    <Radio.Group
                        buttonStyle="solid"
                    >
                        <Space
                            direction="horizontal"
                        >
                            <Radio.Button
                                value={false}
                                className="safety-radio"
                            >
                                {t('Common.no')}
                            </Radio.Button>

                            <Radio.Button
                                value={true}
                                className="safety-radio"
                            >
                                {t('Common.yes')}
                            </Radio.Button>
                        </Space>

                    </Radio.Group>
                </Form.Item>
            </Card>

            <Card>
                <Form.Item
                    label={
                        <span className="safety-form-label">
                            {t('Components.Forms.SafetyForm.is_bringing_outside_chemicals_label')}
                        </span>
                    }
                    className="safety-form-item"
                    name={nameof<SafetyFormValues>(x => x.isBringingOutsideChemicals)}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            type: 'boolean',
                            warningOnly: true,
                            validator: (_rule, value) => {
                                if (value === true) {
                                    return Promise.reject(t('Components.Forms.SafetyForm.sds_sheets_required'));
                                }

                                return Promise.resolve();
                            },
                        },
                    ]}
                    initialValue={safetyQuesionnaire?.isBringingOutsideChemicals}
                >
                    <Radio.Group
                        buttonStyle="solid"
                    >
                        <Space
                            direction="horizontal"
                        >
                            <Radio.Button
                                value={false}
                                className="safety-radio"
                            >
                                {t('Common.no')}
                            </Radio.Button>

                            <Radio.Button
                                value={true}
                                className="safety-radio"
                            >
                                {t('Common.yes')}
                            </Radio.Button>
                        </Space>

                    </Radio.Group>
                </Form.Item>
            </Card>

            <Card>
                <Form.Item
                    label={
                        <span className="safety-form-label">
                            {t('Components.Forms.SafetyForm.special_work_label')}
                        </span>
                    }
                    className="safety-form-item"
                    name={nameof<SafetyFormValues>(x => x.specialWork)}
                    rules={[{ required: true }]}
                    initialValue={safetyQuesionnaire?.specialWork}
                >
                    <Select>
                        <Select.Option
                            key="no-special-work"
                            value={false}
                        >
                            {t('Types.SpecialWork.none')}
                        </Select.Option>

                        <Select.OptGroup
                            label={t('Types.SpecialWork.label')}
                        >
                            <Select.Option
                                key="electrical"
                                value={'Electrical'}
                            >
                                {t('Types.SpecialWork.electrical')}
                            </Select.Option>

                            <Select.Option
                                key="mechanical"
                                value={'Mechanical'}
                            >
                                {t('Types.SpecialWork.mechanical')}
                            </Select.Option>

                            <Select.Option
                                key="automation"
                                value={'Automation'}
                            >
                                {t('Types.SpecialWork.automation')}
                            </Select.Option>
                        </Select.OptGroup>
                    </Select>
                </Form.Item>
            </Card>

            <Card>
                <Form.Item
                    label={
                        <span className="safety-form-label">
                            {t('Components.Forms.SafetyForm.is_lifting_device_required_label')}
                        </span>
                    }
                    className="safety-form-item"
                    name={nameof<SafetyFormValues>(x => x.isLiftingDeviceRequired)}
                    rules={[{ required: true }]}
                    initialValue={safetyQuesionnaire?.isLiftingDeviceRequired}
                >
                    <Radio.Group
                        buttonStyle="solid"
                    >
                        <Space
                            direction="horizontal"
                        >
                            <Radio.Button
                                value={false}
                                className="safety-radio"
                            >
                                {t('Common.no')}
                            </Radio.Button>

                            <Radio.Button
                                value={true}
                                className="safety-radio"
                            >
                                {t('Common.yes')}
                            </Radio.Button>
                        </Space>

                    </Radio.Group>
                </Form.Item>
            </Card>

            <Card>
                <Form.Item
                    className="safety-form-item safety-form-confirmation"
                    name={nameof<SafetyFormValues>(x => x.hasReviewedEmergencyProcedures)}
                    rules={[{ required: true, message: t('Common.validation.confirmation_required') }]}
                    initialValue={safetyQuesionnaire?.hasReviewedEmergencyProcedures}
                >
                    <Checkbox.Group>
                        <Checkbox
                            value={true}
                            className="safety-checkbox"
                        >
                            {t('Components.Forms.SafetyForm.has_reviewed_emergency_procedures_confirmation_text')}
                        </Checkbox>
                    </Checkbox.Group>
                </Form.Item>

                <Form.Item
                    className="safety-form-item safety-form-confirmation"
                    name={nameof<SafetyFormValues>(x => x.isTrained)}
                    rules={[{ required: true, message: t('Common.validation.confirmation_required') }]}
                    initialValue={safetyQuesionnaire?.isTrained}
                >
                    <Checkbox.Group>
                        <Checkbox
                            value={true}
                            className="safety-checkbox"
                        >
                            {t('Components.Forms.SafetyForm.is_trained_confirmation_text')}
                        </Checkbox>
                    </Checkbox.Group>

                </Form.Item>
            </Card>

            <Form.Item
                className="safety-form-buttons"
            >
                <Row
                    align="middle"
                    justify="center"
                    gutter={50}
                >
                    <Col
                        xxl={10}
                        xl={12}
                        lg={14}
                        md={16}
                        sm={18}
                        xs={20}
                    >
                        <AppButton
                            style={{ width: '100%' }}
                            htmlType="submit"
                            className="safety-form-button"
                            disabled={isLoading}
                        >
                            {t('Common.next')}
                        </AppButton>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};