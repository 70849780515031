import { AnyAction, Store } from 'redux';
import { PersistConfig, Persistor, createTransform, persistReducer, persistStore } from 'redux-persist';
import {
    appDrawerReducer,
    deviceConfigReducer,
    emergencyAttendanceReducer,
    employeeReducer,
    externalVisitorReducer,
    facilityConfigReducer,
    facilityReducer,
    globalConfigReducer,
    localeReducer,
    securityReducer,
    serviceWorkerReducer,
    signInReducer,
    socketReducer,
    vendorReducer,
    visitReducer,
    wsibReducer,
} from '@/store/modules';

import { AppDispatch } from '@reduxHelpers';
import { DeviceConfigState } from '@/store/state/deviceConfigState';
import { EmergencyAttendance } from 'drawbridge.shared/models/dataModels/emergencyAttendance';
import { OfflineEmergencyAttendanceState } from '@/store/state/offlineEmergencyAttendanceState';
import { SecurityState } from '@state/securityState';
import { VisitState } from '@/store/state/visitState';
import { combineReducers } from 'redux';
import { configureStore as configureReduxStore } from '@reduxjs/toolkit';
import { convertDates } from 'drawbridge.shared/utility/objectHelpers';
import { nameof } from 'ts-simple-nameof';
import { offlineEmergencyAttendanceReducer } from '@/store/modules/offlineEmergencyAttendanceModule';
import { socketMiddleware } from '@/store/middleware/socketMiddleware';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const rootPersistConfig: PersistConfig<RootState> = {
    key: 'root',
    storage: storage,
    // Reducers we want to persist
    // Important: Do not include any reducers that need additional configs, e.g. security and deviceConfig modules shouldn't persist access token
    // Use a separate persist config for these cases instead
    whitelist: [
        nameof<RootState>(x => x.signIn),
    ],
};

const deviceConfigPersistConfig: PersistConfig<DeviceConfigState> = {
    key: 'deviceConfig',
    storage: storage,
    // Don't persist deviceAccessToken (refresh will be used on reloads)
    // Don't persist isRefreshingToken or refreshPromise - saving these can leave us in a 'stuck' state where the app thinks we're already refreshing so we never actually do
    blacklist: [
        nameof<DeviceConfigState>(x => x.deviceAccessToken),
        nameof<DeviceConfigState>(x => x.isRefreshingToken),
        nameof<DeviceConfigState>(x => x.refreshPromise),
        nameof<DeviceConfigState>(x => x.hasError),
    ],
};

const securityPersistConfig: PersistConfig<SecurityState> = {
    key: 'security',
    storage: storage,
    // Don't persist accessToken (refresh will be used on reloads)
    // Don't persist isRefreshingToken or refreshPromise - saving these can leave us in a 'stuck' state where the app thinks we're already refreshing so we never actually do
    blacklist: [
        nameof<SecurityState>(x => x.accessToken),
        nameof<SecurityState>(x => x.isRefreshingToken),
        nameof<SecurityState>(x => x.refreshPromise),
    ],
};

const visitPersistConfig: PersistConfig<VisitState> = {
    key: 'visit',
    storage: storage,
    whitelist: [
        nameof<VisitState>(x => x.currentVisits),
    ],
    transforms: [
        createTransform((inboundState) => {
            return inboundState;
        }, (outboundState) => {
            if (Array.isArray(outboundState)) {
                outboundState.forEach(x => convertDates(x));
            }

            return outboundState;
        }),
    ],
};

const offlineEmergencyAttendanceConfig: PersistConfig<OfflineEmergencyAttendanceState> = {
    key: 'offlineEmergencyAttendance',
    storage: storage,
    whitelist: [
        nameof<OfflineEmergencyAttendanceState>(x => x.emergencyAttendance),
        nameof<OfflineEmergencyAttendanceState>(x => x.completedEmergencyAttendance),
    ],
    transforms: [
        createTransform<EmergencyAttendance, EmergencyAttendance>((inboundState) => {
            return inboundState;
        }, (outboundState) => {
            convertDates(outboundState);

            outboundState.emergencyAttendanceEmployeeVisits?.forEach(x => convertDates(x));
            outboundState.emergencyAttendanceExternalVisitorVisits?.forEach(x => convertDates(x));

            return outboundState;
        }),
    ],
};

const makeRootReducer = combineReducers({
    deviceConfig: persistReducer(deviceConfigPersistConfig, deviceConfigReducer),
    drawer: appDrawerReducer,
    emergencyAttendance: emergencyAttendanceReducer,
    employee: employeeReducer,
    externalVisitor: externalVisitorReducer,
    facilityConfig: facilityConfigReducer,
    facility: facilityReducer,
    globalConfig: globalConfigReducer,
    locale: localeReducer,
    offlineEmergencyAttendance: persistReducer(offlineEmergencyAttendanceConfig, offlineEmergencyAttendanceReducer),
    security: persistReducer(securityPersistConfig, securityReducer),
    serviceWorker: serviceWorkerReducer,
    signIn: signInReducer,
    socket: socketReducer,
    vendor: vendorReducer,
    visit: persistReducer(visitPersistConfig, visitReducer),
    wsib: wsibReducer,
});

export type RootState = ReturnType<typeof makeRootReducer>;

export function configureStore(initialState?: RootState): { store: Store<RootState, AnyAction> & { dispatch: AppDispatch }, persistor: Persistor } {
    const rootReducer = makeRootReducer;

    const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

    const store = configureReduxStore({
        reducer: persistedReducer,
        preloadedState: initialState,
        middleware: [thunk, socketMiddleware],
        devTools: process.env.NODE_ENV === 'development',
    });

    const persistor = persistStore(store);

    return { store, persistor };
}