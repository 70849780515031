import { callDeviceAuthApi, callUserAuthApi } from '@/utility/api';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { FacilityConfig } from 'drawbridge.shared/models/dataModels/facilityConfig';
import { FacilityConfigState } from '@/store/state/facilityConfigState';
import { FacilityEvacuationMapDocument } from 'drawbridge.shared/models/dataModels/facilityEvacuationMapDocument';
import { FacilityHealthAndSafetyDocument } from 'drawbridge.shared/models/dataModels/facilityHealthAndSafetyDocument';
import { FortinetTestConnectionResult } from 'drawbridge.shared/models/fortinet/testConnectionResult';
import { HttpMethod } from 'drawbridge.shared/constants/httpMethod';
import { MakeOptional } from '@/types/typescriptHelpers';
import { createApiThunk } from '@/types/reduxHelpers';

export const getFacilityConfigById = createApiThunk<FacilityConfig | undefined, { facilityConfigId: number }>('/facilityConfig/getFacilityConfigById', async (params, thunkApi) => {
    const response = await callDeviceAuthApi<FacilityConfig | undefined>(`/facilityConfig/id/${params.facilityConfigId}`);

    return response.data;
});

export const getFacilityConfigByIdAdmin = createApiThunk<FacilityConfig | undefined, { facilityConfigId: number }>('/facilityConfig/getFacilityConfigByIdAdmin', async (params, thunkApi) => {
    const response = await callUserAuthApi<FacilityConfig | undefined>(`/facilityConfig/id/${params.facilityConfigId}`);

    return response.data;
});

export const getFacilityConfigByFacilityId = createApiThunk<FacilityConfig | undefined, { facilityId: number }>('/facilityConfig/getFacilityConfigByFacilityId', async (params, thunkApi) => {
    const response = await callDeviceAuthApi<FacilityConfig | undefined>(`/facilityConfig/facility/${params.facilityId}`);

    return response.data;
});

export const getFacilityConfigByFacilityIdAdmin = createApiThunk<FacilityConfig | undefined, { facilityId: number }>('/facilityConfig/getFacilityConfigByFacilityIdAdmin', async (params, thunkApi) => {
    const response = await callUserAuthApi<FacilityConfig | undefined>(`/facilityConfig/facility/${params.facilityId}`);

    return response.data;
});

export const createFacilityConfig = createApiThunk<FacilityConfig, MakeOptional<FacilityConfig, 'id'>>('/facilityConfig/createFacilityConfig', async (params, thunkApi) => {
    const response = await callUserAuthApi<FacilityConfig>('/facilityConfig/createFacilityConfig', HttpMethod.POST, {
        ...params,
    });

    return response.data;
});

export const updateFacilityConfig = createApiThunk<FacilityConfig, FacilityConfig>('/facilityConfig/updateFacilityConfig', async (facilityConfig, thunkApi) => {
    const response = await callUserAuthApi<FacilityConfig>('/facilityConfig/updateFacilityConfig', HttpMethod.PUT, {
        ...facilityConfig,
    });

    return response.data;
});

export const getFacilityEvacuationMapDocument = createApiThunk<FacilityEvacuationMapDocument | undefined, { facilityId: number }>('/facilityConfig/getFacilityEvacuationMapDocument', async (args, thunkApi) => {
    const response = await callDeviceAuthApi<FacilityEvacuationMapDocument>(`/facilityConfig/getFacilityEvacuationMapDocument/${args.facilityId}`);

    return response.data;
});

export const getFacilityEvacuationMapDocumentAdmin = createApiThunk<FacilityEvacuationMapDocument | undefined, { facilityId: number }>('/facilityConfig/getFacilityEvacuationMapDocumentAdmin', async (args, thunkApi) => {
    const response = await callUserAuthApi<FacilityEvacuationMapDocument>(`/facilityConfig/getFacilityEvacuationMapDocument/${args.facilityId}`);

    return response.data;
});

export const getFacilityHealthAndSafetyDocument = createApiThunk<FacilityHealthAndSafetyDocument | undefined, { facilityId: number }>('/facilityConfig/getFacilityHealthAndSafetyDocument', async (args, thunkApi) => {
    const response = await callDeviceAuthApi<FacilityHealthAndSafetyDocument>(`/facilityConfig/getFacilityHealthAndSafetyDocument/${args.facilityId}`);

    return response.data;
});

export const getFacilityHealthAndSafetyDocumentAdmin = createApiThunk<FacilityHealthAndSafetyDocument | undefined, { facilityId: number }>('/facilityConfig/getFacilityHealthAndSafetyDocumentAdmin', async (args, thunkApi) => {
    const response = await callUserAuthApi<FacilityHealthAndSafetyDocument>(`/facilityConfig/getFacilityHealthAndSafetyDocument/${args.facilityId}`);

    return response.data;
});

export const uploadFacilityEvacuationMapDocument = createApiThunk<FacilityEvacuationMapDocument, { facilityId: number, filename: string, file: File }>('/facilityConfig/uploadFacilityEvacuationMapDocument', async (args, thunkApi) => {
    const formData = new FormData();

    formData.append('facilityEvacuationMapDocument', args.file, args.filename);
    formData.append('facilityId', args.facilityId.toString());

    const response = await callUserAuthApi<FacilityEvacuationMapDocument>('/facilityConfig/uploadFacilityEvacuationMapDocument', HttpMethod.POST, formData);

    return response.data;
});

export const deleteFacilityEvacuationMapDocument = createApiThunk<void, { facilityId: number }>('/facilityConfig/deleteFacilityEvacuationMapDocument', async (args, thunkApi) => {
    await callUserAuthApi<void>('/facilityConfig/deleteFacilityEvacuationMapDocument', HttpMethod.POST, {
        facilityId: args.facilityId,
    });
});

export const uploadFacilityHealthAndSafetyDocument = createApiThunk<FacilityHealthAndSafetyDocument, { facilityId: number, filename: string, file: File }>('/facilityConfig/uploadFacilityHealthAndSafetyDocument', async (args, thunkApi) => {
    const formData = new FormData();

    formData.append('facilityHealthAndSafetyDocument', args.file, args.filename);
    formData.append('facilityId', args.facilityId.toString());

    const response = await callUserAuthApi<FacilityHealthAndSafetyDocument>('/facilityConfig/uploadFacilityHealthAndSafetyDocument', HttpMethod.POST, formData);

    return response.data;
});

export const deleteFacilityHealthAndSafetyDocument = createApiThunk<void, { facilityId: number }>('/facilityConfig/deleteFacilityHealthAndSafetyDocument', async (args, thunkApi) => {
    await callUserAuthApi<void>('/facilityConfig/deleteFacilityHealthAndSafetyDocument', HttpMethod.POST, {
        facilityId: args.facilityId,
    });
});

export const testGuestWifiConnection = createApiThunk<FortinetTestConnectionResult, { fortinetServerUrl: string }>('/guestWifi/testConnection', async (params, thunkApi) => {
    const response = await callUserAuthApi<FortinetTestConnectionResult>(`/guestWifi/testConnection`, HttpMethod.POST, {
        fortinetServerUrl: params.fortinetServerUrl,
    });

    return response.data;
});

export const setFacilityConfig = createAction<FacilityConfig | undefined>('/facilityConfig/setFacilityConfig');
export const facilityConfigChanged = createAction<FacilityConfig | undefined>('/facilityConfig/facilityConfigUpdated');

const initialState: FacilityConfigState = {
    isLoading: false,
    facilityConfig: undefined,
};

export const facilityConfigReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setFacilityConfig, (state, action) => {
            state.facilityConfig = action.payload;
        })
        .addCase(facilityConfigChanged, (state, action) => {
            state.facilityConfig = action.payload;
        })
        .addCase(createFacilityConfig.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(createFacilityConfig.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        .addCase(createFacilityConfig.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(updateFacilityConfig.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(updateFacilityConfig.fulfilled, (state, action) => {
            state.isLoading = false;

            if (state.facilityConfig?.id === action.payload.id) {
                state.facilityConfig = action.payload;
            }
        });
});