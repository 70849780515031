import './badge2.less';

import * as React from 'react';

import { Col, ColProps, Row } from 'antd';

import { Guest } from 'drawbridge.shared/models/fortinet/guest';
import { QRCodeCanvas } from 'qrcode.react';
import { Visit } from 'drawbridge.shared/models/visit';
import { VisitQrData } from 'drawbridge.shared/models/visitQrData';
import { useAppSelector } from '@/types/reduxHelpers';
import { useTranslation } from 'react-i18next';

interface BadgeProps {
    visit: Visit;
    guest?: Guest;
    isPerformingWork?: boolean;
}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>((props, ref) => {
    const { t } = useTranslation();

    const currentLocale = useAppSelector((state) => state.locale.currentLocale);
    const deviceConfig = useAppSelector((state) => state.deviceConfig.deviceConfig);

    const { visit } = props;
    const { visitType } = visit;

    const visitQrData: VisitQrData = {
        visitId: visit.id ?? -1,
        visitType: visitType,
    };

    const infoLabelColProps: ColProps = {
        span: 24,
        style: {
            textAlign: 'center',
            fontSize: '10pt',
            margin: 0,
            lineHeight: '18px',
            marginTop: 8,
        },
    };

    const infoValueColProps: ColProps = {
        span: 24,
        style: {
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '25px',
            margin: 0,
            lineHeight: '25px',
        },
    };

    return (
        <Row
            ref={ref}
            align="stretch"
            justify="center"
            className="badge2"
        >
            <Col span={24} style={{ padding: 0 }}>
                <div
                    className="badge-content"
                >
                    <Row
                        className="badge-first"
                        align="top"
                        justify="center"
                    >
                        <Col
                            span={24}
                            style={{
                                height: '0.6in',
                            }}
                        >
                            <Row
                                align="middle"
                                justify="space-between"
                                className="badge-header"
                                wrap={false}
                            >
                                <Col
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '20pt',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {visit.facility?.facilityName}
                                </Col>
                                <Col
                                    style={{
                                        textAlign: 'right',
                                        width: '78px',
                                    }}
                                >
                                    <QRCodeCanvas
                                        size={78}
                                        value={JSON.stringify(visitQrData)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col
                            span={24}

                        >
                            <Row
                                align="middle"
                                justify="space-around"
                                style={{
                                    margin: 0,
                                    padding: 0,
                                }}
                                className="badge-visitor-type"
                            >
                                <Col
                                    span={12}
                                    className="visitor-type"
                                >
                                    {t(`Types.VisitType.${visitType}`)}
                                </Col>
                                {visit.visitor?.visitorType === 'external' && deviceConfig?.isCorporateMode === false &&
                                    <Col
                                        span={12}
                                        style={{ textAlign: 'center' }}
                                        className="safety-training"
                                    >
                                        <Row
                                            align="top"
                                            justify="center"
                                        >
                                            <Col>
                                                <span className="safety-training-header">{t('Components.Badge.safety_training_header')}&nbsp;</span>
                                            </Col>
                                            <Col>
                                                <span className="safety-training-value">{props.isPerformingWork ? t('Common.yes') : t('Common.no')}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row
                                align="middle"
                                justify="center"
                                className="badge-visitor-info"
                            >
                                <Col span={24}>
                                    <Row
                                        align="middle"
                                        justify="center"
                                        gutter={[10, 0]}
                                        style={{ padding: 0 }}
                                    >
                                        <Col
                                            span={24}
                                            style={{
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                fontSize: '30px',
                                                lineHeight: '30px',
                                                margin: '7px 0',
                                            }}
                                            className="visitor-name-value"
                                        >
                                            {visit.visitor?.fullName}
                                        </Col>

                                        <Col
                                            {...infoLabelColProps}
                                        >
                                            {visitType === 'employee' ? t('Components.Badge.facility_header') : t('Components.Badge.company_header')}:
                                        </Col>

                                        <Col
                                            {...infoValueColProps}
                                        >
                                            {visit.visitor?.visitorType === 'employee' ? visit.visitor.facility?.facilityName ?? 'Linamar' : visit.visitor?.company}
                                        </Col>

                                        <Col
                                            {...infoLabelColProps}
                                        >
                                            {t('Components.Badge.visit_reason_header')}:
                                        </Col>

                                        <Col
                                            {...infoValueColProps}
                                        >
                                            {t(`Types.VisitReason.${visit.visitReason}`)}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                    </Row>

                    <Row
                        className="badge-second"
                        align="middle"
                        justify="center"
                    >
                        <Row
                            align="top"
                            justify="center"
                            className="badge-visitor-info"
                        >
                            <Col span={24}>
                                <Row
                                    align="middle"
                                    justify="center"
                                    gutter={[10, 0]}
                                    style={{ padding: 0 }}
                                >
                                    <Col
                                        {...infoLabelColProps}
                                    >
                                        {t('Components.Badge.visiting_header')}:
                                    </Col>

                                    <Col
                                        {...infoValueColProps}
                                    >
                                        {visit.facilityContact?.fullName}
                                    </Col>

                                    <Col
                                        {...infoLabelColProps}
                                    >
                                        {t('Components.Badge.date_header')}:
                                    </Col>

                                    <Col
                                        {...infoValueColProps}
                                    >
                                        {visit.signedInAt.toLocaleDateString(currentLocale, { month: 'short', day: 'numeric', year: 'numeric' })} {visit.signedInAt.toLocaleTimeString(currentLocale, { timeStyle: 'short' })}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {!!props.guest &&
                            <Row
                                align="top"
                                justify="center"
                                gutter={[10, 0]}
                                style={{ padding: 0 }}
                                className="badge-guest-info"
                            >
                                <Col
                                    {...infoLabelColProps}
                                >
                                    {t('Components.Badge.guest_wifi_username_header')}:
                                </Col>

                                <Col
                                    {...infoValueColProps}
                                >
                                    {props.guest.userId}
                                </Col>

                                <Col
                                    {...infoLabelColProps}
                                >
                                    {t('Components.Badge.guest_wifi_password_header')}:
                                </Col>

                                <Col
                                    {...infoValueColProps}
                                >
                                    {props.guest.password}
                                </Col>
                            </Row>
                        }
                    </Row>
                </div>
            </Col>
        </Row>
    );
});

Badge.displayName = 'Badge';

export { Badge };