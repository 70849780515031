import * as React from 'react';

import { Col, Form, FormProps, Row } from 'antd';

import { AppButton } from '@/components/appButton';
import { ExternalVisitor } from 'drawbridge.shared/models/dataModels/externalVisitor';
import { ExternalVisitorSearch } from '@/components/externalVisitorSearch';
import { nameof } from 'ts-simple-nameof';
import { useTranslation } from 'react-i18next';

interface ReturningVisitorFormValues {
    externalVisitor: ExternalVisitor;
}

interface ReturningVisitorFormProps extends FormProps<ReturningVisitorFormValues> {
    isLoading?: boolean;
    externalVisitor?: ExternalVisitor;
}

export const ReturningVisitorForm: React.VFC<ReturningVisitorFormProps> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm<ReturningVisitorFormValues>();

    const { isLoading, externalVisitor, ...formProps } = props;

    return (
        <Form
            className="returning-visitor-form"
            layout="vertical"
            labelAlign="right"
            autoComplete="off"
            {...formProps}
            form={form}
        >
            <Form.Item
                label={t('Components.Forms.ReturningVisitorForm.visitor_label')}
                className="returning-visitor-form-item"
                name={nameof<ReturningVisitorFormValues>(x => x.externalVisitor)}
                rules={[{ required: true }]}
                initialValue={externalVisitor}
            >
                <ExternalVisitorSearch
                    placeholder={t('Components.Forms.ReturningVisitorForm.visitor_placeholder')}
                />
            </Form.Item>

            <Form.Item>
                <Row>
                    <Col
                        span={24}
                        style={{ textAlign: 'center' }}
                    >
                        <AppButton
                            htmlType="submit"
                            size="middle"
                            className="returning-visitor-form-button"
                            disabled={isLoading}
                            loading={isLoading}
                        >
                            {t('Common.next')}
                        </AppButton>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};