import * as React from 'react';

import { Col, Row, notification } from 'antd';

import { AnimatedHeader } from '@/components/animatedHeader';
import { AppButton } from '@/components/appButton';
import { FarewellHeader } from '@/components/pageHeader';
import { PageWrapper } from '@/components/pageWrapper';
import { SignOutModal } from '@/components/signOutModal';
import { Visit } from 'drawbridge.shared/models/visit';
import { VisitQrReader } from '@/components/visitQrReader';
import { getVisit } from '@/store/modules/visitModule';
import { useApiErrorMessage } from '@/hooks/useApiErrorMessage';
import { useAppDispatch } from '@/types/reduxHelpers';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface SignOutByQrPageProps {

}

export const SignOutByQrPage: React.FC<SignOutByQrPageProps> = (props) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useApiErrorMessage();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const appRoutes = useAppRoutes();

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [visit, setVisit] = React.useState<Visit | undefined>(undefined);

    const [isMounted, setIsMounted] = React.useState(false);

    React.useEffect(() => {
        setIsMounted(true);

        return () => setIsMounted(false);
    }, []);

    return (
        <PageWrapper
            pageTitle={<FarewellHeader />}
            containerClassName="sign-out-by-qr-page"
            scrollBehaviorOnMount="content"
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col span={24}>
                    <AnimatedHeader>
                        {t('Pages.SignOutByQrPage.signOutHeader')}
                    </AnimatedHeader>
                </Col>

                <Col
                    span={24}
                >
                    {!visit && isMounted &&
                        <VisitQrReader
                            shouldScanOnRender={true}
                            onScan={async (data) => {
                                const action = await dispatch(getVisit({
                                    visitId: data.visitId,
                                    visitType: data.visitType,
                                }));

                                if (getVisit.fulfilled.match(action)) {
                                    setVisit(action.payload);

                                    if (!!action.payload?.signedOutAt) {
                                        notification.error({
                                            message: t('Common.error'),
                                            description: t('Pages.SignOutByQrPage.already_signed_out_message'),
                                        });

                                        setVisit(undefined);

                                        return;
                                    }

                                    // setVisit(action.payload);
                                    setIsModalOpen(true);
                                } else {
                                    notification.error({
                                        message: t('Common.error'),
                                        description: getErrorMessage(action.payload),
                                    });
                                }
                            }}
                            onError={(err) => {
                                console.log(err);
                                if (!!err.message) {
                                    notification.error({
                                        message: t('Common.error'),
                                        description: err.message,
                                    });
                                }
                            }}
                        />
                    }


                </Col>

                <Col
                    span={24}
                    style={{ textAlign: 'center' }}
                >
                    <AppButton
                        size="middle"
                        style={{ marginTop: 20 }}
                        onClick={() => {
                            navigate(appRoutes.home.path);
                        }}
                    >
                        {t('Common.cancel')}
                    </AppButton>
                </Col>
            </Row>

            {!!visit &&
                <SignOutModal
                    isOpen={isModalOpen}
                    onClose={() => {
                        setVisit(undefined);
                        setIsModalOpen(false);
                    }}
                    onSignedOut={() => {
                        setVisit(undefined);
                        navigate(appRoutes.home.path);
                    }}
                    visit={visit}
                />
            }
        </PageWrapper>
    );
};