import * as React from 'react';

import { Form, FormProps, Input, Space } from 'antd';
import { MailOutlined, PhoneOutlined, TagOutlined } from '@ant-design/icons';
import { VendorAutoComplete, VendorSearch } from '@/components/vendorSearch';

import { AppButton } from '@/components/appButton';
import { ExternalVisitor } from 'drawbridge.shared/models/dataModels/externalVisitor';
import { MakeOptional } from '@/types/typescriptHelpers';
import { Vendor } from 'drawbridge.shared/models/dataModels/vendor';
import { nameof } from 'ts-simple-nameof';
import { useTranslation } from 'react-i18next';

export interface ExternalVisitorFormValues {
    id?: number;
    company: string;
    vendor?: Vendor;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    email?: string;
}

interface ExternalVisitorFormProps extends FormProps<ExternalVisitorFormValues> {
    isLoading?: boolean;
    isVendorRequired?: boolean;
    externalVisitor?: MakeOptional<ExternalVisitor, 'id'>;
    showCancel?: boolean;
    onCancel?: () => void;
    cancelText?: string;
    submitText?: string;
    isReadOnly?: boolean;
}

export const ExternalVisitorForm: React.VFC<ExternalVisitorFormProps> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm<ExternalVisitorFormValues>();

    const {
        isLoading,
        isVendorRequired,
        externalVisitor,
        showCancel,
        onCancel,
        cancelText,
        submitText,
        isReadOnly,
        ...formProps
    } = props;

    return (
        <Form
            layout="vertical"
            labelAlign="right"
            {...formProps}
            form={form}
            onFinish={(values) => {
                const newValues: ExternalVisitorFormValues = {
                    ...values,
                };

                // If vendor is required, we'll have a 'vendor' but no 'company'
                // Set the company to the vendor name if we have a vendor
                if (values.vendor) {
                    newValues.company = values.vendor.vendorName;
                } else {
                    // If vendor is not required, 'company' can be either a Vendor or a string, depending on
                    // whether or not they selected a result from VendorAutoComplete or just typed something in
                    if (typeof values.company === 'string') {
                        newValues.vendor = undefined;
                    } else {
                        newValues.vendor = values.company as Vendor;
                        newValues.company = newValues.vendor.vendorName;
                    }
                }

                props.onFinish?.(newValues);
            }}
        >
            <Form.Item
                name={nameof<ExternalVisitorFormValues>(x => x.id)}
                initialValue={externalVisitor?.id}
                noStyle={true}
            >
                <Input type="hidden" />
            </Form.Item>

            {isVendorRequired
                ?
                <Form.Item
                    label={t('Components.Forms.ExternalVisitorForm.vendor_label')}
                    name={nameof<ExternalVisitorFormValues>(x => x.vendor)}
                    rules={[{ required: true }]}
                    initialValue={externalVisitor?.vendor}
                >
                    <VendorSearch
                        placeholder={t('Components.Forms.ExternalVisitorForm.vendor_placeholder')}
                        disabled={isLoading || isReadOnly}
                    />
                </Form.Item>
                :
                <Form.Item
                    label={t('Components.Forms.ExternalVisitorForm.company_label')}
                    name={nameof<ExternalVisitorFormValues>(x => x.company)}
                    rules={[{ required: true }]}
                    initialValue={externalVisitor?.vendor ?? externalVisitor?.company}
                >
                    <VendorAutoComplete
                        placeholder="Enter a company"
                        disabled={isLoading || isReadOnly}
                    />
                </Form.Item>
            }

            <Form.Item
                label={t('Components.Forms.ExternalVisitorForm.first_name_label')}
                name={nameof<ExternalVisitorFormValues>(x => x.firstName)}
                rules={[{ required: true }]}
                initialValue={externalVisitor?.firstName}
            >
                <Input
                    prefix={<TagOutlined />}
                    placeholder={t('Components.Forms.ExternalVisitorForm.first_name_placeholder')}
                    disabled={isLoading || isReadOnly}
                />
            </Form.Item>

            <Form.Item
                label={t('Components.Forms.ExternalVisitorForm.last_name_label')}
                name={nameof<ExternalVisitorFormValues>(x => x.lastName)}
                rules={[{ required: true }]}
                initialValue={externalVisitor?.lastName}
            >
                <Input
                    prefix={<TagOutlined />}
                    placeholder={t('Components.Forms.ExternalVisitorForm.last_name_placeholder')}
                    disabled={isLoading || isReadOnly}
                />
            </Form.Item>

            <Form.Item
                label={t('Components.Forms.ExternalVisitorForm.email_label')}
                name={nameof<ExternalVisitorFormValues>(x => x.email)}
                rules={[{ required: false }, { type: 'email' }]}
                initialValue={externalVisitor?.email}
            >
                <Input
                    type="email"
                    prefix={<MailOutlined />}
                    placeholder={t('Components.Forms.ExternalVisitorForm.email_placeholder')}
                    disabled={isLoading || isReadOnly}
                />
            </Form.Item>

            <Form.Item
                label={t('Components.Forms.ExternalVisitorForm.phone_number_label')}
                name={nameof<ExternalVisitorFormValues>(x => x.phoneNumber)}
                initialValue={externalVisitor?.phoneNumber}
            >
                <Input
                    type="tel"
                    prefix={<PhoneOutlined />}
                    placeholder={t('Components.Forms.ExternalVisitorForm.phone_number_placeholder')}
                    disabled={isLoading || isReadOnly}
                />
            </Form.Item>

            <Form.Item
                style={{ textAlign: 'center' }}
            >
                <Space
                    direction="horizontal"
                >
                    {showCancel === true &&
                        <AppButton
                            loading={isLoading}
                            onClick={() => onCancel?.()}
                            size="middle"
                        >
                            {cancelText ?? t('Common.cancel')}
                        </AppButton>
                    }

                    {!isReadOnly &&
                        <AppButton
                            htmlType="submit"
                            size="middle"
                            disabled={isLoading}
                            loading={isLoading}
                        >
                            {submitText ?? t('Common.next')}
                        </AppButton>
                    }

                </Space>
            </Form.Item>

            {/*
                What a nightmare, chrome.
                https://stackoverflow.com/a/30873633/3253311
            */}
            <input
                id="PreventChromeAutoFill"
                name="PreventChromeAutoFill"
                hidden={true}
                autoComplete="here-you-go-chrome"
            />
        </Form>
    );
};