import * as React from 'react';

import { Col, Row } from 'antd';

import { AnimatedButton } from '@components/animatedButton';
import { AnimatedHeader } from '@components/animatedHeader';
import { ContactsOutlined } from '@ant-design/icons';
import { ReactComponent as LinamarIcon } from '@/assets/linamarLogo.svg';
import { PageWrapper } from '@components/pageWrapper';
import { WelcomeHeader } from '@components/pageHeader';
import { useAppRoutes } from '@/hooks';
import { useAppSelector } from '@/types/reduxHelpers';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const VisitorTypePage: React.FC = (props) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const visitDetails = useAppSelector((state) => state.signIn.visitDetails);

    const appRoutes = useAppRoutes();

    React.useEffect(() => {
        if (!visitDetails) {
            navigate(appRoutes.home.path, { replace: true });
        }
    }, []);

    return (
        <PageWrapper
            containerClassName="visitor-type-page"
            pageTitle={<WelcomeHeader />}
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col span={24}>
                    <AnimatedHeader>
                        {t('Pages.VisitorTypePage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    style={{ marginTop: 20 }}
                    xxl={8}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                >
                    <AnimatedButton
                        icon={<ContactsOutlined />}
                        animationDelay={0}
                        onClick={() => navigate(appRoutes.performingWork.path)}
                    >
                        {t('Pages.VisitorTypePage.external_visitor_button_text')}
                    </AnimatedButton>
                </Col>

                <Col
                    style={{ marginTop: 20 }}
                    xxl={8}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                >
                    <AnimatedButton
                        icon={<LinamarIcon className="primary-fill" />}
                        animationDelay={100}
                        onClick={() => navigate(appRoutes.linamarEmployee.path)}
                    >
                        {t('Pages.VisitorTypePage.linamar_employee_button_text')}
                    </AnimatedButton>
                </Col>
            </Row>
        </PageWrapper>
    );
};