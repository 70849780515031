import * as React from 'react';

import { Col, Row } from 'antd';
import { QrcodeOutlined, SearchOutlined } from '@ant-design/icons';

import { AnimatedButton } from '@/components/animatedButton';
import { AnimatedHeader } from '@/components/animatedHeader';
import { FarewellHeader } from '@/components/pageHeader';
import { PageWrapper } from '@/components/pageWrapper';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface SignOutPageProps {

}

export const SignOutPage: React.FC<SignOutPageProps> = (props) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const appRoutes = useAppRoutes();

    return (
        <PageWrapper
            containerClassName="sign-out-page"
            pageTitle={<FarewellHeader />}
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col span={24}>
                    <AnimatedHeader>
                        {t('Pages.SignOutPage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    style={{ marginTop: 20 }}
                    xxl={8}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                >
                    <AnimatedButton
                        icon={<QrcodeOutlined />}
                        animationDelay={0}
                        onClick={() => navigate(appRoutes.signOutByQr.path)}
                    >
                        {t('Pages.SignOutPage.qr_code_button_text')}
                    </AnimatedButton>
                </Col>

                <Col
                    style={{ marginTop: 20 }}
                    xxl={8}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                >
                    <AnimatedButton
                        icon={<SearchOutlined />}
                        animationDelay={50}
                        onClick={() => navigate(appRoutes.signOutBySearch.path)}
                    >
                        {t('Pages.SignOutPage.search_for_visit_button_text')}
                    </AnimatedButton>
                </Col>
            </Row>
        </PageWrapper>
    );
};