import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { AnyAction } from 'redux';
import { ApiResult } from 'drawbridge.shared/models/payloads/apiResult';
import { FailedResponse } from 'drawbridge.shared/types/responses';
import { HttpStatusCode } from 'drawbridge.shared/constants/httpStatusCode';
import { RootState } from '@/store/createStore';

export interface ThunkAPIConfig {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: FailedResponse & { noResponse?: boolean } & { status?: HttpStatusCode };
}

export const createApiThunk = <ReturnType, InputType = unknown,>(
    type: string,
    thunk: AsyncThunkPayloadCreator<ReturnType, InputType, ThunkAPIConfig>,
): AsyncThunk<ReturnType, InputType, ThunkAPIConfig> => {
    return createAsyncThunk<ReturnType, InputType, ThunkAPIConfig>(
        type,
        async (arg, thunkAPI) => {
            try {
                // do some stuff here that happens on every action
                return await thunk(arg, thunkAPI);
            } catch (err) {
                const apiResult = err as ApiResult;

                if (!!apiResult?.response?.data) {
                    return thunkAPI.rejectWithValue({
                        ...apiResult.response.data,
                        status: apiResult.response.status,
                    });
                }
                // do some stuff here that happens on every error
                return thunkAPI.rejectWithValue({
                    key: 'Common.unexpected_error',
                    defaultMessage: 'An unexpected error occurred',
                    isError: true,
                    noResponse: true,
                });
            }
        },
    );
};

export type AppThunkAction<R> = ThunkAction<R, RootState, undefined, AnyAction>;

export type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;