import * as React from 'react';

import { CloudSyncOutlined } from '@ant-design/icons';
import { PageWrapper } from '@/components/pageWrapper';
import { useTranslation } from 'react-i18next';

interface DeviceConfigErrorPageProps {

}

export const DeviceConfigErrorPage: React.FC<DeviceConfigErrorPageProps> = (props) => {
    const { t } = useTranslation();

    return (
        <PageWrapper
            isNonIdeal={true}
            nonIdealHeader={t('Pages.DeviceConfigErrorPage.header')}
            nonIdealSubheader={t('Pages.DeviceConfigErrorPage.subheader')}
            nonIdealIconType={<CloudSyncOutlined />}
            nonIdealActions={['reload']}
        />
    );
};