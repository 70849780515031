import './safetyPage.less';

import * as React from 'react';

import { Col, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AnimatedHeader } from '@/components/animatedHeader';
import { PageWrapper } from '@/components/pageWrapper';
import { SafetyForm } from '@/components/forms/safetyForm';
import { WelcomeHeader } from '@/components/pageHeader';
import { Zoom } from 'react-awesome-reveal';
import { setSafetyQuestionnaire } from '@/store/modules/signInModule';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface SafetyPageProps {
}

export const SafetyPage: React.FC<SafetyPageProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const appRoutes = useAppRoutes();
    const dispatch = useAppDispatch();

    const [isMounted, setIsMounted] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const safetyQuestionnaire = useAppSelector((state) => state.signIn.safetyQuestionnaire);

    const visitor = useAppSelector((state) => state.signIn.visitor);
    const visitDetails = useAppSelector((state) => state.signIn.visitDetails);

    React.useEffect(() => {
        setIsMounted(true);

        if (!visitor || !visitDetails) {
            navigate(appRoutes.home.path, { replace: true });
        }

        return () => {
            setIsMounted(false);
        };
    }, []);

    return (
        <PageWrapper
            pageTitle={<WelcomeHeader />}
            containerClassName="safety-page"
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col span={24}>
                    <AnimatedHeader>
                        {t('Pages.SafetyPage.header')}
                    </AnimatedHeader>
                </Col>

                <Col span={24} style={{ textAlign: 'center', fontSize: '25px', fontWeight: 'lighter', marginBottom: '10px' }}>
                    {t('Pages.SafetyPage.subheader')}
                </Col>

                <Col
                    xxl={24}
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                >
                    <Zoom
                        duration={200}
                        triggerOnce={true}
                    >
                        <SafetyForm
                            isLoading={isLoading}
                            safetyQuesionnaire={safetyQuestionnaire}
                            onFinish={async (values) => {
                                try {
                                    setIsLoading(true);

                                    dispatch(setSafetyQuestionnaire({
                                        ...values,
                                    }));

                                    // navigate(appRoutes.globalHealthAndSafety.path);
                                    navigate(appRoutes.guestWifiPage.path);
                                } catch (err) {
                                    // error
                                } finally {
                                    if (isMounted) {
                                        setIsLoading(false);
                                    }
                                }
                            }}
                        />
                    </Zoom>
                </Col>
            </Row>
        </PageWrapper>
    );
};