import { callDeviceAuthApi, callUserAuthApi } from '@/utility/api';

import { ExternalVisitor } from 'drawbridge.shared/models/dataModels/externalVisitor';
import { ExternalVisitorState } from '@/store/state/externalVisitorState';
import { HttpMethod } from 'drawbridge.shared/constants/httpMethod';
import { MakeOptional } from '@/types/typescriptHelpers';
import { createApiThunk } from '@reduxHelpers';
import { createReducer } from '@reduxjs/toolkit';

export const findExternalVisitorsByPartialName = createApiThunk<Array<ExternalVisitor>, { searchString: string }>('externalVisitor/findExternalVisitorsByPartialName', async (args, thunkApi) => {
    const response = await callDeviceAuthApi<Array<ExternalVisitor>>(`/externalVisitor/search/byName/${args.searchString}`);

    return response.data;
});

export const findExternalVisitorsBySoundex = createApiThunk<Array<ExternalVisitor>, Pick<ExternalVisitor, 'firstName' | 'lastName' | 'company' | 'vendor'>>('externalVisitor/findExternalVisitorBySoundex', async (args, thunkApi) => {
    const response = await callDeviceAuthApi<Array<ExternalVisitor>>(`/externalVisitor/search/bySoundex/${args.firstName}/${args.lastName}/${args.company}`);

    return response.data;
});

export const getAllExternalVisitors = createApiThunk<Array<ExternalVisitor>, void>('externalVisitor/getAllExternalVisitors', async (args, thunkApi) => {
    const response = await callUserAuthApi<Array<ExternalVisitor>>('/externalVisitor/getAll');

    return response.data;
});

export const deleteExternalVisitor = createApiThunk<ExternalVisitor, { externalVisitorId: number }>('externalVisitor/deleteExternalVisitor', async (args, thunkApi) => {
    const response = await callUserAuthApi<ExternalVisitor>('/externalVisitor/delete', HttpMethod.POST, {
        externalVisitorId: args.externalVisitorId,
    });

    return response.data;
});

export const restoreExternalVisitor = createApiThunk<ExternalVisitor, { externalVisitorId: number }>('externalVisitor/restoreExternalVisitor', async (args, thunkApi) => {
    const response = await callUserAuthApi<ExternalVisitor>('/externalVisitor/restore', HttpMethod.POST, {
        externalVisitorId: args.externalVisitorId,
    });

    return response.data;
});

export const createExternalVisitor = createApiThunk<ExternalVisitor, MakeOptional<ExternalVisitor, 'id'>>('externalVisitor/createExternalVisitor', async (externalVisitor, thunkApi) => {
    const response = await callUserAuthApi<ExternalVisitor>('/externalVisitor/create', HttpMethod.POST, {
        ...externalVisitor,
    });

    return response.data;
});

export const updateExternalVisitor = createApiThunk<ExternalVisitor, ExternalVisitor>('externalVisitor/updateExternalVisitor', async (externalVisitor, thunkApi) => {
    const response = await callUserAuthApi<ExternalVisitor>('/externalVisitor/update', HttpMethod.POST, {
        ...externalVisitor,
    });

    return response.data;
});

export const updateExternalVisitorVendor = createApiThunk<ExternalVisitor, { externalVisitorId: number, vendorId: number }>('externalVisitor/updateExternalVisitorVendor', async (args, thunkApi) => {
    const response = await callDeviceAuthApi<ExternalVisitor>('/externalVisitor/updateVendor', HttpMethod.POST, {
        externalVisitorId: args.externalVisitorId,
        vendorId: args.vendorId,
    });

    return response.data;
});

const initialState: ExternalVisitorState = {
    isLoading: false,
    externalVisitors: [],
};

export const externalVisitorReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getAllExternalVisitors.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getAllExternalVisitors.fulfilled, (state, action) => {
            state.isLoading = false;
            state.externalVisitors = [...action.payload];
        })
        .addCase(getAllExternalVisitors.rejected, (state, action) => {
            state.isLoading = false;
            state.externalVisitors = [];
        })
        .addCase(deleteExternalVisitor.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(deleteExternalVisitor.fulfilled, (state, action) => {
            state.isLoading = false;
            state.externalVisitors = [...state.externalVisitors.map(x => x.id === action.payload.id ? action.payload : x)];
        })
        .addCase(deleteExternalVisitor.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(restoreExternalVisitor.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(restoreExternalVisitor.fulfilled, (state, action) => {
            state.isLoading = false;
            state.externalVisitors = [...state.externalVisitors.map(x => x.id === action.payload.id ? action.payload : x)];
        })
        .addCase(restoreExternalVisitor.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(createExternalVisitor.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(createExternalVisitor.fulfilled, (state, action) => {
            state.isLoading = false;
            state.externalVisitors = [
                ...state.externalVisitors,
                action.payload,
            ];
        })
        .addCase(createExternalVisitor.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(updateExternalVisitor.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(updateExternalVisitor.fulfilled, (state, action) => {
            state.isLoading = false;
            state.externalVisitors = [...state.externalVisitors.map(x => x.id === action.payload.id ? action.payload : x)];
        })
        .addCase(updateExternalVisitor.rejected, (state, action) => {
            state.isLoading = false;
        });
});