import { createAction, createReducer } from '@reduxjs/toolkit';

import { Facility } from 'drawbridge.shared/models/dataModels/facility';
import { FacilityState } from '@/store/state/facilityState';
import { HttpMethod } from 'drawbridge.shared/constants/httpMethod';
import { callUserAuthApi } from '@/utility/api';
import { createApiThunk } from '@/types/reduxHelpers';

export const getFacility = createApiThunk<Facility | undefined, { facilityId: number }>('/facility/getFacilityById', async (params, thunkApi) => {
    const response = await callUserAuthApi<Facility | undefined>(`/facility/byId/${params.facilityId}`);

    return response.data;
});

export const getFacilityWithVendors = createApiThunk<Facility | undefined, { facilityId: number }>('/facility/getFacilityWithVendors', async (params, thunkApi) => {
    const response = await callUserAuthApi<Facility | undefined>(`/facility/withVendors/byId/${params.facilityId}`);

    return response.data;
});

export const getFacilityWithMultiFacilityEmployees = createApiThunk<Facility | undefined, { facilityId: number }>('/facility/getFacilityWithMultiFacilityEmployees', async (params, thunkApi) => {
    const response = await callUserAuthApi<Facility | undefined>(`/facility/withMultiFacilityEmployees/byId/${params.facilityId}`);

    return response.data;
});

export const getAllFacilities = createApiThunk<Array<Facility>, void>('/facility/getAllFacilities', async (_noInput, thunkApi) => {
    const response = await callUserAuthApi<Array<Facility>>('/facility/getAllFacilities');

    return response.data;
});

export const getUserFacilities = createApiThunk<Array<Facility>, void>('/facility/getUserFacilities', async (_noInput, thunkApi) => {
    const response = await callUserAuthApi<Array<Facility>>('/facility/getUserFacilities');

    return response.data;
});

export const clearUserFacilities = createAction<void>('/facility/clearUserFacilities');

export const setFacilityVendors = createApiThunk<Facility, { facilityId: number; vendorIds: Array<number> }>('/facility/setFacilityVendors', async (params, thunkApi) => {
    const response = await callUserAuthApi<Facility>('/facility/setFacilityVendors', HttpMethod.POST, {
        facilityId: params.facilityId,
        vendorIds: params.vendorIds,
    });

    return response.data;
});

export const setFacilityMultiFacilityEmployees = createApiThunk<Facility, { facilityId: number; employeeIds: Array<number> }>('/facility/setFacilityMultiFacilityEmployees', async (params, thunkApi) => {
    const response = await callUserAuthApi<Facility>('/facility/setFacilityMultiFacilityEmployees', HttpMethod.POST, {
        facilityId: params.facilityId,
        employeeIds: params.employeeIds,
    });

    return response.data;
});

export const setFacilityPartnerFacilities = createApiThunk<Facility, { facilityId: number; partnerFacilityIds: Array<number> }>('/facility/setFacilityPartnerFacilities', async (params, thunkApi) => {
    const response = await callUserAuthApi<Facility>('/facility/setFacilityPartnerFacilities', HttpMethod.POST, {
        facilityId: params.facilityId,
        partnerFacilityIds: params.partnerFacilityIds,
    });

    return response.data;
});

const initialState: FacilityState = {
    isLoading: false,
    facilities: [],
    userFacilities: [],
};

export const facilityReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getAllFacilities.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getAllFacilities.fulfilled, (state, action) => {
            state.isLoading = false;
            state.facilities = [...action.payload].sort((a, b) => a.facilityName.localeCompare(b.facilityName));
        })
        .addCase(getAllFacilities.rejected, (state, action) => {
            state.isLoading = false;
            state.facilities = [];
        })
        .addCase(getUserFacilities.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getUserFacilities.fulfilled, (state, action) => {
            state.userFacilities = [...action.payload].sort((a, b) => a.facilityName.localeCompare(b.facilityName));
            state.isLoading = false;
        })
        .addCase(getUserFacilities.rejected, (state, action) => {
            state.userFacilities = [];
            state.isLoading = false;
        })
        .addCase(clearUserFacilities, (state, action) => {
            state.userFacilities = [];
        });
});