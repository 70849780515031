import * as React from 'react';

import { Form, FormProps, Space } from 'antd';

import { AppButton } from '@/components/appButton';
import { ExternalVisitor } from 'drawbridge.shared/models/dataModels/externalVisitor';
import { Vendor } from 'drawbridge.shared/models/dataModels/vendor';
import { VendorSearch } from '@/components/vendorSearch';
import { nameof } from 'ts-simple-nameof';
import { useTranslation } from 'react-i18next';

interface VendorSelectFormValues {
    vendor: Vendor;
}

interface VendorSelectFormProps extends FormProps<VendorSelectFormValues> {
    showCancel?: boolean;
    onCancel?: () => void;
    cancelText?: string;
    submitText?: string;
    isLoading?: boolean;
    isReadOnly?: boolean;
    externalVisitor?: ExternalVisitor;
}

export const VendorSelectForm: React.FC<VendorSelectFormProps> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm<VendorSelectFormValues>();

    const {
        showCancel,
        onCancel,
        cancelText,
        submitText,
        isLoading,
        externalVisitor,
        isReadOnly,
        ...formProps
    } = props;

    return (
        <Form
            layout="vertical"
            labelAlign="right"
            {...formProps}
            form={form}
        >
            <Form.Item
                label={t('Components.Forms.VendorSelectForm.vendor_label')}
                name={nameof<VendorSelectFormValues>(x => x.vendor)}
                initialValue={externalVisitor?.vendor}
                rules={[{ required: true }]}
            >
                <VendorSearch
                    placeholder={t('Components.Forms.VendorSelectForm.vendor_placeholder')}
                    disabled={isLoading}
                />
            </Form.Item>

            <Form.Item
                style={{ textAlign: 'center' }}
            >
                <Space
                    direction="horizontal"
                >
                    {showCancel === true &&
                        <AppButton
                            loading={isLoading}
                            onClick={() => onCancel?.()}
                            size="middle"
                        >
                            {cancelText ?? t('Common.cancel')}
                        </AppButton>
                    }

                    {!isReadOnly &&
                        <AppButton
                            htmlType="submit"
                            size="middle"
                            disabled={isLoading}
                            loading={isLoading}
                        >
                            {submitText ?? t('Common.next')}
                        </AppButton>
                    }

                </Space>
            </Form.Item>
        </Form>
    );
};