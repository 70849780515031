import * as React from 'react';

import { AgreementPage, ExternalVisitorPage, FirstTimeVisitorPage, GlobalHealthAndSafetyPage, GuestWifiPage, LinamarEmployeePage, PerformingWorkPage, ReturningVisitorPage, ReturningVisitorVendorUpdatePage, SafetyPage, SignedInPage, VisitDetailsPage, VisitorTypePage } from '@/routes/pages/signInPages';
import { Button, Col, Modal, Popconfirm, Row, Space, notification } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, DeleteOutlined, FileDoneOutlined, FileProtectOutlined, GlobalOutlined, HeartOutlined, HistoryOutlined, HomeOutlined, IdcardOutlined, LockOutlined, LoginOutlined, LogoutOutlined, QrcodeOutlined, SafetyCertificateOutlined, SearchOutlined, SettingOutlined, ShopOutlined, TabletOutlined, TeamOutlined, ToolOutlined, UserOutlined, WarningOutlined, WifiOutlined } from '@ant-design/icons';
import { ConfigurationRequiredPage, HomePage, LoginPage, NotFoundPage, OfflinePage } from '@/routes/pages';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SignOutByQrPage, SignOutBySearchPage, SignOutPage } from '@/routes/pages/signOutPages';
import { dismissCompletedOfflineEmergencyAttendance, removeCompletedOfflineEmergencyAttendance } from '@/store/modules/offlineEmergencyAttendanceModule';
import { getCurrentVisits, getDeviceConfigById, getFacilityConfigByFacilityId, setDeviceConfig, setFacilityConfig } from '@/store/modules';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AppRoute } from '@/types/appRoute';
import { DeviceConfigErrorPage } from '@/routes/pages/deviceConfigErrorPage';
import { FacilityEvacuationMapPage } from '@/routes/pages/signInPages/facilityEvacuationMapPage';
import { FacilityHealthAndSafetyPage } from '@/routes/pages/signInPages/facilityHealthAndSafetyPage';
import { LinamarIcon } from '@/components/linamarIcon';
import { OfflineEmergencyAttendancePage } from '@/routes/pages/adminPages/emergencyAttendancePage/offlineEmergencyAttendancePage'; // Don't lazy load OfflineEmergencyAttendancePage, we need it available!
import { ProtectedRoute } from './protectedRoute';
import { SaveOfflineEmergencyAttendancePage } from '@/routes/pages/adminPages/emergencyAttendancePage/saveOfflineEmergencyAttendancePage';
import { UserRole } from 'drawbridge.shared/enums/userRole';
import { getPath } from '@/utility/path';
import { useApiErrorMessage } from '@/hooks/useApiErrorMessage';
import { useNetwork } from '@/hooks';
import { useTranslation } from 'react-i18next';

// Lazy load admin pages
const AdminPage = React.lazy(() => import('@/routes/pages/adminPages/adminPage').then((module) => ({ default: module.AdminPage })));
const AllVisitsPage = React.lazy(() => import('@/routes/pages/adminPages/allVisitsPage').then((module) => ({ default: module.AllVisitsPage })));
const CurrentVisitsPage = React.lazy(() => import('@/routes/pages/adminPages/currentVisitsPage').then((module) => ({ default: module.CurrentVisitsPage })));
const DeviceManagementPage = React.lazy(() => import('@/routes/pages/adminPages/deviceManagementPage').then((module) => ({ default: module.DeviceManagementPage })));
const DeviceSettingsPage = React.lazy(() => import('@/routes/pages/adminPages/deviceSettingsPage').then((module) => ({ default: module.DeviceSettingsPage })));
const FacilitySettingsPage = React.lazy(() => import('@/routes/pages/adminPages/facilitySettingsPage').then((module) => ({ default: module.FacilitySettingsPage })));
const GlobalSettingsPage = React.lazy(() => import('@/routes/pages/adminPages/globalSettingsPage').then((module) => ({ default: module.GlobalSettingsPage })));
const EmergencyAttendancePage = React.lazy(() => import('@/routes/pages/adminPages/emergencyAttendancePage').then((module) => ({ default: module.EmergencyAttendancePage })));
const FirstTimeDeviceSetupPage = React.lazy(() => import('@/routes/pages/adminPages/firstTimeSetupPage/firstTimeDeviceSetupPage').then((module) => ({ default: module.FirstTimeDeviceSetupPage })));
const FirstTimeFacilitySetupPage = React.lazy(() => import('@/routes/pages/adminPages/firstTimeSetupPage/firstTimeFacilitySetupPage').then((module) => ({ default: module.FirstTimeFacilitySetupPage })));
const FirstTimeSetupPage = React.lazy(() => import('@/routes/pages/adminPages/firstTimeSetupPage').then((module) => ({ default: module.FirstTimeSetupPage })));
const SettingsPage = React.lazy(() => import('@/routes/pages/adminPages/settingsPage').then((module) => ({ default: module.SettingsPage })));
const VendorManagementPage = React.lazy(() => import('@/routes/pages/adminPages/vendorManagementPage').then((module) => ({ default: module.VendorManagementPage })));
const VisitorManagementPage = React.lazy(() => import('@/routes/pages/adminPages/visitorManagementPage').then((module) => ({ default: module.VisitorManagementPage })));

// https://stackoverflow.com/a/73966420/3253311
const typeObjectValues = <T extends Record<string, AppRoute>>(object: T): { [originalPropType in keyof T]: AppRoute } => {
    type propType = keyof typeof object;
    return object as { [originalPropType in propType]: AppRoute };
};

// Add available routes here
// Can be secured with isProtected and allowedRoles props
export const appRoutes = typeObjectValues({
    home: {
        title: 'Home',
        titleKey: 'Pages.HomePage.title',
        path: getPath('/'),
        component: <HomePage />,
        icon: <HomeOutlined />,
        showInMenu: true,
        requiresConfig: true,
    },
    login: {
        title: 'Login',
        titleKey: 'Pages.LoginPage.title',
        path: getPath('login'),
        component: <LoginPage />,
        icon: <LoginOutlined />,
    },

    // Sign In
    visitorType: {
        title: 'Sign In',
        titleKey: 'Pages.SignInPage.title',
        path: getPath('/sign-in/visitor-type'),
        component: <VisitorTypePage />,
        icon: <UserOutlined />,
        requiresConfig: true,
    },
    externalVisitor: {
        title: 'External Visitor Sign-In',
        titleKey: 'Pages.ExternalVisitorPage.title',
        path: getPath('/sign-in/external-visitor'),
        component: <ExternalVisitorPage />,
        requiresConfig: true,
    },
    firstTimeVisitor: {
        title: 'First-Time Visitor Sign-In',
        titleKey: 'Pages.FirstTimeVisitorPage.title',
        path: getPath('/sign-in/first-time-visitor'),
        component: <FirstTimeVisitorPage />,
        icon: <UserOutlined />,
        requiresConfig: true,
    },
    returningVisitor: {
        title: 'Returning Visitor Sign-In',
        titleKey: 'Pages.ReturningVisitorPage.title',
        path: getPath('/sign-in/returning-visitor'),
        component: <ReturningVisitorPage />,
        icon: <HistoryOutlined />,
        requiresConfig: true,
    },
    returningVisitorVendorUpdate: {
        title: 'Returning Visitor Sign-In',
        titleKey: 'Pages.ReturningVisitorVendorUpdatePage.title',
        path: getPath('/sign-in/returning-visitor/vendor-update'),
        component: <ReturningVisitorVendorUpdatePage />,
        icon: <WarningOutlined />,
        requiresConfig: true,
    },
    linamarEmployee: {
        title: 'Linamar Employee Sign-In',
        titleKey: 'Pages.LinamarEmployeePage.title',
        path: getPath('/sign-in/linamar-employee'),
        component: <LinamarEmployeePage />,
        icon: <LinamarIcon className="primary-fill" />,
        requiresConfig: true,
    },
    performingWork: {
        title: 'Performing Work',
        titleKey: 'Pages.PerformingWorkPage.title',
        path: getPath('/sign-in/performing-work'),
        component: <PerformingWorkPage />,
        icon: <ToolOutlined />,
        requiresConfig: true,
    },
    safety: {
        title: 'Safety',
        titleKey: 'Pages.SafetyPage.title',
        path: getPath('/sign-in/safety'),
        component: <SafetyPage />,
        icon: <SafetyCertificateOutlined />,
        requiresConfig: true,
    },
    visitDetails: {
        title: 'Visit Details',
        titleKey: 'Pages.VisitDetailsPage.title',
        path: getPath('/sign-in/visit-details'),
        component: <VisitDetailsPage />,
        icon: <FileProtectOutlined />,
        requiresConfig: true,
    },
    guestWifiPage: {
        title: 'Guest Wifi',
        titleKey: 'Pages.GuestWifiPage.title',
        path: getPath('/sign-in/guest-wifi'),
        component: <GuestWifiPage />,
        icon: <WifiOutlined />,
        requiresConfig: true,
    },
    globalHealthAndSafety: {
        title: 'Health and Safety',
        titleKey: 'Pages.HealthAndSafetyPage.title',
        path: getPath('/sign-in/health-and-safety'),
        component: <GlobalHealthAndSafetyPage />,
        icon: <HeartOutlined />,
        requiresConfig: true,
    },
    facilityHealthAndSafety: {
        title: 'Facility Health and Safety',
        titleKey: 'Pages.FacilityHealthAndSafety.title',
        path: getPath('/sign-in-/facility-health-and-safety'),
        component: <FacilityHealthAndSafetyPage />,
        icon: <HeartOutlined />,
        requiresConfig: true,
    },
    facilityEvacuationMap: {
        title: 'Facility Evacuation Map',
        titleKey: 'Pages.FacilityEvacuationMap.title',
        path: getPath('/sign-in/facility-evacuation-map'),
        component: <FacilityEvacuationMapPage />,
        icon: <HeartOutlined />,
        requiresConfig: true,
    },
    agreement: {
        title: 'Agreement',
        titleKey: 'Pages.AgreementPage.title',
        path: getPath('/sign-in/agreement'),
        component: <AgreementPage />,
        icon: <CheckCircleOutlined />,
        requiresConfig: true,
    },
    signedIn: {
        title: 'Signed In',
        titleKey: 'Pages.SignedInPage.title',
        path: getPath('/sign-in/signed-in'),
        component: <SignedInPage />,
        icon: <IdcardOutlined />,
        requiresConfig: true,
    },
    // End Sign In

    // Sign Out
    signOut: {
        title: 'Sign Out',
        titleKey: 'Pages.SignOutPage.title',
        path: getPath('/sign-out'),
        component: <SignOutPage />,
        icon: <LogoutOutlined />,
        requiresConfig: true,
    },
    signOutByQr: {
        title: 'Sign Out by QR',
        titleKey: 'Pages.SignOutByQrPage.title',
        path: getPath('/sign-out/scan-qr'),
        component: <SignOutByQrPage />,
        icon: <QrcodeOutlined />,
        requiresConfig: true,
    },
    signOutBySearch: {
        title: 'Sign Out by Search',
        titleKey: 'Pages.SignOutBySearchPage.title',
        path: getPath('/sign-out/search'),
        component: <SignOutBySearchPage />,
        icon: <SearchOutlined />,
        requiresConfig: true,
    },
    // End Sign Out

    // Admin
    admin: {
        title: 'Admin',
        titleKey: 'Pages.AdminPage.title',
        path: getPath('/admin'),
        component: <AdminPage />,
        icon: <LockOutlined />,
        showInMenu: true,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER, UserRole.READ_ONLY],
    },
    firstTimeSetup: {
        title: 'First Time Setup',
        titleKey: 'Pages.FirstTimeSetupPage.title',
        path: getPath('/admin/first-time-setup'),
        component: <FirstTimeSetupPage />,
        icon: <SettingOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER],
    },
    firstTimeDeviceSetup: {
        title: 'First Time Device Setup',
        titleKey: 'Pages.FirstTimeDeviceSetupPage.title',
        path: getPath('/admin/first-time-setup/device'),
        component: <FirstTimeDeviceSetupPage />,
        icon: <TabletOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER],
    },
    firstTimeFacilitySetup: {
        title: 'First Time Facility Setup',
        titleKey: 'Pages.FirstTimeFacilitySetupPage.title',
        path: getPath('/admin/first-time-setup/facility'),
        component: <FirstTimeFacilitySetupPage />,
        icon: <TabletOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER],
    },
    allVisits: {
        title: 'All Visits',
        titleKey: 'Pages.AllVisitsPage.title',
        path: getPath('/admin/all-visits'),
        component: <AllVisitsPage />,
        icon: <TeamOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER, UserRole.READ_ONLY],
        requiresConfig: false,
    },
    currentVisits: {
        title: 'Current Visits',
        titleKey: 'Pages.CurrentVisitsPage.title',
        path: getPath('/admin/current-visits'),
        component: <CurrentVisitsPage />,
        icon: <ClockCircleOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER, UserRole.READ_ONLY],
        requiresConfig: false,
    },
    emergencyAttendance: {
        title: 'Emergency Attendance',
        titleKey: 'Pages.EmergencyAttendancePage.title',
        path: getPath('/admin/emergency-attendance'),
        component: <EmergencyAttendancePage />,
        icon: <FileDoneOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER, UserRole.READ_ONLY],
        requiresConfig: false,
    },
    offlineEmergencyAttendance: {
        title: 'Offline Emergency Attendance',
        titleKey: 'Pages.OfflineEmergencyAttendancePage.title',
        path: getPath('/offline-emergency-attendance'),
        component: <OfflineEmergencyAttendancePage />,
        icon: <FileDoneOutlined />,
        isProtected: false, // Can't protect this page because you can't log in offline!
        requiresConfig: true,
    },
    saveOfflineEmergencyAttendance: {
        title: 'Save Offline Emergency Attendance',
        titleKey: 'Pages.SaveOfflineEmergencyAttendancePage.title',
        path: getPath('/admin/save-offline-attendance'),
        component: <SaveOfflineEmergencyAttendancePage />,
        icon: <FileDoneOutlined />,
        isProtected: true,
        requiresConfig: true,
    },
    deviceSettings: {
        title: 'Device Settings',
        titleKey: 'Pages.DeviceSettingsPage.title',
        path: getPath('/admin/deviceSettings'),
        component: <DeviceSettingsPage />,
        icon: <TabletOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER, UserRole.READ_ONLY],
    },
    facilitySettings: {
        title: 'Facility Settings',
        titleKey: 'Pages.FacilitySettingsPage.title',
        path: getPath('/admin/facilitySettings'),
        component: <FacilitySettingsPage />,
        icon: <ShopOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER, UserRole.READ_ONLY],
    },
    globalSettings: {
        title: 'Global Settings',
        titleKey: 'Pages.GlobalSettingsPage.title',
        path: getPath('/admin/globalSettings'),
        component: <GlobalSettingsPage />,
        icon: <GlobalOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.SUPERUSER],
    },
    settings: {
        title: 'Settings',
        titleKey: 'Pages.SettingsPage.title',
        path: getPath('/admin/settings'),
        component: <SettingsPage />,
        icon: <SettingOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER, UserRole.READ_ONLY],
    },
    deviceManagement: {
        title: 'Device Management',
        titleKey: 'Pages.DeviceManagementPage.title',
        path: getPath('/admin/device-management'),
        component: <DeviceManagementPage />,
        icon: <TabletOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER, UserRole.READ_ONLY],
    },
    vendorManagement: {
        title: 'Vendor Management',
        titleKey: 'Pages.VendorManagementPage.title',
        path: getPath('/admin/vendor-management'),
        component: <VendorManagementPage />,
        icon: <ShopOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER, UserRole.READ_ONLY],
    },
    visitorManagement: {
        title: 'Visitor Management',
        titleKey: 'Pages.VisitorManagementPage.title',
        path: getPath('/admin/visitor-management'),
        component: <VisitorManagementPage />,
        icon: <IdcardOutlined />,
        isProtected: true,
        allowedRoles: [UserRole.ADMIN, UserRole.SUPERUSER, UserRole.READ_ONLY],
    },
    // End Admin
});

export type RouteType = typeof appRoutes;

export const AppRoutes: React.FC = (props) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useApiErrorMessage();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const location = useLocation();
    const networkState = useNetwork();

    const deviceConfig = useAppSelector((state) => state.deviceConfig.deviceConfig);
    const deviceConfigHasError = useAppSelector((state) => state.deviceConfig.hasError);
    const completedEmergencyAttendance = useAppSelector((state) => state.offlineEmergencyAttendance.completedEmergencyAttendance);

    const [isOfflineAttendanceModalOpen, setIsOfflineAttendanceModalOpen] = React.useState(false);

    React.useEffect(() => {
        // Get existing deviceConfig on load
        const validateDeviceConfigAsync = async (): Promise<void> => {
            if (!!deviceConfig) {
                const getDeviceConfigAction = await dispatch(getDeviceConfigById({ deviceConfigId: deviceConfig.id }));

                if (getDeviceConfigById.fulfilled.match(getDeviceConfigAction)) {
                    if (!getDeviceConfigAction.payload) {
                        dispatch(setDeviceConfig(undefined));
                    } else {
                        dispatch(setDeviceConfig(getDeviceConfigAction.payload));

                        const facilityId = getDeviceConfigAction.payload.facilityId;

                        const getFacilityConfigAction = await dispatch(getFacilityConfigByFacilityId({ facilityId: facilityId }));

                        if (getFacilityConfigByFacilityId.fulfilled.match(getFacilityConfigAction)) {
                            if (!getFacilityConfigAction.payload) {
                                dispatch(setFacilityConfig(undefined));
                            } else {
                                dispatch(setFacilityConfig(getFacilityConfigAction.payload));
                            }
                        }
                    }
                }
            }
        };

        validateDeviceConfigAsync();
    }, []);

    React.useEffect(() => {
        const loadCurrentVisitsAsync = async (facilityId: number): Promise<void> => {
            const action = await dispatch(getCurrentVisits({
                authMode: 'device',
                facilityId: facilityId,
            }));

            if (getCurrentVisits.rejected.match(action)) {
                notification.error({
                    message: t('Common.error'),
                    description: getErrorMessage(action.payload),
                });
            }
        };

        if (networkState.online === true && !!deviceConfig?.facilityId) {
            loadCurrentVisitsAsync(deviceConfig.facilityId);
        }


        if (networkState.online === true && !!completedEmergencyAttendance) {
            if (!completedEmergencyAttendance.isDismissed) {
                setIsOfflineAttendanceModalOpen(true);
            }
        }
    }, [networkState.online, deviceConfig?.facilityId]);

    const getRouteElement = (appRoute: AppRoute): React.ReactNode => {
        if (appRoute.requiresConfig && !deviceConfig) {
            // The route requires a config but we dont have one: show the configuration required page instead
            return <ConfigurationRequiredPage />;
        }

        if (appRoute.requiresConfig && deviceConfigHasError) {
            return <DeviceConfigErrorPage />;
        }

        if (!networkState.online && !location.pathname.toLowerCase().includes(appRoutes.offlineEmergencyAttendance.path.toLowerCase())) {
            // Show the offline page if we have no network connection, but allow the offline emergency attendance page as it's meant to be used offline!
            return <OfflinePage />;
        }

        if (appRoute.isProtected) {
            return (
                <ProtectedRoute
                    allowedRoles={appRoute.allowedRoles}
                >
                    {appRoute.component}
                </ProtectedRoute>
            );
        } else {
            return appRoute.component;
        }
    };

    return (
        <>
            <Routes>
                {Object.entries(appRoutes).map((entry) => {
                    const [_routeName, appRoute] = entry;

                    return (
                        <Route
                            key={appRoute.path}
                            path={appRoute.path}
                            element={getRouteElement(appRoute)}
                        />
                    );
                })}

                <Route
                    path="*"
                    element={<NotFoundPage />}
                />
            </Routes>

            <Modal
                title={t('Components.Routes.offline_attendance_modal_title')}
                visible={isOfflineAttendanceModalOpen}
                onCancel={() => setIsOfflineAttendanceModalOpen(false)}
                footer={(
                    <Space
                        direction="horizontal"
                    >
                        <Popconfirm
                            title={t('Components.Routes.offline_attendance_modal_confirmation')}
                            placement="bottom"

                            onConfirm={() => {
                                setIsOfflineAttendanceModalOpen(false);
                                dispatch(removeCompletedOfflineEmergencyAttendance());
                            }}
                        >
                            <Button
                                size="middle"
                                type="primary"
                                icon={<DeleteOutlined />}
                                danger={true}
                                style={{ width: '100px' }}
                            >
                                {t('Common.delete')}
                            </Button>
                        </Popconfirm>

                        <Button
                            size="middle"
                            type="default"
                            style={{ width: '100px' }}
                            onClick={() => {
                                setIsOfflineAttendanceModalOpen(false);
                                dispatch(dismissCompletedOfflineEmergencyAttendance());
                            }}
                        >
                            {t('Common.dismiss')}
                        </Button>

                        <Button
                            size="middle"
                            type="primary"
                            style={{ width: '100px' }}
                            onClick={() => {
                                dispatch(dismissCompletedOfflineEmergencyAttendance());
                                setIsOfflineAttendanceModalOpen(false);
                                navigate(appRoutes.saveOfflineEmergencyAttendance.path);
                            }}
                        >
                            {t('Common.ok')}
                        </Button>
                    </Space>
                )}
            >
                <Row
                    align="middle"
                    justify="start"
                    gutter={[15, 15]}
                >
                    <Col
                        span={24}
                    >
                        {t('Components.Routes.offline_attendance_modal_message')}
                    </Col>
                    <Col
                        span={24}
                    >
                        <ul>
                            <li>{t('Components.Routes.offline_attendance_modal_note1')}</li>
                            <li>{t('Components.Routes.offline_attendance_modal_note2')}</li>
                        </ul>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};