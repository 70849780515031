import { callDeviceAuthApi, callUserAuthApi } from '@/utility/api';

import { HttpMethod } from 'drawbridge.shared/constants/httpMethod';
import { MakeOptional } from '@/types/typescriptHelpers';
import { Vendor } from 'drawbridge.shared/models/dataModels/vendor';
import { VendorLiabilityDocument } from 'drawbridge.shared/models/dataModels/vendorLiabilityDocument';
import { VendorState } from '@/store/state/vendorState';
import { VendorValidationResult } from 'drawbridge.shared/models/vendorValidation';
import { VendorWorkersCompensationDocument } from 'drawbridge.shared/models/dataModels/vendorWorkersCompensationDocument';
import { createApiThunk } from '@/types/reduxHelpers';
import { createReducer } from '@reduxjs/toolkit';

export const getAllVendors = createApiThunk<Array<Vendor>, void>('/vendor/getAllVendors', async (args, thunkApi) => {
    const response = await callUserAuthApi<Array<Vendor>>('/vendor/getAllVendors');

    return response.data;
});

export const getActiveVendors = createApiThunk<Array<Vendor>, void>('/vendor/getActiveVendors', async (args, thunkApi) => {
    const response = await callUserAuthApi<Array<Vendor>>('/vendor/getActiveVendors');

    return response.data;
});

export const findVendorsByPartialVendorName = createApiThunk<Array<Vendor>, { vendorName: string }>('/vendor/findByPartialName', async (args, thunkApi) => {
    const response = await callDeviceAuthApi<Array<Vendor>>(`/vendor/search/byName/${args.vendorName}`);

    return response.data;
});

export const deleteVendor = createApiThunk<Vendor, { vendorId: number }>('/vendor/deleteVendor', async (args, thunkApi) => {
    const response = await callUserAuthApi<Vendor>('/vendor/delete', HttpMethod.POST, {
        vendorId: args.vendorId,
    });

    return response.data;
});

export const restoreVendor = createApiThunk<Vendor, { vendorId: number }>('/vendor/restoreVendor', async (args, thunkApi) => {
    const response = await callUserAuthApi<Vendor>('/vendor/restore', HttpMethod.POST, {
        vendorId: args.vendorId,
    });

    return response.data;
});

export const createVendor = createApiThunk<Vendor, MakeOptional<Vendor, 'id'>>('/vendor/create', async (vendor, thunkApi) => {
    const response = await callUserAuthApi<Vendor>('/vendor/create', HttpMethod.POST, {
        ...vendor,
    });

    return response.data;
});

export const updateVendor = createApiThunk<Vendor, Vendor>('/vendor/update', async (vendor, thunkApi) => {
    const response = await callUserAuthApi<Vendor>('/vendor/update', HttpMethod.POST, {
        ...vendor,
    });

    return response.data;
});

export const validateVendor = createApiThunk<VendorValidationResult, { vendorId: number }>('/vendor/validate', async (args, thunkApi) => {
    const response = await callDeviceAuthApi<VendorValidationResult>(`/vendor/validate/id/${args.vendorId}`);

    return response.data;
});

export const getVendorLiabilityDocument = createApiThunk<VendorLiabilityDocument | undefined, { vendorId: number }>('/vendor/getVendorLiabilityDocument', async (args, thunkApi) => {
    const response = await callUserAuthApi<VendorLiabilityDocument | undefined>(`/vendor/getVendorLiabilityDocument/${args.vendorId}`);

    return response.data;
});

export const uploadVendorLiabilityDocument = createApiThunk<VendorLiabilityDocument, { vendorId: number, filename: string, file: File }>('/vendor/uploadVendorLiabilityDocument', async (args, thunkApi) => {
    const formData = new FormData();

    formData.append('vendorLiabilityDocument', args.file, args.filename);
    formData.append('vendorId', args.vendorId.toString());

    const response = await callUserAuthApi<VendorLiabilityDocument>('/vendor/uploadVendorLiabilityDocument', HttpMethod.POST, formData);

    return response.data;
});

export const getVendorWorkersCompensationDocument = createApiThunk<VendorWorkersCompensationDocument | undefined, { vendorId: number }>('/vendor/getVendorWorkersCompensationDocument', async (args, thunkApi) => {
    const response = await callUserAuthApi<VendorWorkersCompensationDocument | undefined>(`/vendor/getVendorWorkersCompensationDocument/${args.vendorId}`);

    return response.data;
});

export const uploadVendorWorkersCompensationDocument = createApiThunk<VendorWorkersCompensationDocument, { vendorId: number, filename: string, file: File }>('/vendor/uploadVendorWorkersCompensationDocument', async (args, thunkApi) => {
    const formData = new FormData();

    formData.append('vendorWorkersCompensationDocument', args.file, args.filename);
    formData.append('vendorId', args.vendorId.toString());

    const response = await callUserAuthApi<VendorWorkersCompensationDocument>('/vendor/uploadVendorWorkersCompensationDocument', HttpMethod.POST, formData);

    return response.data;
});

const initialState: VendorState = {
    isLoading: false,
    vendors: [],
};

export const vendorReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getAllVendors.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getAllVendors.fulfilled, (state, action) => {
            state.isLoading = false;
            state.vendors = action.payload;
        })
        .addCase(getAllVendors.rejected, (state, action) => {
            state.isLoading = false;
            state.vendors = [];
        })
        .addCase(deleteVendor.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(deleteVendor.fulfilled, (state, action) => {
            state.isLoading = false;
            state.vendors = [...state.vendors.map(x => x.id === action.payload.id ? action.payload : x)];
        })
        .addCase(deleteVendor.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(restoreVendor.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(restoreVendor.fulfilled, (state, action) => {
            state.isLoading = false;
            state.vendors = [...state.vendors.map(x => x.id === action.payload.id ? action.payload : x)];
        })
        .addCase(restoreVendor.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(createVendor.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(createVendor.fulfilled, (state, action) => {
            state.isLoading = false;
            state.vendors = [
                ...state.vendors,
                action.payload,
            ];
        })
        .addCase(createVendor.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(updateVendor.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(updateVendor.fulfilled, (state, action) => {
            state.isLoading = false;
            state.vendors = [...state.vendors.map(x => x.id === action.payload.id ? action.payload : x)];
        })
        .addCase(updateVendor.rejected, (state, action) => {
            state.isLoading = false;
        });
});