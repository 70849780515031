import * as React from 'react';

import { Col, Row, Spin, notification } from 'antd';
import { Document, Page } from 'react-pdf';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AnimatedHeader } from '@/components/animatedHeader';
import { AppButton } from '@/components/appButton';
import { CheckCircleOutlined } from '@ant-design/icons';
import { PageWrapper } from '@/components/pageWrapper';
import { WelcomeHeader } from '@/components/pageHeader';
import { getFacilityEvacuationMapDocument } from '@/store/modules';
import { getPdfBlobFromRawData } from '@/utility/pdf';
import { useApiErrorMessage } from '@/hooks/useApiErrorMessage';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface FacilityEvacuationMapPageProps {

}

export const FacilityEvacuationMapPage: React.FC<FacilityEvacuationMapPageProps> = (props) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useApiErrorMessage();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const appRoutes = useAppRoutes();

    const deviceConfig = useAppSelector((state) => state.deviceConfig.deviceConfig);
    const visitor = useAppSelector((state) => state.signIn.visitor);
    const visitDetails = useAppSelector((state) => state.signIn.visitDetails);

    const [pdfBlob, setPdfBlob] = React.useState<Blob | undefined>(undefined);
    const [numberOfPages, setNumberOfPages] = React.useState(0);
    const [pages, setPages] = React.useState<Array<number>>([]);
    const [isPdfLoaded, setIsPdfLoaded] = React.useState(false);

    React.useEffect(() => {
        const loadFacilityEvacuationMapAsync = async (): Promise<void> => {
            if (!!deviceConfig?.facilityId) {
                const loadAction = await dispatch(getFacilityEvacuationMapDocument({
                    facilityId: deviceConfig.facilityId,
                }));

                if (getFacilityEvacuationMapDocument.rejected.match(loadAction)) {
                    notification.error({
                        message: t('Common.error'),
                        description: getErrorMessage(loadAction.payload),
                    });
                } else {
                    if (!loadAction.payload) {
                        // The request succeeded, but no evacuation map document was found for this facility
                        // Move onto the agreement page
                        navigate(appRoutes.agreement.path);

                        return;
                    }

                    setPdfBlob(getPdfBlobFromRawData(loadAction.payload.evacuationMapDocumentData));
                }
            }
        };

        if (!visitor || !visitDetails) {
            navigate(appRoutes.home.path, { replace: true });

            return;
        }

        loadFacilityEvacuationMapAsync();
    }, []);

    React.useEffect(() => {
        const newPages: Array<number> = [];

        for (let i = 0; i < numberOfPages; i++) {
            newPages.push(i + 1);
        }

        setPages(newPages);
    }, [numberOfPages]);

    return (
        <PageWrapper
            pageTitle={<WelcomeHeader />}
            containerClassName="agreement-page"
            isLoading={!isPdfLoaded}
            scrollBehaviorOnMount="none"
        >
            <Row
                align="middle"
                justify="center"
                gutter={20}
            >
                <Col
                    span={24}
                >
                    <AnimatedHeader>
                        {t('Pages.FacilityEvacuationMapPage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    style={{ textAlign: 'center' }}
                    span={24}
                >
                    <Document
                        file={pdfBlob}
                        onLoadSuccess={(doc) => {
                            setNumberOfPages(doc.numPages);
                            setIsPdfLoaded(true);
                        }}
                        onLoadError={(e) => console.log(e)}
                        className="pdf-document"
                    >
                        {pages.map((page) => {
                            return (
                                <Page
                                    key={page}
                                    className="pdf-page"
                                    pageNumber={page}
                                    renderTextLayer={false}
                                    loading={<Spin style={{ height: '100%' }} />}
                                    scale={2}
                                />
                            );
                        })}
                    </Document>
                </Col>

                <Col
                    span={24}
                    style={{ marginTop: 15 }}
                >
                    <Row
                        align="middle"
                        justify="center"
                        gutter={20}
                    >
                        <Col
                            xxl={10}
                            xl={10}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={24}
                        >
                            <AppButton
                                style={{ width: '100%', minHeight: '120px', margin: '10px 0' }}
                                icon={<CheckCircleOutlined />}
                                onClick={() => {
                                    navigate(appRoutes.agreement.path);
                                }}
                                disabled={!isPdfLoaded}
                            >
                                {t('Common.accept')}
                            </AppButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </PageWrapper>
    );
};