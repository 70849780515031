import './animatedButton.less';

import * as React from 'react';

import { Button, ButtonProps, Col, Row } from 'antd';

import { Zoom } from 'react-awesome-reveal';
import classnames from 'classnames';

interface AnimatedButtonProps extends ButtonProps {
    /**
     * Icon to show on button.
     */
    icon?: React.ReactNode;
    /**
     * Delay in milliseconds before animation is triggered
     */
    animationDelay?: number;
}

export const AnimatedButton: React.FC<AnimatedButtonProps> = (props) => {
    const defaultButtonProps: ButtonProps = {
        type: 'primary',
        shape: 'round',
    };

    const { animationDelay, icon, ...rest } = props;

    return (
        <Zoom
            duration={300}
            triggerOnce={true}
            cascade={true}
            delay={animationDelay}
        >
            <Button
                {...defaultButtonProps}
                {...rest}
                className={classnames('animated-button', props.className)}
                size="middle"
            >
                <Row
                    align="middle"
                    justify="center"
                >
                    <Col
                        span={24}
                        className="button-icon"
                    >
                        {icon}
                    </Col>

                    <Col
                        span={24}
                        className="button-text"
                    >
                        {props.children}
                    </Col>
                </Row>
            </Button>
        </Zoom>
    );
};