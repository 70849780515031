import './badge.less';

import * as React from 'react';

import { Col, ColProps, Row } from 'antd';

import { Guest } from 'drawbridge.shared/models/fortinet/guest';
import LinamarLogoColorWithTag from '@/assets/LinamarLogoColorWithTag.png';
import { QRCodeCanvas } from 'qrcode.react';
import { Visit } from 'drawbridge.shared/models/visit';
import { VisitQrData } from 'drawbridge.shared/models/visitQrData';
import { useAppSelector } from '@/types/reduxHelpers';
import { useTranslation } from 'react-i18next';

interface BadgeProps {
    visit: Visit;
    guest?: Guest;
    isPerformingWork?: boolean;
}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>((props, ref) => {
    const { t } = useTranslation();

    const currentLocale = useAppSelector((state) => state.locale.currentLocale);
    const deviceConfig = useAppSelector((state) => state.deviceConfig.deviceConfig);

    const { visit } = props;
    const { visitType } = visit;

    const visitQrData: VisitQrData = {
        visitId: visit.id ?? -1,
        visitType: visitType,
    };

    const infoLabelColProps: ColProps = {
        span: 11,
        style: {
            textAlign: 'right',
        },
    };

    const infoValueColProps: ColProps = {
        span: 13,
        style: {
            textAlign: 'left',
            fontWeight: 'bold',
        },
    };

    return (
        <Row
            ref={ref}
            align="middle"
            justify="center"
            className="badge"
        >
            <Col span={24}>
                <Row
                    align="middle"
                    justify="space-between"
                    className="badge-header"
                >
                    <Col
                        span={12}
                        style={{ textAlign: 'center' }}
                    >
                        <img style={{ width: '100%' }} src={LinamarLogoColorWithTag} />
                    </Col>
                    <Col
                        span={8}
                        style={{ textAlign: 'right' }}
                    >
                        <QRCodeCanvas
                            value={JSON.stringify(visitQrData)}
                        />
                    </Col>
                </Row>
            </Col>

            <Col span={24}>
                <Row
                    align="middle"
                    justify="space-around"
                    style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}
                    className="badge-visitor-type"
                >
                    <Col
                        span={12}
                        className="visitor-type"
                    >
                        {t(`Types.VisitType.${visitType}`)}
                    </Col>
                    {visit.visitor?.visitorType === 'external' && deviceConfig?.isCorporateMode === false &&
                        <Col
                            span={12}
                            style={{ textAlign: 'center' }}
                            className="safety-training"
                        >
                            <Row
                                align="middle"
                                justify="center"
                            >
                                <Col>
                                    <span className="safety-training-header">{t('Components.Badge.safety_training_header')}&nbsp;</span>
                                </Col>
                                <Col>
                                    <span className="safety-training-value">{props.isPerformingWork ? t('Common.yes') : t('Common.no')}</span>
                                </Col>
                            </Row>
                        </Col>
                    }

                </Row>
            </Col>

            <Col
                span={24}
            >
                <Row
                    align="middle"
                    justify="center"
                    className="badge-visitor-info"
                >
                    <Col span={24}>
                        <Row
                            align="middle"
                            justify="center"
                            gutter={[10, 5]}
                            style={{ padding: 10 }}
                        >
                            <Col
                                {...infoLabelColProps}
                            >
                                {t('Components.Badge.name_header')}:
                            </Col>

                            <Col
                                {...infoValueColProps}
                            >
                                {visit.visitor?.fullName}
                            </Col>

                            <Col
                                {...infoLabelColProps}
                            >
                                {visitType === 'employee' ? t('Components.Badge.facility_header') : t('Components.Badge.company_header')}:
                            </Col>

                            <Col
                                {...infoValueColProps}
                            >
                                {visit.visitor?.visitorType === 'employee' ? visit.visitor.facility?.facilityName ?? 'Linamar' : visit.visitor?.company}
                            </Col>

                            <Col
                                {...infoLabelColProps}
                            >
                                {t('Components.Badge.visiting_header')}:
                            </Col>

                            <Col
                                {...infoValueColProps}
                            >
                                {visit.facilityContact?.fullName}
                            </Col>

                            <Col
                                {...infoLabelColProps}
                            >
                                {t('Components.Badge.visit_reason_header')}:
                            </Col>

                            <Col
                                {...infoValueColProps}
                            >
                                {t(`Types.VisitReason.${visit.visitReason}`)}
                            </Col>

                            <Col
                                {...infoLabelColProps}
                            >
                                {t('Components.Badge.date_header')}:
                            </Col>

                            <Col
                                {...infoValueColProps}
                            >
                                {visit.signedInAt.toLocaleDateString(currentLocale, { month: 'short', day: 'numeric', year: 'numeric' })}
                            </Col>

                            <Col
                                {...infoLabelColProps}
                            >
                                {t('Components.Badge.time_header')}:
                            </Col>

                            <Col
                                {...infoValueColProps}
                            >
                                {visit.signedInAt.toLocaleTimeString(currentLocale)}
                            </Col>

                            {!!props.guest &&
                                <Col
                                    {...infoLabelColProps}
                                >
                                    {t('Components.Badge.guest_wifi_username_header')}:
                                </Col>
                            }

                            {!!props.guest &&
                                <Col
                                    {...infoValueColProps}
                                >
                                    {props.guest.userId}
                                </Col>
                            }

                            {!!props.guest &&
                                <Col
                                    {...infoLabelColProps}
                                >
                                    {t('Components.Badge.guest_wifi_password_header')}:
                                </Col>
                            }

                            {!!props.guest &&
                                <Col
                                    {...infoValueColProps}
                                >
                                    {props.guest.password}
                                </Col>
                            }

                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
});

Badge.displayName = 'Badge';

export { Badge };