import * as React from 'react';

import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

interface VendorPerformingWorkAlertProps {

}

export const VendorPerformingWorkAlert: React.FC<VendorPerformingWorkAlertProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Alert
            message={t('Components.VendorPerformingWorkAlert.vendor_alert_header')}
            description={t('Components.VendorPerformingWorkAlert.vendor_alert_verified_company_required_message')}
            showIcon={true}
            type="warning"
        />
    );
};