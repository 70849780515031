import './appButton.less';

import * as React from 'react';

import { Button, ButtonProps } from 'antd';

import classNames from 'classnames';

interface AppButtonProps extends ButtonProps { }

export const AppButton: React.FC<AppButtonProps> = (props) => {

    const defaultButtonProps: ButtonProps = {
        shape: 'round',
        type: 'default',
    };

    const { ...buttonProps } = props;

    return (
        <Button
            {...defaultButtonProps}
            {...buttonProps}
            className={classNames('app-button', buttonProps.className)}
        >
            {props.children}
        </Button>
    );
};