import './employeeSearch.less';

import * as React from 'react';

import { Empty, Select, SelectProps, Spin } from 'antd';
import { findEmployeesByFacilityAndPartialId, findEmployeesByFacilityAndPartialName, findEmployeesByFacilityAndPartialUsername, findEmployeesByFacilityAndPartialUsernameOrName } from '@/store/modules/employeeModule';

import { Employee } from 'drawbridge.shared/models/dataModels/employee';
import Highlighter from 'react-highlight-words';
import { LabeledValue } from 'antd/lib/select';
import { NumberOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { debounce } from 'throttle-debounce';
import { useAppDispatch } from '@reduxHelpers';
import { useTranslation } from 'react-i18next';

type AuthMode = 'user' | 'device';
type SearchType = 'employeeId' | 'name' | 'username';
type SearchMode = readonly [SearchType, SearchType?, SearchType?];

interface EmployeeSearchProps extends SelectProps {
    facilityId?: number;
    searchMode: SearchMode;
    showFacility?: boolean;
    includeMultiFacilityEmployees?: boolean;
    value?: Employee;
    onChange?: (value: Employee | undefined) => void;
    authMode?: AuthMode;
}

const MINIMUM_SEARCH_CHARACTERS = 3;
const EMPLOYEE_NUMBER_SEARCH_CHARACTERS = 7;

export const EmployeeSearch: React.FC<EmployeeSearchProps> = (props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [foundEmployees, setFoundEmployees] = React.useState<Array<Employee>>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');
    const [labeledValue, setLabeledValue] = React.useState<LabeledValue | undefined>(undefined);

    const {
        searchMode,
        facilityId,
        showFacility,
        includeMultiFacilityEmployees,
        authMode,
        ...selectProps
    } = props;

    const fetchRef = React.useRef(0);

    const triggerChange = (changedValue: Employee | undefined): void => {
        props.onChange?.(changedValue);
    };

    React.useEffect(() => {
        if (!!props.value?.id) {
            setLabeledValue({
                key: props.value.id.toString(),
                value: props.value.id,
                label: (
                    <span>{`${props.value.fullName}${showFacility ? ` - ${props.value.facility?.facilityName}` : ''}: ${props.value.departmentName}`}</span>
                ),
            });
        } else {
            setLabeledValue(undefined);
        }

    }, [props.value]);

    React.useEffect(() => {
        const searchAsync = async (): Promise<void> => {
            fetchRef.current += 1;

            const fetchId = fetchRef.current;

            if (!searchString) {
                setFoundEmployees([]);
                return;
            }
            if (isNaN(Number(searchString)) && searchString.length < MINIMUM_SEARCH_CHARACTERS) {
                setFoundEmployees([]);
                return;
            }
            if (!isNaN(Number(searchString)) && searchString.length !== EMPLOYEE_NUMBER_SEARCH_CHARACTERS) {
                setFoundEmployees([]);
                return;
            }

            setIsLoading(true);

            const auth = authMode ?? 'device';

            const searchPolicy = isNaN(Number(searchString)) // Is the search non-numeric?
                ? searchMode.includes('name') && searchMode.includes('username') // Are both name and username suppported?
                    ? findEmployeesByFacilityAndPartialUsernameOrName // Search by name or username
                    : searchMode.includes('name') // Is name supported?
                        ? findEmployeesByFacilityAndPartialName // Search by name.
                        : findEmployeesByFacilityAndPartialUsername // Search by username.
                : findEmployeesByFacilityAndPartialId; // Search was numeric: Search by ID

            const action = await dispatch(searchPolicy({ facilityId: facilityId, searchString: searchString, includeMultiFacilityEmployees: includeMultiFacilityEmployees ?? false, authMode: auth }));

            if (searchPolicy.fulfilled.match(action)) {
                // Make sure we're getting results from the last request, and not an old one that took its sweet time to complete
                if (fetchId === fetchRef.current) {
                    setFoundEmployees([...action.payload].sort((a, b) => a.fullName.localeCompare(b.fullName)));
                }
            }

            setIsLoading(false);
        };

        searchAsync();
    }, [searchString]);

    const debouncedSearch = React.useCallback(debounce(500, (searchString: string) => {
        setSearchString(searchString);
    }), []);

    const getNotFoundContent = (): React.ReactNode => {
        if (isLoading) {
            return <span><Spin size="small" /> {t('Common.searching')}</span>;
        }

        if (!!searchString) {
            if (isNaN(Number(searchString))) {
                // String
                if (searchString.length < MINIMUM_SEARCH_CHARACTERS) {
                    return <span><Spin size="small" /> {t('Components.EmployeeSearch.keep_typing_message')}</span>;
                } else {
                    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('Components.EmployeeSearch.no_employees_found')} />;
                }
            } else {
                // Employee Number
                if (searchString.length !== EMPLOYEE_NUMBER_SEARCH_CHARACTERS) {
                    return <span><NumberOutlined /> {t('Components.EmployeeSearch.enter_full_employee_number_message')}</span>;
                } else {
                    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('Components.EmployeeSearch.no_employees_found')} />;
                }
            }
        }

        return null;
    };

    return (
        <Select<LabeledValue>
            {...selectProps}
            className={classNames('employee-search', selectProps.className)}
            showSearch={true}
            filterOption={false}
            labelInValue={true}
            allowClear={true}
            value={labeledValue}
            showArrow={false}
            loading={isLoading}
            onBlur={() => {
                setSearchString('');
                setFoundEmployees([]);
            }}
            onChange={(newValue) => {
                setLabeledValue(newValue);

                const employee = foundEmployees.find(x => x.id === newValue.value);

                triggerChange(employee);

                // Clear out search when selected
                setSearchString('');
                setFoundEmployees([]);
            }}
            notFoundContent={getNotFoundContent()}
            onSearch={(value) => {
                // Debounce so we dont hammer the server with searches for every keypress
                debouncedSearch(value);
            }}
        >
            {foundEmployees.map((employee) => {
                return (
                    <Select.Option
                        key={employee.id}
                        value={employee.id}
                    >
                        <span>
                            <Highlighter
                                highlightClassName={classNames({ 'search-input-highlight': !!searchString })}
                                searchWords={!!searchString ? [searchString] : []}
                                textToHighlight={`${employee.fullName}${showFacility ? ` - ${employee.facility?.facilityName}` : ''}: ${employee.departmentName}`}
                            />
                        </span>
                    </Select.Option>
                );
            })}
        </Select>
    );
};