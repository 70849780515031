import * as React from 'react';

import { PageHeader, PageHeaderProps } from '@/components/pageHeader/pageHeader';

import { useTranslation } from 'react-i18next';

interface WelcomeHeaderProps extends PageHeaderProps {

}

export const WelcomeHeader: React.FC<WelcomeHeaderProps> = (props) => {
    const { t } = useTranslation();

    return (
        <PageHeader
            preContent={t('Components.WelcomeHeader.welcome_to')}
            mainContent="Linamar"
        />
    );
};