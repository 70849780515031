import './firstTimeVisitorPage.less';

import * as React from 'react';

import { Col, Divider, List, Modal, Row } from 'antd';
import { ExternalVisitorForm, ExternalVisitorFormValues } from '@/components/forms';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AnimatedHeader } from '@/components/animatedHeader';
import { AppButton } from '@/components/appButton';
import { ExternalVisitor } from 'drawbridge.shared/models/dataModels/externalVisitor';
import { IdcardOutlined } from '@ant-design/icons';
import { PageWrapper } from '@/components/pageWrapper';
import { VendorPerformingWorkAlert } from '@/components/vendorPerformingWorkAlert';
import { WelcomeHeader } from '@/components/pageHeader';
import { Zoom } from 'react-awesome-reveal';
import { findExternalVisitorsBySoundex } from '@/store/modules/externalVisitorModule';
import { setVisitor } from '@/store/modules/signInModule';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useVendorValidation } from '@/hooks/useVendorValidation';

interface FirstTimeVisitorPageProps {

}

export const FirstTimeVisitorPage: React.FC<FirstTimeVisitorPageProps> = (props) => {
    const appRoutes = useAppRoutes();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const vendorValidation = useVendorValidation();

    const [isLoading, setIsLoading] = React.useState(false);
    const [isExistingVisitorModalOpen, setIsExistingVisitorModalOpen] = React.useState(false);
    const [formVisitor, setFormVisitor] = React.useState<ExternalVisitorFormValues | undefined>(undefined);
    const [possibleVisitorMatches, setPossibleVisitorMatches] = React.useState<Array<ExternalVisitor>>([]);

    const visitor = useAppSelector((state) => state.signIn.visitor);
    const isPerformingWork = useAppSelector((state) => state.signIn.isPerformingWork);
    const visitReason = useAppSelector((state) => state.signIn.visitDetails?.reasonForVisit);

    const externalVisitor = !visitor
        ? undefined
        : visitor.visitorType === 'external'
            ? visitor
            : undefined;

    React.useEffect(() => {
        if (isPerformingWork === undefined || !visitReason) {
            // There's no way to legitimately get here without these values unless pages were skipped
            // Just go home and reset the sign in state...
            navigate(appRoutes.home.path, { replace: true });
        }
    }, []);

    if (isPerformingWork === undefined || !visitReason) {
        return <></>;
    }

    return (
        <PageWrapper
            pageTitle={<WelcomeHeader />}
            containerClassName="first-time-visitor-page"
            isNonIdeal={vendorValidation.vendorValidationFailed}
            nonIdealHeader={t('Types.VendorValidationMessage.validation_failed_header')}
            nonIdealActions={[
                { text: t('Common.return'), action: () => vendorValidation.resetValidationResult() },
                { text: t('Types.VendorValidationMessage.validation_failed_restart_button_text'), action: () => navigate(appRoutes.home.path) },
            ]}
            nonIdealSubheader={(
                <div>
                    <div style={{ textAlign: 'center' }}>
                        {t('Types.VendorValidationMessage.validation_failed_message')}
                    </div>

                    <Divider />

                    <ul style={{ listStyleType: 'none' }}>
                        {vendorValidation.localizedMessages.map((validationMessage, index) => {
                            return (
                                <li key={index}>
                                    {validationMessage}
                                </li>
                            );
                        })}
                    </ul>

                    <Divider />

                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        {t('Types.VendorValidationMessage.validation_failed_description')}
                    </div>
                </div>
            )}
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col
                    span={24}
                >
                    <AnimatedHeader>
                        {t('Pages.FirstTimeVisitorPage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    xxl={16}
                    xl={18}
                    lg={18}
                    md={24}
                    sm={24}
                    xs={24}
                >
                    {isPerformingWork &&
                        <VendorPerformingWorkAlert />
                    }
                    <Zoom
                        duration={200}
                        triggerOnce={true}
                    >
                        <ExternalVisitorForm
                            isLoading={isLoading}
                            externalVisitor={externalVisitor}
                            isVendorRequired={isPerformingWork}
                            onFinish={async (values) => {
                                setIsLoading(true);
                                setPossibleVisitorMatches([]);

                                // Keep our form values in state in case we need to use them later after a confirmation etc.
                                setFormVisitor({
                                    ...values,
                                });

                                // Find existing visitors with experimental soundex search
                                const action = await dispatch(findExternalVisitorsBySoundex({
                                    ...values,
                                }));

                                if (findExternalVisitorsBySoundex.fulfilled.match(action) && action.payload.length > 0) {
                                    // We found possible matches for an existing visitor
                                    setPossibleVisitorMatches(action.payload);
                                    setIsExistingVisitorModalOpen(true);
                                } else {
                                    const validationResult = await vendorValidation.getVendorValidationResult({
                                        isPerformingWork: isPerformingWork,
                                        vendorId: values.vendor?.id,
                                    });

                                    if (!validationResult?.isValid) {
                                        setIsLoading(false);

                                        return;
                                    }

                                    dispatch(setVisitor({
                                        ...values,
                                        visitorType: 'external',
                                        fullName: `${values.firstName.trim()} ${values.lastName.trim()}`,
                                        vendorId: values.vendor?.id,
                                    }));

                                    setIsLoading(false);

                                    if (isPerformingWork) {
                                        navigate(appRoutes.safety.path);
                                    } else {
                                        // navigate(appRoutes.globalHealthAndSafety.path);
                                        navigate(appRoutes.guestWifiPage.path);
                                    }
                                }
                            }}
                        />
                    </Zoom>

                </Col>
            </Row>

            <Modal
                visible={isExistingVisitorModalOpen}
                title={t('Pages.FirstTimeVisitorPage.possible_visitor_matches_title')}
                okText={t('Pages.FirstTimeVisitorPage.possible_visitor_matches_deny_button_text')}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={() => {
                    setIsLoading(false);
                    setIsExistingVisitorModalOpen(false);
                }}
                onOk={async () => {
                    setIsExistingVisitorModalOpen(false);

                    if (!!formVisitor) {
                        const { ...otherValues } = formVisitor;
                        // Didnt find the right match, continue with the form values they had entered
                        const validationResult = await vendorValidation.getVendorValidationResult({
                            isPerformingWork: isPerformingWork,
                            vendorId: formVisitor.vendor?.id,
                        });

                        if (!validationResult?.isValid) {
                            setIsLoading(false);

                            return;
                        }

                        dispatch(setVisitor({
                            visitorType: 'external',
                            vendorId: formVisitor.vendor?.id,
                            fullName: `${otherValues.firstName.trim()} ${otherValues.lastName.trim()}`,
                            ...formVisitor,
                        }));

                        setIsLoading(false);

                        if (isPerformingWork) {
                            navigate(appRoutes.safety.path);
                        } else {
                            // navigate(appRoutes.globalHealthAndSafety.path);
                            navigate(appRoutes.guestWifiPage.path);
                        }
                    }

                }}
            >
                <List
                    dataSource={possibleVisitorMatches}
                    itemLayout="horizontal"
                    renderItem={(externalVisitor) => (
                        <List.Item
                            actions={[
                                <AppButton
                                    key={externalVisitor.id}
                                    size="small"
                                    onClick={async () => {
                                        setIsExistingVisitorModalOpen(false);

                                        const validationResult = await vendorValidation.getVendorValidationResult({
                                            isPerformingWork: isPerformingWork,
                                            vendorId: externalVisitor.vendor?.id,
                                        });

                                        if (!validationResult?.isValid) {
                                            setIsLoading(false);

                                            return;
                                        }

                                        dispatch(setVisitor(externalVisitor));

                                        setIsLoading(false);

                                        if (isPerformingWork) {
                                            navigate(appRoutes.safety.path);
                                        } else {
                                            // navigate(appRoutes.globalHealthAndSafety.path);
                                            navigate(appRoutes.guestWifiPage.path);
                                        }
                                    }}
                                >
                                    {t('Pages.FirstTimeVisitorPage.possible_visitor_matches_approve_button_text')}
                                </AppButton>,
                            ]}
                        >
                            <List.Item.Meta
                                avatar={<IdcardOutlined />}
                                title={externalVisitor.fullName}
                                description={externalVisitor.company}
                            />
                        </List.Item>
                    )}
                />
            </Modal>
        </PageWrapper>
    );
};