import { AnyAction, Middleware } from 'redux';
import { connectSocket, connectionChanged, connectionError, disconnectSocket, subscribeToEmergencyAttendance, subscribeToFacility, unsubscribeFromEmergencyAttendance, unsubscribeFromFacility } from '@/store/modules/socketModule';
import { deviceConfigChanged, loginDevice, logoutDevice, refreshDeviceToken, setDeviceConfig } from '@/store/modules/deviceConfigModule';
import { emergencyAttendanceEnded, emergencyAttendanceStarted, emergencyAttendanceUpdated } from '@/store/modules/emergencyAttendanceModule';
import { login, logout, refreshToken, visitorSignedIn, visitorSignedOut } from '@/store/modules';

import { AppDispatch } from '@/types/reduxHelpers';
import { AppSocket } from '@/utility/socket';
import { RootState } from '@/store/createStore';
import { facilityConfigChanged } from '@/store/modules/facilityConfigModule';
import { i18n } from '@/i18n';
import { message } from 'antd';

export const socketMiddleware: Middleware<Record<string, unknown>, RootState, AppDispatch> = (store) => {
    const appSocket = new AppSocket({
        // Here's where we'll handle the dispatched actions for socket events coming through
        // As new events are added to the ServerToClientEvents type in the shared project, they'll be available to be handled here
        connectionChanged: (isConnected) => {
            store.dispatch(connectionChanged(isConnected));
        },
        connectionFailed: async (message) => {
            store.dispatch(connectionError(message));

            // Our token can expire independently from our socket connection
            // E.g. If a client has been idle long enough for the token to expire, and the connection to the server breaks, reconnect will fail because of the expired token
            // If we failed to connect because of a socket authentication error, try to refresh the respective token.
            // The result of the refresh will already handle a new connection via the action interceptors below
            // TODO: Better way to check for these cases?
            if (message?.toLowerCase() === 'socket device authentication error') {
                await store.dispatch(refreshDeviceToken(false));
            } else if (message?.toLowerCase() === 'socket user authentication error') {
                await store.dispatch(refreshToken(false));
            }
        },
        deviceConfigChanged: (newDeviceConfig) => {
            message.info(i18n.t('SocketNotifications.device_config_updated'));

            store.dispatch(deviceConfigChanged(newDeviceConfig));
        },
        facilityConfigChanged: (newFacilityConfig) => {
            message.info(i18n.t('SocketNotifications.facility_config_updated'));

            store.dispatch(facilityConfigChanged(newFacilityConfig));
        },
        emergencyAttendanceStarted: (emergencyAttendance) => {
            message.warn(i18n.t('SocketNotifications.emergency_attendance_started'));

            store.dispatch(emergencyAttendanceStarted(emergencyAttendance));
        },
        emergencyAttendanceEnded: (emergencyAttendance) => {
            message.info(i18n.t('SocketNotifications.emergency_attendance_ended'));

            store.dispatch(emergencyAttendanceEnded(emergencyAttendance));
        },
        emergencyAttendanceUpdated: (emergencyAttendance) => {
            store.dispatch(emergencyAttendanceUpdated(emergencyAttendance));
        },
        visitorSignedIn: (visit) => {
            store.dispatch(visitorSignedIn(visit));
        },
        visitorSignedOut: (visit) => {
            store.dispatch(visitorSignedOut(visit));
        },
    });

    return (next) => <A extends AnyAction>(action: A) => {
        if (login.fulfilled.match(action)) {
            // User logged in: Update socket connection
            appSocket.disconnect();
            store.dispatch(connectSocket({
                token: action.payload.accessToken,
                deviceToken: store.getState().deviceConfig.deviceAccessToken,
            }));
        }

        if (refreshToken.fulfilled.match(action)) {
            // User token was refreshed: Update socket connection
            appSocket.disconnect();
            store.dispatch(connectSocket({
                token: action.payload.accessToken,
                deviceToken: store.getState().deviceConfig.deviceAccessToken,
            }));
        }

        if (loginDevice.fulfilled.match(action)) {
            // Device logged in: Update socket connection
            appSocket.disconnect();
            store.dispatch(connectSocket({
                token: store.getState().security.accessToken,
                deviceToken: action.payload.deviceAccessToken,
            }));
        }

        if (refreshDeviceToken.fulfilled.match(action)) {
            // Device token was refreshed: Update socket connection
            appSocket.disconnect();
            store.dispatch(connectSocket({
                token: store.getState().security.accessToken,
                deviceToken: action.payload.deviceAccessToken,
            }));
        }

        if (logout.pending.match(action)) {
            // User is logging out: Update socket connection with device auth only
            appSocket.disconnect();
            store.dispatch(connectSocket({
                token: undefined,
                deviceToken: store.getState().deviceConfig.deviceAccessToken,
            }));
        }

        if (logoutDevice.pending.match(action)) {
            // Device is logging out: Update socket connection with user auth only
            appSocket.disconnect();
            store.dispatch(connectSocket({
                token: store.getState().security.accessToken,
                deviceToken: undefined,
            }));
        }

        if (connectSocket.match(action)) {
            appSocket.connect(action.payload.token, action.payload.deviceToken);
        }

        if (disconnectSocket.match(action)) {
            appSocket.disconnect();
        }

        if (deviceConfigChanged.match(action)) {
            if (!action.payload) {
                // TODO: Test this more
                // If our device config was deleted remotely, logout of the device
                store.dispatch(logoutDevice());
            }
        }

        if (setDeviceConfig.match(action)) {
            const deviceConfigState = store.getState().deviceConfig;

            if (deviceConfigState.deviceConfig?.id !== action.payload?.id || deviceConfigState.deviceConfig?.facilityId !== action.payload?.facilityId) {
                // We set a different device config or facility than we had before
                // Reconnect to join the appropriate socket rooms for updates
                appSocket.disconnect();
                appSocket.connect(store.getState().security.accessToken, store.getState().deviceConfig.deviceAccessToken);
            }
        }

        /**
         * Client socket actions
         */
        if (subscribeToEmergencyAttendance.match(action)) {
            appSocket.socket?.emit('subscribeToEmergencyAttendance', action.payload);
        }

        if (unsubscribeFromEmergencyAttendance.match(action)) {
            appSocket.socket?.emit('unsubscribeFromEmergencyAttendance', action.payload);
        }

        if (subscribeToFacility.match(action)) {
            appSocket.socket?.emit('subscribeToFacility', action.payload);
        }

        if (unsubscribeFromFacility.match(action)) {
            appSocket.socket?.emit('unsubscribeFromFacility', action.payload);
        }

        return next(action);
    };
};