import * as React from 'react';

import { Button } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface OfflineAttendanceButtonProps {

}

export const OfflineAttendanceButton: React.FC<OfflineAttendanceButtonProps> = (props) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const appRoutes = useAppRoutes();

    return (
        <Button
            style={{ width: '100%' }}
            icon={<FileDoneOutlined />}
            type="default"
            danger={true}
            onClick={() => navigate(appRoutes.offlineEmergencyAttendance.path)}
        >
            {t('Components.OfflineAttendanceButton.button_text')}
        </Button>
    );
};