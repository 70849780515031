import './pageHeader.less';

import * as React from 'react';

import { Col, Divider, Row } from 'antd';

import { LinamarLogo } from '../linamarLogo';
import classNames from 'classnames';

export interface PageHeaderProps {
    children?: never;
    className?: string;
    preContent?: React.ReactNode;
    mainContent?: React.ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
    return (
        <Row
            align="middle"
            justify="center"
            gutter={10}
            className={classNames('page-header', props.className)}
        >
            <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                <Row
                    align="middle"
                    justify="center"
                    gutter={15}
                >
                    <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={6}
                        sm={12}
                        xs={16}
                    >
                        <LinamarLogo
                            className="page-header-logo-icon"
                        />
                    </Col>

                    <Col
                        xxl={18}
                        xl={18}
                        lg={20}
                        md={18}
                        sm={24}
                        xs={24}

                    >
                        <Row
                            align="middle"
                            justify="end"
                        >
                            {!!props.preContent &&
                                <Col span={24}>
                                    <Divider style={{ padding: '0 20px' }}>
                                        <span className="page-header-pre-text">
                                            {props.preContent}
                                        </span>
                                    </Divider>
                                </Col>
                            }

                            {!!props.mainContent &&
                                <Col span={24}>
                                    <span className="page-header-main-text">
                                        {props.mainContent}
                                    </span>
                                </Col>
                            }

                            <Col span={24}>
                                <Divider style={{ padding: '0', marginBottom: 0 }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};