import './homePage.less';

import * as React from 'react';

import { AlertOutlined, LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AnimatedButton } from '@components/animatedButton';
import { AnimatedHeader } from '@components/animatedHeader';
import { AttentionSeeker } from 'react-awesome-reveal';
import { PageWrapper } from '@components/pageWrapper';
import { WelcomeHeader } from '@components/pageHeader';
import { logout } from '@/store/modules/securityModule';
import { resetSignInState } from '@/store/modules/signInModule';
import { useAppRoutes } from '@/hooks';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { useNonIdealState } from '@/hooks/useNonIdealState';
import { useTranslation } from 'react-i18next';

interface HomePageProps {

}

export const HomePage: React.FC<HomePageProps> = (props) => {
    const { t } = useTranslation();

    const nonIdealState = useNonIdealState();

    const adUser = useAppSelector((state) => state.security.adUser);
    const isRefreshingDeviceToken = useAppSelector((state) => state.deviceConfig.isRefreshingToken);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const appRoutes = useAppRoutes();

    React.useEffect(() => {
        // Reset sign in state whenever we mount the homepage
        dispatch(resetSignInState());
    }, []);

    React.useEffect(() => {
        if (!!adUser) {
            nonIdealState.setNonIdealState({
                icon: <AlertOutlined className="error-color" />,
                header: (
                    <>
                        <AttentionSeeker
                            effect="tada"
                            delay={400}
                            duration={1500}
                        >
                            <Row
                                align="middle"
                                justify="center"
                                gutter={20}
                            >
                                <Col>
                                    <span className="error-color" style={{ fontWeight: 'bold', WebkitTextStroke: '0.3px white', textTransform: 'uppercase' }}>{t('Common.warning')}:</span>
                                </Col>
                                <Col>
                                    <span style={{ fontWeight: 'lighter' }}>{t('Pages.HomePage.user_logged_in_header')}</span>
                                </Col>

                            </Row>
                        </AttentionSeeker>
                    </>
                ),
                subheader: t('Pages.HomePage.user_logged_in_subheader'),
            });
        } else {
            nonIdealState.resetNonIdealState();
        }
    }, [adUser]);

    useIdleTimer({
        timeout: 1000 * 60 * 5, // 5 minutes
        stopOnIdle: true,
        onIdle: async () => {
            if (!!adUser) {
                await dispatch(logout());
            }
        },
    });

    return (
        <PageWrapper
            containerClassName="home-page"
            pageTitle={<WelcomeHeader />}
            scrollBehaviorOnMount="top"
            isNonIdeal={nonIdealState.isNonIdeal}
            nonIdealActions={[
                {
                    action: () => {
                        nonIdealState.resetNonIdealState();
                    },
                    text: t('Common.close'),
                },
                'logout',
            ]}
            nonIdealHeader={nonIdealState.header}
            nonIdealSubheader={nonIdealState.subheader}
            nonIdealIconType={nonIdealState.icon}
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col span={24}>
                    <AnimatedHeader>
                        {t('Pages.HomePage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    style={{ marginTop: 20 }}
                    xxl={8}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                >

                    <AnimatedButton
                        icon={<LoginOutlined />}
                        animationDelay={0}
                        onClick={() => navigate(appRoutes.visitDetails.path)}
                        disabled={isRefreshingDeviceToken}
                    >
                        {t('Pages.HomePage.sign_in_button_text')}
                    </AnimatedButton>

                </Col>

                <Col
                    style={{ marginTop: 20 }}
                    xxl={8}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                >
                    <AnimatedButton
                        icon={<LogoutOutlined />}
                        animationDelay={50}
                        onClick={() => navigate(appRoutes.signOut.path)}
                        disabled={isRefreshingDeviceToken}
                    >
                        {t('Pages.HomePage.sign_out_button_text')}
                    </AnimatedButton>

                </Col>
            </Row>
        </PageWrapper>
    );
};