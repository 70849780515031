import * as React from 'react';

import { Alert, Col, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AnimatedHeader } from '@/components/animatedHeader';
import { AppButton } from '@/components/appButton';
import { PageWrapper } from '@/components/pageWrapper';
import { WelcomeHeader } from '@/components/pageHeader';
import { setIsPerformingWork } from '@/store/modules/signInModule';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface PerformingWorkPageProps {

}

export const PerformingWorkPage: React.FC<PerformingWorkPageProps> = (props) => {
    const { t } = useTranslation();

    const appRoutes = useAppRoutes();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // isPerformingWork may already be set as a result of the VisitReason
    const isPerformingWork = useAppSelector((state) => state.signIn.isPerformingWork);
    const visitDetails = useAppSelector((state) => state.signIn.visitDetails);

    React.useEffect(() => {
        if (!visitDetails) {
            navigate(appRoutes.home.path, { replace: true });
        }

        // Move on if already set
        if (isPerformingWork === true || isPerformingWork === false) {
            navigate(appRoutes.externalVisitor.path, { replace: true });
        }
    }, []);

    return (
        <PageWrapper
            pageTitle={<WelcomeHeader />}
        >
            <Row
                align="middle"
                justify="center"
                gutter={20}
            >
                <Col
                    span={24}
                >
                    <AnimatedHeader>
                        {t('Pages.PerformingWorkPage.header')}
                    </AnimatedHeader>
                </Col>

                <Col span={24}>
                    <Alert
                        type="warning"
                        message={
                            <Row
                                align="middle"
                                justify="center"
                            >
                                <Col style={{ fontWeight: 'bold' }}>
                                    {t('Pages.PerformingWorkPage.work_includes')}
                                </Col>
                            </Row>
                        }
                        style={{ width: '100%', margin: '20px 0' }}
                        description={
                            <Row
                                align="middle"
                                justify="center"
                            >
                                <ul>
                                    <li>{t('Pages.PerformingWorkPage.equipment_work')}</li>
                                    <li>{t('Pages.PerformingWorkPage.equipment_service')}</li>
                                    <li>{t('Pages.PerformingWorkPage.quality_sorting')}</li>
                                    <li>{t('Pages.PerformingWorkPage.training_services')}</li>
                                    <li>{t('Pages.PerformingWorkPage.unescorted_tour')}</li>
                                </ul>
                            </Row>
                        }
                    />

                </Col>

                <Col span={24}>
                    <Row
                        align="middle"
                        justify="space-between"
                        gutter={20}
                    >
                        <Col
                            xxl={10}
                            xl={10}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <AppButton
                                type="default"
                                style={{ width: '100%', minHeight: '120px' }}
                                onClick={() => {
                                    dispatch(setIsPerformingWork(false));
                                    navigate(appRoutes.externalVisitor.path);
                                }}
                            >
                                {t('Common.no')}
                            </AppButton>
                        </Col>

                        <Col
                            xxl={10}
                            xl={10}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <AppButton
                                type="default"
                                style={{ width: '100%', minHeight: '120px' }}
                                onClick={() => {
                                    dispatch(setIsPerformingWork(true));
                                    navigate(appRoutes.externalVisitor.path);
                                }}
                            >
                                {t('Common.yes')}
                            </AppButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </PageWrapper>
    );
};