import './appMenu.less';

import * as React from 'react';

import { Col, Menu, MenuProps, Row } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';

import { MenuOutlined } from '@ant-design/icons';
import { changeDrawerState } from '@modules/appDrawerModule';
import classNames from 'classnames';
import { groupBy } from '@utility/collections';
import { useAppDispatch } from '@reduxHelpers';
import { useAppRoutes } from '@/hooks';

interface AppMenuProps extends MenuProps {

}

export const AppMenu: React.FC<AppMenuProps> = (props) => {
    const appRoutes = useAppRoutes();
    const dispatch = useAppDispatch();
    const location = useLocation();

    // Get our routes that have showInMenu set to true
    const menuRoutes = Object.entries(appRoutes).map((entry) => {
        const [_routeName, appRoute] = entry;

        // Our routes are turned into a big union type so that we can access the keys
        // with autocomplete, but this breaks optional properties :(
        // Will need to assert this as an AppRoute to properly access optional properties
        return appRoute;
    }).filter(x => x.showInMenu === true);

    const groupedRoutes = groupBy(menuRoutes, x => x.groupName ?? '');

    const { ...menuProps } = props;

    return (
        <Menu
            {...menuProps}
            className="app-menu"
            selectable={false}
            defaultSelectedKeys={['']}
            selectedKeys={[location.pathname]}
            disabledOverflow={true}
            triggerSubMenuAction="click" // Default is hover which does not work well on mobile
        >
            {Object.entries(groupedRoutes).map((entry) => {
                const [key, itemArray] = entry;

                if (!key) {
                    // No key means its a direct route
                    return (
                        itemArray.map((item) => {
                            return (
                                <Menu.Item
                                    key={item.path}
                                    className={classNames('menu-item', { active: item.path === location.pathname })}
                                >
                                    <NavLink
                                        to={item.path}
                                        onClick={() => dispatch(changeDrawerState(false))}
                                        className="menu-item-link"
                                    >
                                        <Row
                                            align="middle"
                                            justify="start"
                                            gutter={10}
                                        >
                                            <Col
                                                className="menu-item-link-icon"
                                            >
                                                {item.icon}
                                            </Col>
                                            <Col>
                                                {item.title}
                                            </Col>
                                        </Row>
                                    </NavLink>
                                </Menu.Item>
                            );
                        })
                    );
                } else {
                    // We have a key, and therefore a group

                    // Our group will be active if any of its items are active
                    const isActive = itemArray.some(x => x.path === location.pathname);

                    const sorted = [...itemArray].sort((a, b) => (a.groupOrder ?? 99) - (b.groupOrder ?? 99));

                    return (
                        <Menu.SubMenu
                            title={key}
                            icon={<MenuOutlined />}
                            key={`${key}`}
                            className={classNames('sub-menu', { active: isActive })}
                        >
                            {sorted
                                .map((item) => {
                                    return (
                                        <Menu.Item
                                            key={item.path}
                                            className="menu-item"
                                        >
                                            <NavLink
                                                to={item.path}
                                                onClick={() => dispatch(changeDrawerState(false))}
                                                className="menu-item-link"
                                            >
                                                <Row
                                                    align="middle"
                                                    justify="start"
                                                    gutter={10}
                                                >
                                                    <Col
                                                        className="menu-item-link-icon"
                                                    >
                                                        {item.icon}
                                                    </Col>
                                                    <Col>
                                                        {item.title}
                                                    </Col>
                                                </Row>
                                            </NavLink>
                                        </Menu.Item>
                                    );
                                })}
                        </Menu.SubMenu>
                    );
                }
            })}
        </Menu>
    );
};