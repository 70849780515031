import './adminHeader.less';

import * as React from 'react';

import { PageHeader, PageHeaderProps } from '@/components/pageHeader/pageHeader';

import { useTranslation } from 'react-i18next';

interface AdminHeaderProps extends PageHeaderProps {

}

export const AdminHeader: React.FC<AdminHeaderProps> = (props) => {
    const { t } = useTranslation();

    return (
        <PageHeader
            preContent="Drawbridge"
            mainContent={t('Components.AdminHeader.administration')}
            className="admin-header"
        />
    );
};