import * as React from 'react';

import { Checkbox, Divider, Form, FormProps, Input, InputNumber, Radio, Space } from 'antd';

import { AppButton } from '@/components/appButton';
import { BadgeType } from 'drawbridge.shared/models/badgeType';
import { DeviceConfig } from 'drawbridge.shared/models/dataModels/deviceConfig';
import { UserFacilitySelect } from '@/components/userFacilitySelect';
import { WebcamMode } from 'drawbridge.shared/models/webcamMode';
import { nameof } from 'ts-simple-nameof';
import { useTranslation } from 'react-i18next';

interface DeviceConfigFormValues {
    id?: number;
    deviceName: string;
    facilityId: number;
    isCorporateMode: boolean;
    isPrintingEnabled: boolean;
    webcamMode: WebcamMode;
    isIdleTimeoutEnabled: boolean;
    idleTimeoutSeconds: number;
    badgeType: BadgeType;
}

interface DeviceConfigFormProps extends FormProps<DeviceConfigFormValues> {
    isLoading?: boolean;
    deviceConfig?: DeviceConfig;
    onCancel?: () => void;
    showCancel?: boolean;
    cancelText?: string;
    submitText?: string;
    isDisabled?: boolean;
}

export const DeviceConfigForm: React.FC<DeviceConfigFormProps> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm<DeviceConfigFormValues>();

    const [isIdleTimeoutAvailable, setIsIdleTimeoutAvailable] = React.useState(props.deviceConfig?.isIdleTimeoutEnabled ?? false);

    const {
        isLoading,
        isDisabled,
        deviceConfig,
        showCancel,
        onCancel,
        cancelText,
        submitText,
        ...formProps
    } = props;

    return (
        <Form
            layout="vertical"
            style={{ width: '100%' }}
            {...formProps}
            form={form}
        >
            <Form.Item
                name={nameof<DeviceConfigFormValues>(x => x.id)}
                initialValue={deviceConfig?.id}
                noStyle={true}
            >
                <Input type="hidden" />
            </Form.Item>

            <Form.Item
                label={t('Components.Forms.DeviceConfigForm.device_name_label')}
                name={nameof<DeviceConfigFormValues>(x => x.deviceName)}
                rules={[{ required: true }]}
                initialValue={deviceConfig?.deviceName}
            >
                <Input
                    placeholder={t('Components.Forms.DeviceConfigForm.device_name_placeholder')}
                    disabled={!!deviceConfig || isDisabled}
                />
            </Form.Item>

            <Form.Item
                label={t('Components.Forms.DeviceConfigForm.facility_label')}
                name={nameof<DeviceConfigFormValues>(x => x.facilityId)}
                rules={[{ required: true }]}
                initialValue={deviceConfig?.facilityId}
            >
                <UserFacilitySelect
                    placeholder={t('Components.Forms.DeviceConfigForm.facility_placeholder')}
                    disabled={!!deviceConfig || isDisabled}
                />
            </Form.Item>

            <Form.Item
                name={nameof<DeviceConfigFormValues>(x => x.isCorporateMode)}
                initialValue={deviceConfig?.isCorporateMode ?? false}
                valuePropName="checked"
            >
                <Checkbox
                    disabled={isDisabled}
                >
                    {t('Components.Forms.DeviceConfigForm.corporate_mode_checkbox_text')}
                </Checkbox>
            </Form.Item>

            <Form.Item
                name={nameof<DeviceConfigFormValues>(x => x.isPrintingEnabled)}
                initialValue={deviceConfig?.isPrintingEnabled ?? false}
                valuePropName="checked"
            >
                <Checkbox
                    disabled={isDisabled}
                >
                    {t('Components.Forms.DeviceConfigForm.is_printing_enabled_checkbox_text')}
                </Checkbox>
            </Form.Item>

            <Form.Item
                label={t('Components.Forms.DeviceConfigForm.badge_type_label')}
                name={nameof<DeviceConfigFormValues>(x => x.badgeType)}
                rules={[{ required: true }]}
                initialValue={deviceConfig?.badgeType ?? 'original'}
            >
                <Radio.Group
                    buttonStyle="solid"
                    disabled={isDisabled}
                >
                    <Space
                        direction="horizontal"
                    >
                        <Radio
                            value={'original'}
                        >
                            {t('Types.BadgeType.original')}
                        </Radio>

                        <Radio
                            value={'long'}
                        >
                            {t('Types.BadgeType.long')}
                        </Radio>
                    </Space>

                </Radio.Group>
            </Form.Item>

            <Divider />

            <Form.Item
                label={t('Components.Forms.DeviceConfigForm.webcam_mode_label')}
                name={nameof<DeviceConfigFormValues>(x => x.webcamMode)}
                rules={[{ required: true }]}
                initialValue={deviceConfig?.webcamMode ?? 'user'}
            >
                <Radio.Group
                    buttonStyle="solid"
                    disabled={isDisabled}
                >
                    <Space
                        direction="horizontal"
                    >
                        <Radio
                            value={'environment'}
                        >
                            {t('Types.WebcamMode.environment')}
                        </Radio>

                        <Radio
                            value={'user'}
                        >
                            {t('Types.WebcamMode.user')}
                        </Radio>
                    </Space>

                </Radio.Group>
            </Form.Item>

            <Divider />

            <Form.Item
                name={nameof<DeviceConfigFormValues>(x => x.isIdleTimeoutEnabled)}
                initialValue={deviceConfig?.isIdleTimeoutEnabled ?? false}
                valuePropName="checked"
            >
                <Checkbox
                    disabled={isDisabled}
                    onChange={(e) => setIsIdleTimeoutAvailable(e.target.checked)}
                >
                    {t('Components.Forms.DeviceConfigForm.is_idle_timeout_enabled_checkbox_text')}
                </Checkbox>
            </Form.Item>

            <Form.Item
                label={t('Components.Forms.DeviceConfigForm.idle_timeout_seconds_label')}
                name={nameof<DeviceConfigFormValues>(x => x.idleTimeoutSeconds)}
                initialValue={deviceConfig?.idleTimeoutSeconds ?? 300}
                rules={[
                    { required: true },
                    { type: 'number', min: 60, message: t('Components.Forms.DeviceConfigForm.min_idle_timeout_validation_message') },
                ]}
            >
                <InputNumber<number>
                    style={{ width: '100%' }}
                    disabled={!isIdleTimeoutAvailable}
                />
            </Form.Item>

            <Form.Item
                style={{ textAlign: 'center' }}
            >
                <Space
                    direction="horizontal"
                >
                    {(showCancel ?? true) &&
                        <AppButton
                            loading={isLoading}
                            onClick={() => onCancel?.()}
                            size="middle"
                        >
                            {cancelText ?? t('Common.cancel')}
                        </AppButton>
                    }

                    <AppButton
                        htmlType="submit"
                        loading={isLoading}
                        disabled={isDisabled}
                        size="middle"
                    >
                        {submitText ?? t('Common.submit')}
                    </AppButton>
                </Space>

            </Form.Item>
        </Form>
    );
};