import * as React from 'react';

import { Col, Form, FormProps, Row, Select } from 'antd';

import { AppButton } from '@/components/appButton';
import { Employee } from 'drawbridge.shared/models/dataModels/employee';
import { EmployeeSearch } from '@/components/employeeSearch';
import { VisitDetails } from 'drawbridge.shared/models/visitDetails';
import { VisitReason } from 'drawbridge.shared/models/visitReason';
import { nameof } from 'ts-simple-nameof';
import { useAppSelector } from '@/types/reduxHelpers';
import { useTranslation } from 'react-i18next';

interface VisitDetailsFormValues {
    personVisiting: Employee;
    reasonForVisit: VisitReason;
}


interface VisitDetailsFormProps extends FormProps<VisitDetailsFormValues> {
    isLoading?: boolean;
    visitDetails?: VisitDetails;
}

export const VisitDetailsForm: React.FC<VisitDetailsFormProps> = (props) => {
    const { t } = useTranslation();

    const [form] = Form.useForm<VisitDetailsFormValues>();

    const deviceConfig = useAppSelector((state) => state.deviceConfig.deviceConfig);

    const { isLoading, visitDetails, ...formProps } = props;

    return (
        <Form
            className="visit-details-form"
            layout="vertical"
            labelAlign="right"
            autoComplete="off"
            {...formProps}
            form={form}
        >
            <Form.Item
                label={t('Components.Forms.VisitDetailsForm.person_visiting_label')}
                className="visit-details-form-item"
                name={nameof<VisitDetailsFormValues>(x => x.personVisiting)}
                rules={[{ required: true }]}
                initialValue={visitDetails?.personVisiting}
            >
                <EmployeeSearch
                    searchMode={['name']}
                    placeholder={t('Components.Forms.VisitDetailsForm.person_visiting_placeholder')}
                    facilityId={deviceConfig?.facilityId ?? -1}
                    includeMultiFacilityEmployees={true}
                />
            </Form.Item>

            <Form.Item
                label={t('Components.Forms.VisitDetailsForm.reason_for_visit_label')}
                className="visit-details-form-item"
                name={nameof<VisitDetailsFormValues>(x => x.reasonForVisit)}
                rules={[{ required: true }]}
                initialValue={visitDetails?.reasonForVisit}
            >
                <Select
                    placeholder={t('Components.Forms.VisitDetailsForm.reason_for_visit_placeholder')}
                >
                    {/* <Select.Option
                        key="personal"
                        value="personal"
                    >
                        <Row>
                            <Col span={24}>
                                <span>{t('Types.VisitReason.personal')}</span>
                            </Col>
                            <Col>
                                <ul style={{ fontWeight: 'lighter' }}>
                                    <li>{t('Components.Forms.VisitDetailsForm.reason_for_visit_personal_option_detail')}</li>
                                </ul>
                            </Col>
                        </Row>
                    </Select.Option> */}

                    <Select.Option
                        key="general"
                        value="general"
                    >
                        <Row>
                            <Col span={24}>
                                <span>{t('Types.VisitReason.general')}</span>
                            </Col>
                            <Col>
                                <ul style={{ fontWeight: 'lighter' }}>
                                    <li>{t('Components.Forms.VisitDetailsForm.reason_for_visit_general_option_detail')}</li>
                                </ul>
                            </Col>
                        </Row>
                    </Select.Option>

                    {!deviceConfig?.isCorporateMode &&
                        <Select.Option
                            key="industrialRepairs"
                            value="industrialRepairs"
                        >
                            <Row>
                                <Col span={24}>
                                    <span>{t('Types.VisitReason.industrialRepairs')}</span>
                                </Col>
                                <Col>
                                    <ul style={{ fontWeight: 'lighter' }}>
                                        <li>
                                            {t('Components.Forms.VisitDetailsForm.reason_for_visit_industrial_repairs_option_detail')}
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Select.Option>
                    }

                    {!deviceConfig?.isCorporateMode &&
                        <Select.Option
                            key="construction"
                            value="construction"
                        >
                            <Row>
                                <Col span={24}>
                                    <span>{t('Types.VisitReason.construction')}</span>
                                </Col>
                                <Col>
                                    <ul style={{ fontWeight: 'lighter' }}>
                                        <li>{t('Components.Forms.VisitDetailsForm.reason_for_visit_construction_option_detail')}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Select.Option>
                    }
                </Select>
            </Form.Item>

            <Form.Item>
                <Row>
                    <Col
                        span={24}
                        style={{ textAlign: 'center' }}
                    >
                        <AppButton
                            htmlType="submit"
                            size="middle"
                            className="visit-details-form-button"
                            disabled={isLoading}
                            loading={isLoading}
                        >
                            {t('Common.next')}
                        </AppButton>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};