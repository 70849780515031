import './localStorageUsageHelper.less';

import * as React from 'react';

import { Collapse, Divider, Row, Tag } from 'antd';
import { LocalStorageUsageInfo, getLocalStorageUsageInfo } from '@/utility/localStorage';

interface LocalStorageUsageHelperProps {

}

export const LocalStorageUsageHelper: React.FC<LocalStorageUsageHelperProps> = (props) => {
    const [localStorageUsageInfo, setLocalStorageUsageInfo] = React.useState<LocalStorageUsageInfo | undefined>();

    React.useEffect(() => {
        setLocalStorageUsageInfo(getLocalStorageUsageInfo());
    }, []);

    return (
        <Collapse
            className="local-storage-usage-helper"
            style={{ opacity: 0.8 }}
        >
            <Collapse.Panel
                key="ls"
                header={`Local Storage Usage (${localStorageUsageInfo?.totalLabel})`}
            >
                {Object.entries(localStorageUsageInfo?.entryLabels ?? []).map(([key, value]) => {
                    return (
                        <Row
                            key={key}
                        >
                            <Tag
                                color="geekblue"
                                style={{ margin: 0 }}
                            >
                                {key}
                            </Tag>
                            <Tag
                                color="default"
                                style={{ margin: 0 }}
                            >
                                {value}
                            </Tag>
                        </Row>
                    );
                })}

                <Divider />

                <Row
                    align="middle"
                    justify="center"
                >
                    <Tag
                        color="geekblue"
                        style={{ margin: 0 }}
                    >
                        Total
                    </Tag>
                    <Tag
                        color="default"
                        style={{ margin: 0 }}
                    >
                        {localStorageUsageInfo?.totalLabel}
                    </Tag>
                </Row>
            </Collapse.Panel>

        </Collapse>
    );
};