import './attendanceCard.less';

import * as React from 'react';

import { Card, Col, ColProps, Divider, Row, Statistic } from 'antd';
import { CheckCircleTwoTone, ContactsOutlined } from '@ant-design/icons';
import { Fade, Roll } from 'react-awesome-reveal';
import { OfflineEmergencyAttendanceEmployeeVisit, OfflineEmergencyAttendanceExternalVisitorVisit } from 'drawbridge.shared/models/dataModels/emergencyAttendance';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AppButton } from '@/components/appButton';
import { LinamarIcon } from '@/components/linamarIcon';
import classNames from 'classnames';
import { updateOfflineEmergencyAttendanceVisitPresence } from '@/store/modules/offlineEmergencyAttendanceModule';
import { useTranslation } from 'react-i18next';

interface OfflineAttendanceCardProps {
    emergencyAttendanceVisit: OfflineEmergencyAttendanceEmployeeVisit | OfflineEmergencyAttendanceExternalVisitorVisit;
}

export const OfflineAttendanceCard: React.FC<OfflineAttendanceCardProps> = (props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const currentLocale = useAppSelector((state) => state.locale.currentLocale);

    const [isLoading, setIsLoading] = React.useState(false);

    const { emergencyAttendanceVisit } = props;

    if (!emergencyAttendanceVisit.visit) {
        return null;
    }

    const infoColProps: ColProps = {
        xxl: 8,
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
        style: { marginTop: '15px' },
    };

    const valueStyle: React.CSSProperties = {
        fontSize: '1em',
    };

    return (
        <Card
            key={`${emergencyAttendanceVisit.visit.visitType}.${emergencyAttendanceVisit.id}`}
            className={classNames('attendance-card', { isPresent: emergencyAttendanceVisit.isPresent })}
        >
            <Row
                align="middle"
                justify="start"
                gutter={20}
            >
                <Col
                    flex={0}
                    style={{ textAlign: 'center' }}
                >
                    {emergencyAttendanceVisit.visit.visitType === 'employee' ? <LinamarIcon className="primary-fill" style={{ width: 35, height: 35 }} /> : <ContactsOutlined className="primary" style={{ fontSize: 30 }} />}
                </Col>

                <Col
                    flex={2}
                >
                    <span className="attendance-card-name">{emergencyAttendanceVisit.visit.visitor?.fullName}</span>
                </Col>


                <Col
                    flex={0}
                    style={{ textAlign: 'center' }}
                >
                    <AppButton
                        style={{ width: '100%' }}
                        size="small"
                        type={emergencyAttendanceVisit.isPresent ? 'default' : 'primary'}
                        loading={isLoading}
                        onClick={async () => {
                            if (!emergencyAttendanceVisit.visit) {
                                return;
                            }

                            setIsLoading(true);

                            dispatch(updateOfflineEmergencyAttendanceVisitPresence({
                                emergencyAttendanceVisit: emergencyAttendanceVisit,
                                isPresent: !emergencyAttendanceVisit.isPresent,
                                visitType: emergencyAttendanceVisit.visit?.visitType,
                            }));

                            setIsLoading(false);
                        }}
                    >
                        {emergencyAttendanceVisit.isPresent
                            ? t('Components.AttendanceCard.unmark_as_present_button_text')
                            : t('Components.AttendanceCard.mark_as_present_button_text')
                        }
                    </AppButton>
                </Col>
            </Row>

            <Divider style={{ margin: 10 }} />

            <Row
                align="middle"
                justify="start"
                gutter={20}
            >
                <Col
                    {...infoColProps}
                >
                    <Statistic
                        title={t('Components.AttendanceCard.signed_in_at_label')}
                        value={emergencyAttendanceVisit.visit.signedInAt.toLocaleString(currentLocale, { dateStyle: 'medium', timeStyle: 'short' })}
                        valueStyle={valueStyle}
                    />

                </Col>

                <Col
                    {...infoColProps}
                >
                    <Statistic
                        title={emergencyAttendanceVisit.visit.visitType === 'employee' ? t('Components.AttendanceCard.facility_label') : t('Components.AttendanceCard.company_label')}
                        value={emergencyAttendanceVisit.visit.visitType === 'employee' ? (emergencyAttendanceVisit.visit.visitor?.facility?.facilityName ?? 'Linamar') : emergencyAttendanceVisit.visit.visitor?.company}
                        valueStyle={valueStyle}
                    />
                </Col>

                <Col
                    {...infoColProps}
                >
                    <Statistic
                        title={t('Components.AttendanceCard.contact_label')}
                        value={emergencyAttendanceVisit.visit.facilityContact?.fullName}
                        valueStyle={valueStyle}
                    />
                </Col>

                <Col
                    {...infoColProps}
                >
                    <Statistic
                        title={t('Components.AttendanceCard.visit_reason_label')}
                        value={t(`Types.VisitReason.${emergencyAttendanceVisit.visit.visitReason}`)}
                        valueStyle={valueStyle}
                    />
                </Col>
            </Row>

            {/** Overlay when present */}
            {emergencyAttendanceVisit.isPresent &&
                <Row
                    align="middle"
                    justify="center"
                    className="present-overlay"
                >
                    <Col
                        style={{ textAlign: 'center' }}
                    >
                        <Roll
                            duration={600}
                            triggerOnce={true}
                        >
                            <CheckCircleTwoTone style={{ fontSize: '90px' }} twoToneColor="#52c41a" />
                        </Roll>

                        <Fade
                            duration={600}
                            triggerOnce={true}
                        >
                            <span
                                className="primary"
                                style={{ fontSize: '15px', fontWeight: 'bold' }}
                            >
                                {t('Components.AttendanceCard.marked_as_present')}
                            </span>
                        </Fade>

                    </Col>
                </Row>
            }
        </Card>
    );
};