import * as React from 'react';

import { VendorValidationResult } from 'drawbridge.shared/models/vendorValidation';
import { notification } from 'antd';
import { useApiErrorMessage } from '@/hooks/useApiErrorMessage';
import { useAppDispatch } from '@/types/reduxHelpers';
import { useTranslation } from 'react-i18next';
import { validateVendor } from '@/store/modules/vendorModule';

interface UseVendorValidationReturn {
    vendorValidationFailed: boolean;
    localizedMessages: Array<string>;
    resetValidationResult: () => void;
    getVendorValidationResult: (params: { isPerformingWork: boolean, vendorId?: number }) => Promise<VendorValidationResult | undefined>;
}

export const useVendorValidation = (): UseVendorValidationReturn => {
    const { t, i18n } = useTranslation();
    const { getErrorMessage } = useApiErrorMessage();

    const dispatch = useAppDispatch();

    const [vendorValidationFailed, setVendorValidationFailed] = React.useState(false);
    const [localizedMessages, setLocalizedMessages] = React.useState<Array<string>>([]);

    return {
        vendorValidationFailed: vendorValidationFailed,

        localizedMessages: localizedMessages,

        resetValidationResult: () => {
            setVendorValidationFailed(false);
            setLocalizedMessages([]);
        },

        getVendorValidationResult: async ({ isPerformingWork, vendorId }) => {
            if (isPerformingWork) {
                if (!vendorId) {
                    notification.error({
                        message: t('Common.error'),
                        description: 'Unexpected error: No vendor ID',
                    });

                    return undefined;
                }

                // Visitor is doing work, validate vendor
                const validateAction = await dispatch(validateVendor({
                    vendorId: vendorId,
                }));

                if (validateVendor.rejected.match(validateAction)) {
                    notification.error({
                        message: t('Common.error'),
                        description: getErrorMessage(validateAction.payload),
                    });

                    return undefined;
                } else {
                    if (!validateAction.payload.isValid) {
                        setVendorValidationFailed(true);
                        setLocalizedMessages(validateAction.payload.vendorValidationMessages.map((vm) => {
                            if (i18n.exists(vm.key)) {
                                return t(vm.key);
                            } else {
                                return vm.defaultMessage;
                            }
                        }));
                    }
                    return validateAction.payload;
                }
            } else {
                // No work = no vendor validation
                return {
                    isValid: true,
                };
            }
        },
    };

};