import './loggedInAccount.less';

import * as React from 'react';

import { Button, Modal } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@reduxHelpers';

import { logout } from '@modules/securityModule';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface LoggedInAccountProps {

}

export const LoggedInAccount: React.FC<LoggedInAccountProps> = (props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const appRoutes = useAppRoutes();

    const adUser = useAppSelector((state) => state.security.adUser);
    const isAuthenticated = useAppSelector((state) => state.security.isAuthenticated);

    if (isAuthenticated) {
        return (
            <div
                className="logged-in-account"
                style={{ cursor: 'pointer', display: 'flex', textAlign: 'right', justifyContent: 'flex-end', alignItems: 'center' }}
            >
                <Button
                    icon={<LogoutOutlined />}
                    style={{ width: '100%' }}
                    type="primary"
                    danger={true}
                    onClick={() => {
                        Modal.confirm({
                            title: `${t('Components.LoggedInAccount.logout_text')}: ${adUser?.displayName}`,
                            content: t('Components.LoggedInAccount.logout_confirmation_message'),
                            okText: t('Components.LoggedInAccount.logout_confirmation_button_text'),
                            cancelText: t('Common.cancel'),
                            onOk: async () => {
                                navigate(appRoutes.home.path);
                                await dispatch(logout());
                            },
                        });
                    }}
                >
                    {t('Components.LoggedInAccount.logout_text')}: {adUser?.displayName}
                </Button>
            </div>
        );
    } else {
        return (
            <Button
                icon={<UserOutlined />}
                style={{ width: '100%' }}
                onClick={() => navigate(appRoutes.login.path)}
            >
                {t('Components.LoggedInAccount.login_text')}
            </Button>
        );
    }
};