import { config } from '@config';
import path from 'path';

export const getPath = (...paths: Array<string>): string => {
    return path.join(
        ...[
            config.basePath,
            ...paths,
        ]
    );
};

export const isRootPath = (pathname: string): boolean => {
    return path.normalize(pathname) === config.basePath;
};

export const getPathWithoutBase = (path: string): string => {
    return path.replace(config.basePath, '');
};