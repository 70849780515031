import './connectionIndicator.less';

import * as React from 'react';

import { ApiOutlined, BulbOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Avatar, Tooltip } from 'antd';

import classNames from 'classnames';
import { useAppSelector } from '@/types/reduxHelpers';

interface ConnectionIndicatorProps {

}

export const ConnectionIndicator: React.FC<ConnectionIndicatorProps> = (props) => {

    const isConnecting = useAppSelector(state => state.socket.isConnecting);
    const isConnected = useAppSelector(state => state.socket.isConnected);
    const hasError = useAppSelector(state => state.socket.hasError);
    const errorMessage = useAppSelector(state => state.socket.errorMessage);

    if (isConnecting) {
        return (
            <Tooltip
                title="Connecting..."
            >
                <Avatar
                    size="small"
                    className={classNames('connection-indicator', 'connecting')}
                    icon={
                        <LoadingOutlined
                            className="connection-indicator-icon"
                        />
                    }
                />
            </Tooltip>
        );
    }

    if (isConnected) {
        return (
            <Tooltip
                title="Connected"
            >
                <Avatar
                    size="small"
                    className={classNames('connection-indicator', 'online')}
                    icon={
                        <BulbOutlined
                            className="connection-indicator-icon"
                        />
                    }
                />
            </Tooltip>
        );
    } else {
        if (hasError) {
            return (
                <Tooltip
                    title={errorMessage}
                >
                    <Avatar
                        size="small"
                        className={classNames('connection-indicator', 'offline')}
                        icon={
                            <ExclamationCircleOutlined
                                className="connection-indicator-icon"
                            />
                        }
                    />
                </Tooltip>
            );
        } else {
            return (
                <Tooltip
                    title="Disconnected"
                >
                    <Avatar
                        size="small"
                        className={classNames('connection-indicator', 'offline')}
                        icon={
                            <ApiOutlined
                                className="connection-indicator-icon"
                            />
                        }
                    />
                </Tooltip>
            );
        }

    }
};