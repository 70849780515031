import * as React from 'react';

import { PageWrapper } from '@/components/pageWrapper';
import { SettingOutlined } from '@ant-design/icons';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ConfigurationRequiredPageProps {

}

export const ConfigurationRequiredPage: React.FC<ConfigurationRequiredPageProps> = (props) => {
    const { t } = useTranslation();
    const appRoutes = useAppRoutes();
    const navigate = useNavigate();

    return (
        <PageWrapper
            isNonIdeal={true}
            nonIdealHeader={t('Pages.ConfigurationRequiredPage.header')}
            nonIdealSubheader={t('Pages.ConfigurationRequiredPage.subheader')}
            nonIdealActions={[
                'back',
                {
                    text: t('Pages.ConfigurationRequiredPage.configure_now_button_text'),
                    action: () => navigate(appRoutes.firstTimeSetup.path),
                },
            ]}
            nonIdealIconType={<SettingOutlined />}
        />
    );
};