import * as React from 'react';

import { Select, SelectProps } from 'antd';

import { Facility } from 'drawbridge.shared/models/dataModels/facility';
import { useAppSelector } from '@/types/reduxHelpers';
import { useTranslation } from 'react-i18next';

interface UserFacilitySelectProps extends SelectProps<number | undefined> {
    autoSelectIfNoValue?: boolean;
}

export const UserFacilitySelect: React.FC<UserFacilitySelectProps> = (props) => {
    const { t } = useTranslation();

    const userFacilities = useAppSelector((state) => state.facility.userFacilities);
    const isLoadingFacilities = useAppSelector((state) => state.facility.isLoading);

    const { autoSelectIfNoValue, ...selectProps } = props;

    // React.useEffect(() => {
    //     if (!!selectProps.value) {
    //         const facility = userFacilities.find(x => x.facilityId === selectProps.value);

    //         if (!facility) {
    //             selectProps.onChange?.(undefined, {
    //                 label: undefined,
    //                 value: undefined,
    //             });
    //         }
    //     }
    // }, []);

    React.useEffect(() => {
        if (!!selectProps.defaultValue) {
            const defaultFacility = userFacilities.find(x => x.facilityId === selectProps.defaultValue);

            if (!!defaultFacility) {
                selectProps.onChange?.(selectProps.defaultValue, {
                    label: defaultFacility.facilityName,
                    value: selectProps.defaultValue,
                });
            } else if (autoSelectIfNoValue && userFacilities.length > 0) {
                const firstFacility = userFacilities[0] as Facility;

                selectProps.onChange?.(firstFacility.facilityId, {
                    label: firstFacility.facilityName,
                    value: firstFacility.facilityId,
                });
            } else {
                selectProps.onChange?.(undefined, {
                    label: undefined,
                    value: undefined,
                });
            }
        } else if (autoSelectIfNoValue && userFacilities.length > 0) {
            const firstFacility = userFacilities[0] as Facility;

            selectProps.onChange?.(firstFacility.facilityId, {
                label: firstFacility.facilityName,
                value: firstFacility.facilityId,
            });
        }

    }, [userFacilities]);

    return (
        <Select
            showSearch={true}
            filterOption={(inputValue, option) => (option?.children?.toString().toLowerCase() ?? '').includes(inputValue.toLowerCase())}
            placeholder={t('Components.UserFacilitySelect.default_placeholder')}
            loading={isLoadingFacilities}
            {...selectProps}
        >
            {userFacilities.map((facility) => {
                return (
                    <Select.Option
                        key={facility.facilityId}
                        value={facility.facilityId}
                    >
                        {facility.facilityName}
                    </Select.Option>
                );
            })}
        </Select>
    );
};