import { Employee } from './employee';
import { ExternalVisitor } from './externalVisitor';
import { Facility } from './facility';
import { Visit } from '../visit';

export interface ExternalVisitorVisit {
    id: number;
    externalVisitorId: number;
    facilityId: number;
    isPerformingWork: boolean;
    signedInAt: Date;
    signedOutAt?: Date | null;
    facilityContactEmployeeReferenceId: number;
    visitReason: string;
    dateCreated?: Date;
    createdBy?: string;
    dateModified?: Date | null;
    modifiedBy?: string | null;

    visitor?: ExternalVisitor;
    facilityContact?: Employee;
    facility?: Facility;

    /**
     * Discriminator to identify ExternalVisitorVisit types when we have a union with other visit types, e.g. EmployeeVisit
     */
    readonly visitType: 'external'
}

export const isExternalVisitorVisit = (visit?: Visit): visit is ExternalVisitorVisit => {
    if (!visit) {
        return false;
    }

    return visit.visitType === 'external';
};