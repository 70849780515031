import * as React from 'react';

import { Col, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AnimatedHeader } from '@/components/animatedHeader';
import { LinamarEmployeeForm } from '@/components/forms';
import { PageWrapper } from '@/components/pageWrapper';
import { WelcomeHeader } from '@/components/pageHeader';
import { Zoom } from 'react-awesome-reveal';
import { setVisitor } from '@/store/modules/signInModule';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface LinamarEmployeePageProps {

}

export const LinamarEmployeePage: React.FC<LinamarEmployeePageProps> = (props) => {
    const { t } = useTranslation();

    const appRoutes = useAppRoutes();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const visitDetails = useAppSelector((state) => state.signIn.visitDetails);
    const visitor = useAppSelector((state) => state.signIn.visitor);

    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        if (!visitDetails) {
            navigate(appRoutes.home.path, { replace: true });
        }
    }, []);

    const employee = !visitor
        ? undefined
        : visitor.visitorType === 'employee'
            ? visitor
            : undefined;

    return (
        <PageWrapper
            pageTitle={<WelcomeHeader />}
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col
                    span={24}
                >
                    <AnimatedHeader>
                        {t('Pages.LinamarEmployeePage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    xxl={16}
                    xl={18}
                    lg={18}
                    md={24}
                    sm={24}
                    xs={24}
                >
                    <Zoom
                        duration={300}
                        triggerOnce={true}
                    >
                        <LinamarEmployeeForm
                            isLoading={isLoading}
                            employee={employee}
                            onFinish={(values) => {
                                setIsLoading(true);

                                dispatch(setVisitor({
                                    ...values.employee,
                                }));

                                setIsLoading(false);

                                // Linamar employees don't go to the 'guest wifi' page (only external visitors)
                                navigate(appRoutes.globalHealthAndSafety.path);
                            }}
                        />
                    </Zoom>
                </Col>
            </Row>
        </PageWrapper>
    );
};