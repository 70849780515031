import './externalVisitorSearch.less';

import * as React from 'react';

import { Empty, Select, SelectProps, Spin } from 'antd';

import { ExternalVisitor } from 'drawbridge.shared/models/dataModels/externalVisitor';
import Highlighter from 'react-highlight-words';
import { LabeledValue } from 'antd/lib/select';
import classNames from 'classnames';
import { debounce } from 'throttle-debounce';
import { findExternalVisitorsByPartialName } from '@/store/modules/externalVisitorModule';
import { useAppDispatch } from '@/types/reduxHelpers';
import { useTranslation } from 'react-i18next';

interface ExternalVisitorSearchProps extends SelectProps {
    value?: ExternalVisitor;
    onChange?: (value: ExternalVisitor | undefined) => void;
}

export const ExternalVisitorSearch: React.FC<ExternalVisitorSearchProps> = (props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [foundExternalVisitors, setFoundExternalVisitors] = React.useState<Array<ExternalVisitor>>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');
    const [labeledValue, setLabeledValue] = React.useState<LabeledValue | undefined>(undefined);

    const { ...selectProps } = props;

    const fetchRef = React.useRef(0);

    const triggerChange = (changedValue: ExternalVisitor | undefined): void => {
        props.onChange?.(changedValue);
    };

    React.useEffect(() => {
        if (!!props.value?.id) {
            setLabeledValue({
                key: props.value.id.toString(),
                value: props.value.id,
                label: (
                    <span>{props.value.fullName} - {props.value.company}</span>
                ),
            });
        } else {
            setLabeledValue(undefined);
        }

    }, [props.value]);

    React.useEffect(() => {
        const searchAsync = async (): Promise<void> => {
            fetchRef.current += 1;

            const fetchId = fetchRef.current;

            if (!!searchString) {
                setIsLoading(true);

                const action = await dispatch(findExternalVisitorsByPartialName({ searchString: searchString }));

                if (findExternalVisitorsByPartialName.fulfilled.match(action)) {
                    // Make sure we're getting results from the last request, and not an old one that took its sweet time to complete
                    if (fetchId === fetchRef.current) {
                        setFoundExternalVisitors([...action.payload].sort((a, b) => a.fullName.localeCompare(b.fullName)));
                    }
                }

                setIsLoading(false);
            } else {
                setFoundExternalVisitors([]);
            }
        };

        searchAsync();
    }, [searchString]);

    const debouncedSearch = React.useCallback(debounce(500, (searchString: string) => {
        setSearchString(searchString);
    }), []);

    return (
        <Select<LabeledValue>
            {...selectProps}
            className={classNames('external-visitor-search', selectProps.className)}
            showSearch={true}
            filterOption={false}
            labelInValue={true}
            allowClear={true}
            value={labeledValue}
            loading={isLoading}
            onBlur={() => {
                setSearchString('');
                setFoundExternalVisitors([]);
            }}
            onChange={(newValue) => {
                setLabeledValue(newValue);

                const externalVisitor = foundExternalVisitors.find(x => x.id === newValue.value);

                triggerChange(externalVisitor);

                // Clear out search when selected
                setSearchString('');
                setFoundExternalVisitors([]);
            }}
            notFoundContent={
                isLoading
                    ? <span><Spin size="small" /> {t('Common.searching')}</span>
                    : !!searchString
                        ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('Components.ExternalVisitorSearch.no_external_visitors_found')} />
                        : null
            }
            onSearch={(value) => {
                // Debounce so we dont hammer the server with searches for every keypress
                debouncedSearch(value);
            }}
        >
            {foundExternalVisitors.map((externalVisitor) => {
                return (
                    <Select.Option
                        key={externalVisitor.id}
                        value={externalVisitor.id}
                    >
                        <span>
                            <Highlighter
                                highlightClassName={classNames({ 'search-input-highlight': !!searchString })}
                                searchWords={!!searchString ? [searchString] : []}
                                textToHighlight={`${externalVisitor.fullName} - ${externalVisitor.company}`}
                            />
                        </span>
                    </Select.Option>
                );
            })}
        </Select>
    );
};