import * as React from 'react';

import { ApiOutlined, WifiOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AnimatedButton } from '@/components/animatedButton';
import { AnimatedHeader } from '@/components/animatedHeader';
import { PageWrapper } from '@/components/pageWrapper';
import { WelcomeHeader } from '@/components/pageHeader';
import { setIsRequestingGuestWifi } from '@/store/modules';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface GuestWifiPageProps {

}

export const GuestWifiPage: React.FC<GuestWifiPageProps> = (props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const appRoutes = useAppRoutes();

    const visitor = useAppSelector((state) => state.signIn.visitor);
    const facilityConfig = useAppSelector((state) => state.facilityConfig.facilityConfig);

    React.useEffect(() => {
        if (!facilityConfig?.isGuestWifiEnabled || visitor?.visitorType === 'employee') {
            // Guest WiFi is not enabled for the facility, or we somehow got here with an employee signing in (only external visitors can get guest wifi)
            // just move on to the next page
            navigate(appRoutes.globalHealthAndSafety.path, { replace: true });
        }
    }, []);

    return (
        <PageWrapper
            containerClassName="guest-wifi-page"
            pageTitle={<WelcomeHeader />}
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col span={24}>
                    <AnimatedHeader>
                        {t('Pages.GuestWifiPage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    style={{ marginTop: 20 }}
                    xxl={8}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                >
                    <AnimatedButton
                        icon={<ApiOutlined />}
                        animationDelay={0}
                        onClick={() => {
                            dispatch(setIsRequestingGuestWifi(false));
                            navigate(appRoutes.globalHealthAndSafety.path);
                        }}
                    >
                        {t('Pages.GuestWifiPage.decline_guest_wifi_button_text')}
                    </AnimatedButton>
                </Col>

                <Col
                    style={{ marginTop: 20 }}
                    xxl={8}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                >
                    <AnimatedButton
                        icon={<WifiOutlined />}
                        animationDelay={100}
                        onClick={() => {
                            dispatch(setIsRequestingGuestWifi(true));
                            navigate(appRoutes.globalHealthAndSafety.path);
                        }}
                    >
                        {t('Pages.GuestWifiPage.accept_guest_wifi_button_text')}
                    </AnimatedButton>
                </Col>
            </Row>
        </PageWrapper>
    );
};