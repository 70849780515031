import * as React from 'react';

import { Alert, Checkbox, Col, Modal, Row, Space, notification } from 'antd';
import { DeleteOutlined, MailOutlined, SaveOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AdminHeader } from '@/components/pageHeader';
import { AnimatedHeader } from '@/components/animatedHeader';
import { AppButton } from '@/components/appButton';
import { EmergencyAttendanceCard } from '@/components/emergencyAttendanceCard';
import { PageWrapper } from '@/components/pageWrapper';
import { removeCompletedOfflineEmergencyAttendance } from '@/store/modules/offlineEmergencyAttendanceModule';
import { saveOfflineEmergencyAttendance } from '@/store/modules';
import { useApiErrorMessage } from '@/hooks/useApiErrorMessage';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface SaveOfflineEmergencyAttendancePageProps {

}

export const SaveOfflineEmergencyAttendancePage: React.FC<SaveOfflineEmergencyAttendancePageProps> = (props) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useApiErrorMessage();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const appRoutes = useAppRoutes();

    const completedEmergencyAttendance = useAppSelector((state) => state.offlineEmergencyAttendance.completedEmergencyAttendance);
    const [shouldEmail, setShouldEmail] = React.useState(true);

    if (!completedEmergencyAttendance) {
        navigate(appRoutes.home.path);

        return null;
    }

    return (
        <PageWrapper
            pageTitle={<AdminHeader />}
            loadingMessage={t('Common.loading')}
        >
            <Row
                align="middle"
                justify="center"
                gutter={[15, 15]}
            >
                <Col span={24}>
                    <AnimatedHeader>
                        {t('Pages.SaveOfflineEmergencyAttendancePage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    span={24}
                >
                    <Row
                        align="middle"
                        justify="center"
                        style={{ width: '100%', margin: 0 }}
                    >
                        <EmergencyAttendanceCard
                            emergencyAttendance={completedEmergencyAttendance.emergencyAttendance}
                            style={{ width: '100%' }}
                        />

                        <Col
                            span={24}
                            style={{ textAlign: 'center' }}
                        >
                            <Alert
                                type="info"
                                showIcon={true}
                                icon={<MailOutlined />}
                                message={<strong>{t('Pages.SaveOfflineEmergencyAttendancePage.email_info_header')}</strong>}
                                description={(
                                    <div>
                                        <p>
                                            {t('Pages.SaveOfflineEmergencyAttendancePage.email_info_message')}
                                        </p>

                                        <div>
                                            <Checkbox
                                                checked={shouldEmail}
                                                onChange={(e) => setShouldEmail(e.target.checked)}
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {t('Pages.SaveOfflineEmergencyAttendancePage.email_info_checkbox_text')}
                                            </Checkbox>
                                        </div>
                                    </div>
                                )}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col>
                    <Space
                        direction="horizontal"
                        size="large"
                    >
                        <AppButton
                            size="middle"
                            danger={true}
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                Modal.confirm({
                                    title: t('Pages.SaveOfflineEmergencyAttendancePage.delete_attendance_header'),
                                    content: t('Pages.SaveOfflineEmergencyAttendancePage.delete_attendance_message'),
                                    okText: t('Pages.SaveOfflineEmergencyAttendancePage.delete_attendance_ok_button_text'),
                                    onOk: () => {
                                        dispatch(removeCompletedOfflineEmergencyAttendance());
                                    },
                                });
                            }}
                        >
                            {t('Common.delete')}
                        </AppButton>

                        <AppButton
                            size="middle"
                            type="primary"
                            icon={<SaveOutlined />}
                            onClick={async () => {
                                const action = await dispatch(saveOfflineEmergencyAttendance({
                                    offlineEmergencyAttendance: completedEmergencyAttendance.emergencyAttendance,
                                    shouldEmail: shouldEmail,
                                }));

                                if (saveOfflineEmergencyAttendance.rejected.match(action)) {
                                    notification.error({
                                        message: t('Common.error'),
                                        description: getErrorMessage(action.payload),
                                    });
                                } else {
                                    notification.success({
                                        message: t('Common.success'),
                                        description: t('Pages.SaveOfflineEmergencyAttendancePage.attendance_saved_message'),
                                    });

                                    dispatch(removeCompletedOfflineEmergencyAttendance());
                                }
                            }}
                        >
                            {t('Common.save')}
                        </AppButton>
                    </Space>
                </Col>

            </Row>
        </PageWrapper>
    );
};