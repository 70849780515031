import './visitDetailsPage.less';

import * as React from 'react';

import { Col, Row } from 'antd';
import { setIsPerformingWork, setVisitDetails } from '@/store/modules/signInModule';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AnimatedHeader } from '@/components/animatedHeader';
import { PageWrapper } from '@/components/pageWrapper';
import { VisitDetailsForm } from '@/components/forms';
import { WelcomeHeader } from '@/components/pageHeader';
import { Zoom } from 'react-awesome-reveal';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface VisitDetailsPageProps {

}

export const VisitDetailsPage: React.FC<VisitDetailsPageProps> = (props) => {
    const { t } = useTranslation();

    const appRoutes = useAppRoutes();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = React.useState(false);

    const visitDetails = useAppSelector((state) => state.signIn.visitDetails);
    const deviceConfig = useAppSelector((state) => state.deviceConfig.deviceConfig);

    return (
        <PageWrapper
            pageTitle={<WelcomeHeader />}
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col
                    span={24}
                >
                    <AnimatedHeader>
                        {t('Pages.VisitDetailsPage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    xxl={16}
                    xl={18}
                    lg={18}
                    md={24}
                    sm={24}
                    xs={24}
                >
                    <Zoom
                        duration={300}
                        triggerOnce={true}
                    >
                        <VisitDetailsForm
                            visitDetails={visitDetails}
                            isLoading={isLoading}
                            onFinish={(values) => {
                                setIsLoading(true);

                                dispatch(setVisitDetails({
                                    personVisiting: values.personVisiting,
                                    reasonForVisit: values.reasonForVisit,
                                }));

                                let isPerformingWork: boolean | undefined = undefined;

                                if (values.reasonForVisit === 'construction' || values.reasonForVisit === 'industrialRepairs') {
                                    isPerformingWork = true;
                                } else if (values.reasonForVisit === 'personal') {
                                    isPerformingWork = false;
                                }

                                // If 'corporate mode' is enabled, no one is doing work
                                if (deviceConfig?.isCorporateMode === true) {
                                    isPerformingWork = false;
                                }

                                dispatch(setIsPerformingWork(isPerformingWork));

                                setIsLoading(false);

                                navigate(appRoutes.visitorType.path);
                            }}
                        />
                    </Zoom>
                </Col>
            </Row>
        </PageWrapper>
    );
};