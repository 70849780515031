import * as React from 'react';

import { PageHeader, PageHeaderProps } from '@/components/pageHeader/pageHeader';

import { useTranslation } from 'react-i18next';

interface FarewellHeaderProps extends PageHeaderProps {

}

export const FarewellHeader: React.FC<FarewellHeaderProps> = (props) => {
    const { t } = useTranslation();

    return (
        <PageHeader
            preContent={t('Components.FarewellHeader.now_leaving')}
            mainContent="Linamar"
        />
    );
};