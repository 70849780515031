import './animatedHeader.less';

import * as React from 'react';

import { Col, Row } from 'antd';

import { Fade } from 'react-awesome-reveal';
import classNames from 'classnames';

interface AnimatedHeaderProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
    justify?: 'center' | 'start' | 'end' | 'space-around' | 'space-between' | 'space-evenly' | undefined;
    className?: string;
}

export const AnimatedHeader: React.FC<AnimatedHeaderProps> = (props) => {

    return (
        <Fade
            triggerOnce={true}
        >
            <Row
                align="middle"
                justify={props.justify ?? 'center'}
                className={classNames('animated-header', props.className)}
            >
                <Col
                    className="animated-header-content"
                    style={props.style}
                >
                    {props.children}
                </Col>
            </Row>
        </Fade>

    );
};