import * as React from 'react';

import { AntdBreakpointHelper } from '@/components/antdBreakpointHelper';
import { AppDrawer } from '@/components/appDrawer';
import { AppRoutes } from '@/routes';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Footer } from '@/components/footer';
import { Header } from '@/components/header';
import { IdleTimeout } from '@/components/idleTimeout';
import { LocalStorageUsageHelper } from '@/components/localStorageUsageHelper';
import { NavButtons } from '@/components/navButtons';
import { PageWrapper } from '@/components/pageWrapper';
import { useAppSelector } from '@reduxHelpers';
import { useAuthenticate } from '@/hooks/useAuthenticate';
import { useFormValidateMessages } from '@/hooks/useFormValidateMessages';
import { useTranslation } from 'react-i18next';

interface CoreLayoutProps {

}

// Context for the scroll container (layout-routes)
// Useful for child components that may need to have control over page scroll (Affix, etc.)
export const ScrollContext = React.createContext<Partial<{ scrollContainerRef: React.RefObject<HTMLDivElement> }>>({});

export const CoreLayout: React.FC<CoreLayoutProps> = (props) => {
    useAuthenticate();

    const { t } = useTranslation();

    const validateMessages = useFormValidateMessages();

    const antdLocale = useAppSelector((state) => state.locale.antdLocale);

    const scrollRef = React.useRef<HTMLDivElement>(null);

    return (
        <ConfigProvider
            form={{ validateMessages: validateMessages }}
            locale={antdLocale}
            componentSize="large"
            autoInsertSpaceInButton={true}
        >
            <BrowserRouter>
                <div
                    className="core-layout__viewport main-content"
                >
                    <IdleTimeout />

                    <div className="layout-main">
                        <div className="layout-header">
                            <Header />
                        </div>

                        <ScrollContext.Provider value={{ scrollContainerRef: scrollRef }}>
                            <div className="layout-routes" ref={scrollRef}>
                                <div className="nav-buttons-container">
                                    <NavButtons />
                                </div>
                                <React.Suspense
                                    fallback={
                                        <PageWrapper
                                            isLoading={true}
                                            loadingMessage={t('Common.loading')}
                                        />
                                    }
                                >
                                    <AppRoutes />
                                </React.Suspense>
                            </div>
                        </ScrollContext.Provider>

                        <div className="layout-footer">
                            <Footer />
                        </div>
                    </div>

                    <AppDrawer />

                    {process.env.REACT_APP_ANTD_GRID_HELPER === 'on' &&
                        <div style={{ position: 'fixed', bottom: 15, left: 15 }}>
                            <AntdBreakpointHelper />
                        </div>
                    }

                    {process.env.REACT_APP_LOCAL_STORAGE_USAGE_INFO === 'on' &&
                        <div style={{ position: 'fixed', bottom: 15, right: 15 }}>
                            <LocalStorageUsageHelper />
                        </div>
                    }
                </div>
            </BrowserRouter>
        </ConfigProvider>

    );
};