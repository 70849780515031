import * as React from 'react';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Spin } from 'antd';
import { Document, Page } from 'react-pdf';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AnimatedHeader } from '@/components/animatedHeader';
import { AppButton } from '@/components/appButton';
import { PageWrapper } from '@/components/pageWrapper';
import { WelcomeHeader } from '@/components/pageHeader';
import { getGlobalConfig } from '@/store/modules/globalConfigModule';
import { getPdfBlobFromRawData } from '@/utility/pdf';
import { setHasAcceptedGlobalHealthAndSafety } from '@/store/modules/signInModule';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface GlobalHealthAndSafetyPageProps {

}

export const GlobalHealthAndSafetyPage: React.FC<GlobalHealthAndSafetyPageProps> = (props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const appRoutes = useAppRoutes();
    const navigate = useNavigate();

    const globalConfig = useAppSelector((state) => state.globalConfig.globalConfig);
    const visitor = useAppSelector((state) => state.signIn.visitor);
    const visitDetails = useAppSelector((state) => state.signIn.visitDetails);

    const [pdfBlob, setPdfBlob] = React.useState<Blob | undefined>(undefined);
    const [numberOfPages, setNumberOfPages] = React.useState(0);
    const [pages, setPages] = React.useState<Array<number>>([]);
    const [isPdfLoaded, setIsPdfLoaded] = React.useState(false);

    React.useEffect(() => {
        const loadGlobalConfigAsync = async (): Promise<void> => {
            await dispatch(getGlobalConfig());
        };

        if (!visitor || !visitDetails) {
            navigate(appRoutes.home.path, { replace: true });

            return;
        }

        if (!globalConfig) {
            loadGlobalConfigAsync();
        }
    }, []);

    React.useEffect(() => {
        setPdfBlob(getPdfBlobFromRawData(globalConfig?.healthAndSafetyPdfData));
    }, [globalConfig]);

    React.useEffect(() => {
        const newPages: Array<number> = [];

        for (let i = 0; i < numberOfPages; i++) {
            newPages.push(i + 1);
        }

        setPages(newPages);
    }, [numberOfPages]);

    return (
        <PageWrapper
            pageTitle={<WelcomeHeader />}
            containerClassName="agreement-page"
            isLoading={!isPdfLoaded}
        >
            <Row
                align="middle"
                justify="center"
                gutter={20}
            >
                <Col
                    span={24}
                >
                    <AnimatedHeader>
                        {t('Pages.GlobalHealthAndSafetyPage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    style={{ textAlign: 'center' }}
                    span={24}
                >
                    <Document
                        file={pdfBlob}
                        onLoadSuccess={(doc) => {
                            setNumberOfPages(doc.numPages);
                            setIsPdfLoaded(true);
                        }}
                        onLoadError={(e) => console.log(e)}
                        loading={<Spin />}
                        className="pdf-document"
                    >
                        {pages.map((page) => {
                            return (
                                <Page
                                    key={page}
                                    className="pdf-page"
                                    pageNumber={page}
                                    renderTextLayer={false}
                                    loading={<Spin style={{ height: '100%' }} />}
                                    scale={2}
                                />
                            );
                        })}
                    </Document>
                </Col>

                <Col
                    span={24}
                    style={{ marginTop: 15 }}
                >
                    <Row
                        align="middle"
                        justify="space-between"
                        gutter={20}
                    >
                        <Col
                            xxl={10}
                            xl={10}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={24}
                        >
                            <AppButton
                                style={{ width: '100%', minHeight: '120px', margin: '10px 0' }}
                                icon={<CloseCircleOutlined />}
                                onClick={() => {
                                    Modal.confirm({
                                        title: t('Common.confirmation'),
                                        content: (
                                            <div>
                                                <p>{t('Pages.GlobalHealthAndSafetyPage.decline_agreement_warning')}</p>
                                                <p>{t('Pages.GlobalHealthAndSafetyPage.decline_agreement_confirmation')}</p>
                                            </div>
                                        ),
                                        onOk: () => {
                                            navigate(appRoutes.home.path);
                                        },
                                        okText: t('Pages.GlobalHealthAndSafetyPage.decline_agreement_confirmation_button_text'),
                                        cancelText: t('Common.go_back'),
                                    });
                                }}
                                disabled={!isPdfLoaded}
                            >
                                {t('Common.decline')}
                            </AppButton>
                        </Col>

                        <Col
                            xxl={10}
                            xl={10}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={24}
                        >
                            <AppButton
                                style={{ width: '100%', minHeight: '120px', margin: '10px 0' }}
                                icon={<CheckCircleOutlined />}
                                onClick={() => {
                                    dispatch(setHasAcceptedGlobalHealthAndSafety(true));
                                    navigate(appRoutes.facilityHealthAndSafety.path);
                                }}
                                disabled={!isPdfLoaded}
                            >
                                {t('Common.accept')}
                            </AppButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </PageWrapper>
    );
};