import * as React from 'react';

import { AutoComplete, AutoCompleteProps, Spin } from 'antd';

import Highlighter from 'react-highlight-words';
import { Vendor } from 'drawbridge.shared/models/dataModels/vendor';
import classNames from 'classnames';
import { debounce } from 'throttle-debounce';
import { findVendorsByPartialVendorName } from '@/store/modules/vendorModule';
import { useAppDispatch } from '@/types/reduxHelpers';
import { useTranslation } from 'react-i18next';

interface VendorAutoCompleteProps extends AutoCompleteProps {
    value?: string | Vendor;
    onChange?: (value: string | Vendor | undefined) => void;
}

export const VendorAutoComplete: React.FC<VendorAutoCompleteProps> = (props) => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [foundVendors, setFoundVendors] = React.useState<Array<Vendor>>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');
    const [selectedVendor, setSelectedVendor] = React.useState<Vendor | undefined>(undefined);

    React.useEffect(() => {
        if (typeof props.value === 'string') {
            // just a string
        } else {
            // vendor
            setSelectedVendor(props.value);
        }
    }, [props.value]);

    const fetchRef = React.useRef(0);

    const triggerChange = (changedValue: string | Vendor | undefined): void => {
        props.onChange?.(changedValue);
    };

    React.useEffect(() => {
        const searchAsync = async (): Promise<void> => {
            fetchRef.current += 1;

            const fetchId = fetchRef.current;

            if (!!searchString) {
                setIsLoading(true);

                const action = await dispatch(findVendorsByPartialVendorName({ vendorName: searchString }));

                if (findVendorsByPartialVendorName.fulfilled.match(action)) {
                    // Make sure we're getting results from the last request, and not an old one that took its sweet time to complete
                    if (fetchId === fetchRef.current) {
                        setFoundVendors([...action.payload].sort((a, b) => a.vendorName.localeCompare(b.vendorName)));
                    }
                }

                setIsLoading(false);
            } else {
                setFoundVendors([]);
            }
        };

        searchAsync();
    }, [searchString]);

    const { ...autoCompleteProps } = props;

    const debouncedSearch = React.useCallback(debounce(500, async (searchString: string) => {
        setSearchString(searchString);
    }), []);

    return (
        <AutoComplete
            {...autoCompleteProps}
            className={classNames('vendor-autocomplete', autoCompleteProps.className)}
            showSearch={true}
            filterOption={false}
            allowClear={true}
            value={typeof props.value === 'string' ? props.value : selectedVendor?.vendorName}
            showArrow={false}
            onBlur={() => {
                setSearchString('');
                setFoundVendors([]);
            }}
            onChange={(newValue: string) => {
                const vendor = foundVendors.find(x => x.vendorName.toLowerCase() === newValue.toLowerCase());

                setSelectedVendor(vendor);

                if (!!vendor) {
                    triggerChange(vendor);
                } else {
                    triggerChange(newValue);
                }
            }}
            onSelect={(value: string) => {
                const vendor = foundVendors.find(x => x.vendorName === value);
                setSearchString(value);
                setSelectedVendor(vendor);

                if (!!vendor) {
                    triggerChange(vendor);
                } else {
                    triggerChange(value);
                }
            }}
            notFoundContent={
                isLoading
                    ? <span><Spin size="small" /> {t('Common.searching')}</span>
                    : !!searchString
                        ? 'No matches found'
                        : null
            }
            onSearch={(value) => {
                // Debounce so we dont hammer the server with searches for every keypress
                debouncedSearch(value);
            }}
        >
            {foundVendors.map((vendor) => {
                return (
                    <AutoComplete.Option
                        key={vendor.id}
                        value={vendor.vendorName}
                    >
                        <span>
                            <Highlighter
                                highlightClassName={classNames({ 'search-input-highlight': !!searchString })}
                                searchWords={!!searchString ? [searchString] : []}
                                textToHighlight={vendor.vendorName}
                            />
                        </span>
                    </AutoComplete.Option>
                );
            })}
        </AutoComplete>
    );
};