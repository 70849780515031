import { Employee } from './employee';
import { Facility } from './facility';
import { Visit } from '../visit';

export interface EmployeeVisit {
    id: number;
    employeeReferenceId: number;
    facilityId: number;
    signedInAt: Date;
    signedOutAt?: Date | null;
    facilityContactEmployeeReferenceId: number;
    visitReason: string;
    dateCreated?: Date;
    createdBy?: string;
    dateModified?: Date | null;
    modifiedBy?: string | null;

    visitor?: Employee;
    facilityContact?: Employee;
    facility?: Facility;

    /**
     * Discriminator to identify EmployeeVisit types when we have a union with other visit types, e.g. ExternalVisitorVisit
     */
    readonly visitType: 'employee';
}

export const isEmployeeVisit = (visit?: Visit): visit is EmployeeVisit => {
    if (!visit) {
        return false;
    }

    return visit.visitType === 'employee';
};