import './header.less';

import * as React from 'react';

import { Button, Col, Divider, Popover, Row } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, ToolOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@reduxHelpers';
import { useAppRoutes, useNetwork } from '@/hooks';

import { AppMenu } from '@components/appMenu';
import { ConnectionIndicator } from '@/components/connectionIndicator';
import { FullscreenButton } from '@/components/fullscreenButton';
import { LanguagePicker } from '@/components/languagePicker';
import { LoggedInAccount } from '@components/loggedInAccount';
import { NavLink } from 'react-router-dom';
import { OfflineAttendanceButton } from '@/components/offlineAttendanceButton';
import { ReloadButton } from '@/components/reloadButton';
import { SaveOfflineAttendanceButton } from '@/components/saveOfflineAttendanceButton';
import applicationInfo from '@/utility/applicationInfo';
import { changeDrawerState } from '@modules/appDrawerModule';
import { useFullscreen } from '@/hooks/useFullscreen';

export const Header: React.FC = (props) => {
    const { version, build } = applicationInfo;

    const dispatch = useAppDispatch();

    const { isFullscreenEnabled } = useFullscreen();
    const appRoutes = useAppRoutes();
    const networkState = useNetwork();

    const isAuthenticated = useAppSelector((state) => state.security.isAuthenticated);
    const isDrawerOpen = useAppSelector((state) => state.drawer.isDrawerOpen);
    const completedEmergencyAttendance = useAppSelector((state) => state.offlineEmergencyAttendance.completedEmergencyAttendance);

    const [areToolsOpen, setAreToolsOpen] = React.useState(false);

    return (
        <Row
            className="header"
            justify="space-between"
            align="middle"
        >
            <Col>
                <Row
                    align="middle"
                    justify="start"
                >
                    <Col
                        className="header-menu-button"
                    >
                        {isAuthenticated === true &&
                            <div>
                                {isDrawerOpen === true
                                    ?
                                    <MenuFoldOutlined
                                        className="header-icon"
                                        style={{ fontSize: 20, marginLeft: 16, marginRight: 16, cursor: 'pointer' }}
                                        onClick={() => dispatch(changeDrawerState(false))}
                                    />
                                    :
                                    <MenuUnfoldOutlined
                                        className="header-icon"
                                        style={{ fontSize: 20, marginLeft: 16, marginRight: 16, cursor: 'pointer' }}
                                        onClick={() => dispatch(changeDrawerState(true))}
                                    />

                                }
                            </div>
                        }
                    </Col>

                    <Col>
                        <NavLink
                            to={appRoutes.home.path}
                        >
                            <Row>
                                <div className="header-title" style={{ flex: 0, alignSelf: 'middle', marginLeft: 10, marginRight: 10 }}><span className="bold">Draw</span><span className="lighter">bridge</span></div>
                            </Row>
                        </NavLink>
                    </Col>

                    <Col>
                        <ConnectionIndicator />
                    </Col>
                </Row>
            </Col>

            {isAuthenticated &&
                <Col
                    className="app-menu-container"
                >
                    <Row>
                        <AppMenu
                            mode="horizontal"
                        />
                    </Row>
                </Col>
            }

            <Col>
                <Popover
                    destroyTooltipOnHide={true}
                    trigger="click"
                    placement="bottomRight"
                    onVisibleChange={(visible) => setAreToolsOpen(visible)}
                    visible={areToolsOpen}
                    content={
                        <Row
                            style={{ maxWidth: '400px' }}
                        >
                            <Col
                                span={24}
                                style={{ margin: '10px 0' }}
                            >
                                <LanguagePicker />
                            </Col>

                            <Col
                                span={24}
                                style={{ margin: '10px 0' }}
                            >
                                <ReloadButton />
                            </Col>

                            {isFullscreenEnabled &&
                                <Col
                                    span={24}
                                    style={{ margin: '10px 0' }}
                                    onClick={() => setAreToolsOpen(false)}
                                >
                                    <FullscreenButton />
                                </Col>
                            }

                            <Col
                                span={24}
                                style={{ margin: '10px 0' }}
                                onClick={() => setAreToolsOpen(false)}
                            >
                                <LoggedInAccount />
                            </Col>

                            {(!networkState.online || !!completedEmergencyAttendance) &&
                                <>
                                    <Divider />

                                    {!networkState.online &&
                                        <Col
                                            span={24}
                                            style={{ margin: '10px 0' }}
                                            onClick={() => setAreToolsOpen(false)}
                                        >
                                            <OfflineAttendanceButton />
                                        </Col>
                                    }
                                    {!!completedEmergencyAttendance && networkState.online &&
                                        <Col
                                            span={24}
                                            style={{ margin: '10px 0' }}
                                            onClick={() => setAreToolsOpen(false)}
                                        >
                                            <SaveOfflineAttendanceButton />
                                        </Col>
                                    }
                                </>
                            }
                        </Row>
                    }
                    title={`Drawbridge v${version}${build ? ` - Build: ${build}` : ''}`}
                >
                    <Button
                        icon={<ToolOutlined />}
                        style={{ marginRight: 16 }}
                        type="dashed"
                        onClick={() => setAreToolsOpen(!areToolsOpen)}
                        shape="circle"
                    />
                </Popover>
            </Col>
        </Row>
    );
};