import { Visit, VisitType } from 'drawbridge.shared/models/visit';
import { callDeviceAuthApi, callUserAuthApi } from '@/utility/api';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';
import { Employee } from 'drawbridge.shared/models/dataModels/employee';
import { EmployeeVisit } from 'drawbridge.shared/models/dataModels/employeeVisit';
import { ExternalVisitor } from 'drawbridge.shared/models/dataModels/externalVisitor';
import { ExternalVisitorVisit } from 'drawbridge.shared/models/dataModels/externalVisitorVisit';
import { Guest } from 'drawbridge.shared/models/fortinet/guest';
import { HttpMethod } from 'drawbridge.shared/constants/httpMethod';
import { MakeOptional } from '@/types/typescriptHelpers';
import { SafetyQuestionnaire } from 'drawbridge.shared/models/safetyQuestionnaire';
import { VisitDetails } from 'drawbridge.shared/models/visitDetails';
import { VisitState } from '@state/visitState';
import { createApiThunk } from '@/types/reduxHelpers';

export const visitorSignedIn = createAction<Visit>('visit/visitorSignedIn');
export const visitorSignedOut = createAction<Visit>('visit/visitorSignedOut');

export const getCurrentVisits = createApiThunk<Array<Visit>, { authMode?: 'user' | 'device'; facilityId: number }>('visit/getCurrentVisits', async (params, thunkApi) => {
    const auth = params.authMode ?? 'user';

    let response: AxiosResponse<Array<Visit>>;

    if (auth === 'user') {
        response = await callUserAuthApi<Array<Visit>>(`/visit/currentVisits/${params.facilityId}`);
    } else {
        response = await callDeviceAuthApi<Array<Visit>>(`/visit/currentVisits/${params.facilityId}`);
    }

    return response.data;
});

export const clearCurrentVisits = createAction<void>('visit/clearCurrentVisits');

export const getAllVisits = createApiThunk<Array<Visit>, { facilityId: number }>('/visit/getAllVisits', async (params, thunkApi) => {
    const response = await callUserAuthApi<Array<Visit>>(`/visit/allVisits/${params.facilityId}`);

    return response.data;
});

export const clearAllVisits = createAction<void>('/visit/clearAllVisits');

export const createEmployeeVisit = createApiThunk<EmployeeVisit, { employee: Employee; visitDetails: VisitDetails; facilityId: number; }>('visit/createEmployeeVisit', async (params, thunkApi) => {
    const response = await callDeviceAuthApi<EmployeeVisit>('/visit/createEmployeeVisit', HttpMethod.POST, {
        employeeRefId: params.employee.id,
        contactEmployeeRefId: params.visitDetails.personVisiting.id,
        visitReason: params.visitDetails.reasonForVisit,
        facilityId: params.facilityId,
    });

    return response.data;
});

export const createExternalVisitorVisit = createApiThunk<{ visit: ExternalVisitorVisit; guest?: Guest }, { externalVisitor: MakeOptional<ExternalVisitor, 'id'>; visitDetails: VisitDetails; facilityId: number; isPerformingWork: boolean; safetyQuestionnaire?: SafetyQuestionnaire; isRequestingGuestWifi?: boolean; }>('visit/createExternalVisitorVisit', async (params, thunkApi) => {
    const response = await callDeviceAuthApi<{ visit: ExternalVisitorVisit; guest?: Guest }>('/visit/createExternalVisitorVisit', HttpMethod.POST, {
        externalVisitor: params.externalVisitor,
        contactEmployeeRefId: params.visitDetails.personVisiting.id,
        visitReason: params.visitDetails.reasonForVisit,
        isPerformingWork: params.isPerformingWork,
        safetyQuestionnaire: params.safetyQuestionnaire,
        isRequestingGuestWifi: params.isRequestingGuestWifi,
        facilityId: params.facilityId,
    });

    return response.data;
});

export const getVisit = createApiThunk<Visit | undefined, { visitId: number, visitType: VisitType }>('/visit/getVisit', async (params, thunkApi) => {
    const response = await callDeviceAuthApi<Visit | undefined>(`/visit/getVisit/type/${params.visitType}/id/${params.visitId}`);

    return response.data;
});

export const signOutVisit = createApiThunk<EmployeeVisit | ExternalVisitorVisit, { authMode?: 'user' | 'device'; visitId: number; visitType: VisitType }>('visit/signOutVisit', async (params, thunkApi) => {
    const auth = params.authMode ?? 'user';

    let response: AxiosResponse<EmployeeVisit | ExternalVisitorVisit>;

    if (auth === 'user') {
        response = await callUserAuthApi<EmployeeVisit | ExternalVisitorVisit>('/visit/signOutVisit', HttpMethod.POST, {
            visitId: params.visitId,
            visitType: params.visitType,
        });
    } else {
        response = await callDeviceAuthApi<EmployeeVisit | ExternalVisitorVisit>('/visit/signOutVisit', HttpMethod.POST, {
            visitId: params.visitId,
            visitType: params.visitType,
        });
    }

    return response.data;
});

export const findVisitsByFacilityIdAndPartialVisitorName = createApiThunk<Array<Visit>, { searchString: string, facilityId: number }>('visit/findVisitsByFacilityIdAndPartialVisitorName', async (args, thunkApi) => {
    const response = await callDeviceAuthApi<Array<Visit>>(`/visit/search/byFacility/${args.facilityId}/byVisitorName/${args.searchString}`);

    return response.data;
});

const initialState: VisitState = {
    isLoading: false,
    currentVisits: [],
    allVisits: [],
};

export const visitReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getCurrentVisits.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getCurrentVisits.fulfilled, (state, action) => {
            state.isLoading = false;
            state.currentVisits = [...action.payload];
        })
        .addCase(getCurrentVisits.rejected, (state, action) => {
            state.isLoading = false;
            state.currentVisits = [];
        })
        .addCase(clearCurrentVisits, (state, action) => {
            state.currentVisits = [];
        })
        .addCase(getAllVisits.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getAllVisits.fulfilled, (state, action) => {
            state.isLoading = false;
            state.allVisits = [...action.payload];
        })
        .addCase(getAllVisits.rejected, (state, action) => {
            state.isLoading = false;
            state.allVisits = [];
        })
        .addCase(clearAllVisits, (state, action) => {
            state.allVisits = [];
        })
        .addCase(createEmployeeVisit.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(createEmployeeVisit.fulfilled, (state, action) => {
            state.isLoading = false;
            // The created visit will be added to state.currentVisits via the visitorSignedIn action triggered by sockets, don't add here!
        })
        .addCase(createEmployeeVisit.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(createExternalVisitorVisit.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(createExternalVisitorVisit.fulfilled, (state, action) => {
            state.isLoading = false;
            // The created visit will be added to state.currentVisits via the visitorSignedIn action triggered by sockets, don't add here!
        })
        .addCase(createExternalVisitorVisit.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(signOutVisit.fulfilled, (state, action) => {
            state.currentVisits = [...state.currentVisits].filter(x => !(x.id === action.payload.id && x.visitType === action.payload.visitType));
            // Update the signedOutVisit in allVisits
            state.allVisits = [...state.allVisits].map(x => x.id === action.payload.id && x.visitType === action.payload.visitType ? action.payload : x);
        })
        .addCase(visitorSignedIn, (state, action) => {
            state.currentVisits = [
                ...state.currentVisits,
                action.payload,
            ];

            state.allVisits = [
                ...state.allVisits,
                action.payload,
            ];
        })
        .addCase(visitorSignedOut, (state, action) => {
            // Remove the signedOutVisit from currentVisits if its already in there
            state.currentVisits = [...state.currentVisits].filter(x => !(x.id === action.payload.id && x.visitType === action.payload.visitType));
            // Update the signedOutVisit in allVisits
            state.allVisits = [...state.allVisits].map(x => x.id === action.payload.id && x.visitType === action.payload.visitType ? action.payload : x);
        });
});
