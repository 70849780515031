import { callDeviceAuthApi, callUserAuthApi } from '@/utility/api';

import { AxiosResponse } from 'axios';
import { Employee } from 'drawbridge.shared/models/dataModels/employee';
import { EmployeeState } from '@/store/state/employeeState';
import { createApiThunk } from '@reduxHelpers';
import { createReducer } from '@reduxjs/toolkit';

export const getEmployeeByEmployeeRefId = createApiThunk<Employee, number>('employee/getByEmployeeRefId', async (employeeRefId, thunkApi) => {
    const response = await callDeviceAuthApi<Employee>(`/employee/${employeeRefId}`);

    return response.data;
});

export const findEmployeesByFacilityAndPartialName = createApiThunk<Array<Employee>, { facilityId?: number, includeMultiFacilityEmployees?: boolean; searchString: string; authMode?: 'user' | 'device' }>('employee/findEmployeesByFacilityAndPartialName', async (args, thunkApi) => {
    let route = `/employee/search/byName/${args.searchString}/${args.includeMultiFacilityEmployees ?? false}`;

    if (!!args.facilityId) {
        route += `/${args.facilityId}`;
    }

    const auth = args.authMode ?? 'device';

    let response: AxiosResponse<Array<Employee>>;

    if (auth === 'device') {
        response = await callDeviceAuthApi<Array<Employee>>(route);
    } else {
        response = await callUserAuthApi<Array<Employee>>(route);
    }

    return response.data;
});

export const findEmployeesByFacilityAndPartialId = createApiThunk<Array<Employee>, { facilityId?: number, includeMultiFacilityEmployees?: boolean; searchString: string; authMode?: 'user' | 'device' }>('employee/findEmployeesByFacilityAndPartialId', async (args, thunkApi) => {
    let route = `/employee/search/byId/${args.searchString}/${args.includeMultiFacilityEmployees ?? false}`;

    if (!!args.facilityId) {
        route += `/${args.facilityId}`;
    }

    const auth = args.authMode ?? 'device';

    let response: AxiosResponse<Array<Employee>>;

    if (auth === 'device') {
        response = await callDeviceAuthApi<Array<Employee>>(route);
    } else {
        response = await callUserAuthApi<Array<Employee>>(route);
    }

    return response.data;
});

export const findEmployeesByFacilityAndPartialUsername = createApiThunk<Array<Employee>, { facilityId?: number, includeMultiFacilityEmployees?: boolean; searchString: string; authMode?: 'user' | 'device' }>('employee/findEmployeesByFacilityAndPartialUsername', async (args, thunkApi) => {
    let route = `/employee/search/byUsername/${args.searchString}/${args.includeMultiFacilityEmployees ?? false}`;

    if (!!args.facilityId) {
        route += `/${args.facilityId}`;
    }

    const auth = args.authMode ?? 'device';

    let response: AxiosResponse<Array<Employee>>;

    if (auth === 'device') {
        response = await callDeviceAuthApi<Array<Employee>>(route);
    } else {
        response = await callUserAuthApi<Array<Employee>>(route);
    }

    return response.data;
});

export const findEmployeesByFacilityAndPartialUsernameOrName = createApiThunk<Array<Employee>, { facilityId?: number; includeMultiFacilityEmployees?: boolean; searchString: string; authMode?: 'user' | 'device' }>('employee/findEmployeesByFacilityAndPartialUsernameOrName', async (args, thunkApi) => {
    let route = `/employee/search/byUsernameOrName/${args.searchString}/${args.includeMultiFacilityEmployees ?? false}`;

    if (!!args.facilityId) {
        route += `/${args.facilityId}`;
    }

    const auth = args.authMode ?? 'device';

    let response: AxiosResponse<Array<Employee>>;

    if (auth === 'device') {
        response = await callDeviceAuthApi<Array<Employee>>(route);
    } else {
        response = await callUserAuthApi<Array<Employee>>(route);
    }

    return response.data;
});

const initialState: EmployeeState = {
    isLoading: false,
};

export const employeeReducer = createReducer(initialState, (builder) => {
    builder
        .addDefaultCase((state, action) => {
            // no change
        });
});