import './facilityConfigForm.less';

import * as React from 'react';

import { Alert, Button, Checkbox, Col, Divider, Form, FormProps, Input, Popover, Row, Space, Spin, Tag, notification } from 'antd';
import { CloseOutlined, DownloadOutlined, LoadingOutlined, UndoOutlined } from '@ant-design/icons';
import { Document, Page } from 'react-pdf';
import { getFacilityEvacuationMapDocumentAdmin, getFacilityHealthAndSafetyDocumentAdmin, testGuestWifiConnection } from '@/store/modules';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AppButton } from '@/components/appButton';
import { FacilityConfig } from 'drawbridge.shared/models/dataModels/facilityConfig';
import { FacilityEvacuationMapDocument } from 'drawbridge.shared/models/dataModels/facilityEvacuationMapDocument';
import { FacilityHealthAndSafetyDocument } from 'drawbridge.shared/models/dataModels/facilityHealthAndSafetyDocument';
import { PdfFileSelector } from '@/components/pdfFileSelector';
import classNames from 'classnames';
import { getPdfBlobFromRawData } from '@/utility/pdf';
import { nameof } from 'ts-simple-nameof';
import { useApiErrorMessage } from '@/hooks/useApiErrorMessage';
import { useTranslation } from 'react-i18next';

interface FacilityDocuments {
    facilityEvacuationMapDocument?: File | null;
    facilityHealthAndSafetyDocument?: File | null;
}

interface FacilityConfigFormValues extends FacilityDocuments {
    id?: number;
    facilityId: number;
    receptionEmailAddress: string;
    emergencyEmailAddress: string;
    sendSignInEmail: boolean;
    sendSignOutEmail: boolean;
    isGuestWifiEnabled: boolean;
    fortinetServerUrl?: string;
}

interface FacilityConfigFormProps extends FormProps<FacilityConfigFormValues> {
    facilityId: number;
    facilityConfig?: FacilityConfig;
    showCancel?: boolean;
    isLoading?: boolean;
    onCancel?: () => void;
    cancelText?: string;
    submitText?: string;
    isReadOnly?: boolean;
}

export const FacilityConfigForm: React.FC<FacilityConfigFormProps> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm<FacilityConfigFormValues>();
    const { getErrorMessage } = useApiErrorMessage();

    const dispatch = useAppDispatch();

    const currentLocale = useAppSelector((state) => state.locale.currentLocale);

    const [areGuestWifiOptionsEnabled, setAreGuestWifiOptionsEnabled] = React.useState(props.facilityConfig?.isGuestWifiEnabled ?? false);
    const [isTestingConnection, setIsTestingConnection] = React.useState(false);
    const [isConnectionSuccessful, setIsConnectionSuccessful] = React.useState<boolean | undefined>(undefined);
    const [connectionTestResultMessage, setConnectionTestResultMessage] = React.useState('');

    const [isLoadingDocuments, setIsLoadingDocuments] = React.useState(false);
    const [existingFacilityEvacuationMapDocument, setExistingFacilityEvacuationMapDocument] = React.useState<FacilityEvacuationMapDocument | undefined>(undefined);
    const [existingFacilityEvacuationMapDocumentBlob, setExistingFacilityEvacuationMapDocumentBlob] = React.useState<Blob | undefined>(undefined);

    const [existingFacilityHealthAndSafetyDocument, setExistingFacilityHealthAndSafetyDocument] = React.useState<FacilityHealthAndSafetyDocument | undefined>(undefined);
    const [existingFacilityHealthAndSafetyDocumentBlob, setExistingFacilityHealthAndSafetyDocumentBlob] = React.useState<Blob | undefined>(undefined);
    const [newFacilityEvacuationMapDocument, setNewFacilityEvacuationMapDocument] = React.useState<File | undefined | null>(undefined);
    const [newFacilityHealthAndSafetyDocument, setNewFacilityHealthAndSafetyDocument] = React.useState<File | undefined | null>(undefined);

    React.useEffect(() => {
        form.resetFields();
    }, [props.facilityConfig]);

    React.useEffect(() => {
        const loadDocumentsAsync = async (): Promise<void> => {
            const { facilityId } = props;

            setIsLoadingDocuments(true);

            const getEvacuationMapPromise = dispatch(getFacilityEvacuationMapDocumentAdmin({
                facilityId: facilityId,
            }));

            const getHealthAndSafetyPromise = dispatch(getFacilityHealthAndSafetyDocumentAdmin({
                facilityId: facilityId,
            }));

            const [getEvacuationMapResult, getHealthAndSafetyResult] = await Promise.allSettled([
                getEvacuationMapPromise,
                getHealthAndSafetyPromise,
            ]);

            if (getEvacuationMapResult.status === 'fulfilled' && getFacilityEvacuationMapDocumentAdmin.fulfilled.match(getEvacuationMapResult.value)) {
                // Request succeeded, but may still be undefined if not found
                if (!!getEvacuationMapResult.value.payload) {
                    setExistingFacilityEvacuationMapDocument(getEvacuationMapResult.value.payload);
                    setExistingFacilityEvacuationMapDocumentBlob(getPdfBlobFromRawData(getEvacuationMapResult.value.payload.evacuationMapDocumentData));
                }
            } else {
                // Something went wrong getting the map
                notification.error({
                    message: t('Common.error'),
                    description: t('Components.Forms.FacilityConfigForm.error_loading_evacuation_map'),
                });
            }

            if (getHealthAndSafetyResult.status === 'fulfilled' && getFacilityHealthAndSafetyDocumentAdmin.fulfilled.match(getHealthAndSafetyResult.value)) {
                // Request succeeded, but may still be undefined if not found
                if (!!getHealthAndSafetyResult.value.payload) {
                    setExistingFacilityHealthAndSafetyDocument(getHealthAndSafetyResult.value.payload);
                    setExistingFacilityHealthAndSafetyDocumentBlob(getPdfBlobFromRawData(getHealthAndSafetyResult.value.payload.healthAndSafetyDocumentData));
                }
            } else {
                // Something went wrong getting the H&S document
                notification.error({
                    message: t('Common.error'),
                    description: t('Components.Forms.FacilityConfigForm.error_loading_health_and_safety_document'),
                });
            }

            setIsLoadingDocuments(false);
        };

        loadDocumentsAsync();
    }, [props.facilityId]);

    const {
        facilityId,
        facilityConfig,
        showCancel,
        isLoading,
        isReadOnly,
        onCancel,
        cancelText,
        submitText,
        ...formProps
    } = props;

    return (
        <Form
            layout="vertical"
            style={{ width: '100%' }}
            {...formProps}
            className={classNames('facility-config-form', formProps.className)}
            form={form}
            onFinish={(values) => {
                props.onFinish?.({
                    ...values,
                    facilityEvacuationMapDocument: newFacilityEvacuationMapDocument,
                    facilityHealthAndSafetyDocument: newFacilityHealthAndSafetyDocument,
                });
            }}
            onFinishFailed={(errorInfo) => form.scrollToField(errorInfo.errorFields[0]?.name ?? '', { behavior: 'smooth', block: 'center', inline: 'start' })}
        >
            <Form.Item
                name={nameof<FacilityConfigFormValues>(x => x.id)}
                initialValue={facilityConfig?.id}
                noStyle={true}
            >
                <Input type="hidden" />
            </Form.Item>

            <Form.Item
                name={nameof<FacilityConfigFormValues>(x => x.facilityId)}
                initialValue={facilityId}
                noStyle={true}
            >
                <Input type="hidden" />
            </Form.Item>

            <Divider>{t('Components.Forms.FacilityConfigForm.notification_settings_header')}</Divider>

            <Form.Item
                label={t('Components.Forms.FacilityConfigForm.reception_email_address_label')}
                name={nameof<FacilityConfigFormValues>(x => x.receptionEmailAddress)}
                rules={[{ required: true }, { type: 'email' }]}
                initialValue={facilityConfig?.receptionEmailAddress}
            >
                <Input
                    placeholder={t('Components.Forms.FacilityConfigForm.reception_email_address_placeholder')}
                    disabled={isReadOnly}
                />
            </Form.Item>

            <Form.Item
                name={nameof<FacilityConfigFormValues>(x => x.sendSignInEmail)}
                initialValue={facilityConfig?.sendSignInEmail ?? true}
                valuePropName="checked"
            >
                <Checkbox
                    disabled={isReadOnly}
                >
                    {t('Components.Forms.FacilityConfigForm.send_sign_in_emails_checkbox_text')}
                </Checkbox>
            </Form.Item>

            <Form.Item
                name={nameof<FacilityConfigFormValues>(x => x.sendSignOutEmail)}
                initialValue={facilityConfig?.sendSignOutEmail ?? true}
                valuePropName="checked"
            >
                <Checkbox
                    disabled={isReadOnly}
                >
                    {t('Components.Forms.FacilityConfigForm.send_sign_out_emails_checkbox_text')}
                </Checkbox>
            </Form.Item>

            <Divider />

            <Form.Item
                label={t('Components.Forms.FacilityConfigForm.emergency_email_address_label')}
                name={nameof<FacilityConfigFormValues>(x => x.emergencyEmailAddress)}
                rules={[{ required: true }, { type: 'email' }]}
                initialValue={facilityConfig?.emergencyEmailAddress}
            >
                <Input
                    placeholder={t('Components.Forms.FacilityConfigForm.emergency_email_address_placeholder')}
                    disabled={isReadOnly}
                />
            </Form.Item>

            <Divider>{t('Components.Forms.FacilityConfigForm.guest_wifi_header')}</Divider>

            <Form.Item
                name={nameof<FacilityConfigFormValues>(x => x.isGuestWifiEnabled)}
                rules={[{ required: true }]}
                initialValue={facilityConfig?.isGuestWifiEnabled ?? false}
                valuePropName={'checked'}
            >
                <Checkbox
                    onChange={async (e) => {
                        setAreGuestWifiOptionsEnabled(e.target.checked);
                        setIsConnectionSuccessful(undefined);
                        setConnectionTestResultMessage('');
                        await form.validateFields([nameof<FacilityConfigFormValues>(x => x.fortinetServerUrl)]);
                    }}
                >
                    {t('Components.Forms.FacilityConfigForm.is_guest_wifi_enabled_checkbox_text')}
                </Checkbox>
            </Form.Item>

            <Form.Item
                label={t('Components.Forms.FacilityConfigForm.fortinet_server_url_label')}
                name={nameof<FacilityConfigFormValues>(x => x.fortinetServerUrl)}
                rules={[{
                    validator: (_rule, value: string | null | undefined) => {
                        if (!value && areGuestWifiOptionsEnabled) {
                            // Guest wifi options are enabled, but we don't have a fortinet server entered
                            return Promise.reject(t('Components.Forms.FacilityConfigForm.fortinet_server_url_required_validation_message'));
                        }

                        const hasFortinetServerUrlChanged = form.isFieldTouched(nameof<FacilityConfigFormValues>(x => x.fortinetServerUrl));

                        if (!isConnectionSuccessful && areGuestWifiOptionsEnabled && hasFortinetServerUrlChanged) {
                            return Promise.reject(t('Components.Forms.FacilityConfigForm.test_connection_required_validation_message'));
                        }

                        return Promise.resolve();
                    },
                }]}
                initialValue={facilityConfig?.fortinetServerUrl}
            >
                <Input
                    placeholder={t('Components.Forms.FacilityConfigForm.fortinet_server_url_placeholder')}
                    disabled={isReadOnly || !areGuestWifiOptionsEnabled}
                    onChange={() => {
                        setIsConnectionSuccessful(undefined);
                        setConnectionTestResultMessage('');
                    }}
                    suffix={(
                        <Button
                            disabled={!areGuestWifiOptionsEnabled}
                            loading={isTestingConnection}
                            size="middle"
                            type="primary"
                            onClick={async () => {
                                const fortinetServerUrl = form.getFieldValue(nameof<FacilityConfigFormValues>(x => x.fortinetServerUrl));

                                if (!fortinetServerUrl) {
                                    // No  point testing

                                    return;
                                } else {
                                    setIsConnectionSuccessful(undefined);
                                    setConnectionTestResultMessage('');
                                    setIsTestingConnection(true);

                                    const action = await dispatch(testGuestWifiConnection({
                                        fortinetServerUrl: fortinetServerUrl,
                                    }));

                                    setIsTestingConnection(false);

                                    if (testGuestWifiConnection.rejected.match(action)) {
                                        // Something went wrong with the request to test the connection
                                        // This isn't a sign that the actual attempt to connect failed, more likely
                                        // that there was bad data sent or an issue contacting our own api
                                        notification.error({
                                            message: t('Common.error'),
                                            description: getErrorMessage(action.payload),
                                        });
                                    } else {
                                        // The request succeeded - check if the test was actually successful or not
                                        if (action.payload.isSuccess) {
                                            // Connection successful
                                            setIsConnectionSuccessful(true);
                                            setConnectionTestResultMessage('');
                                        } else {
                                            // Connection was not successful
                                            setIsConnectionSuccessful(false);
                                            setConnectionTestResultMessage(t(`Api.${action.payload.messageKey}`, { ...action.payload.messageReplacements }));
                                        }
                                    }

                                    await form.validateFields([nameof<FacilityConfigFormValues>(x => x.fortinetServerUrl)]);
                                }
                            }}
                        >
                            {t('Components.Forms.FacilityConfigForm.test_connection_button_text')}
                        </Button>
                    )}
                />
            </Form.Item>

            {isTestingConnection &&
                <Alert
                    type="warning"
                    message={t('Components.Forms.FacilityConfigForm.test_connection_in_progress_message')}
                    description={<LoadingOutlined />}
                    showIcon={true}
                />
            }

            {!isTestingConnection && isConnectionSuccessful === true &&

                <Alert
                    type="success"
                    message={t('Common.success')}
                    description={t('Components.Forms.FacilityConfigForm.test_connection_success_message')}
                    showIcon={true}
                />
            }

            {!isTestingConnection && isConnectionSuccessful === false &&

                <Alert
                    type="error"
                    message={t('Common.error')}
                    description={connectionTestResultMessage}
                    showIcon={true}
                />
            }

            <Divider>{t('Components.Forms.FacilityConfigForm.health_and_safety_header')}</Divider>

            <Form.Item
                label={t('Components.Forms.FacilityConfigForm.existing_health_and_safety_document_label')}
            >
                {isLoadingDocuments
                    ?
                    <Spin />
                    :
                    !!existingFacilityHealthAndSafetyDocument
                        ?
                        <Row
                            align="middle"
                            justify="start"
                        >
                            <Col
                                span={24}
                            >
                                <p>
                                    <Popover
                                        trigger="hover"
                                        placement="bottom"
                                        content={
                                            <div
                                                style={{ maxWidth: '500px', maxHeight: '500px' }}
                                            >
                                                <span>{t('Common.preview')} {existingFacilityHealthAndSafetyDocument.healthAndSafetyDocumentName}</span>
                                                <Document
                                                    file={existingFacilityHealthAndSafetyDocumentBlob}
                                                    className="pdf-document"
                                                >
                                                    <Page
                                                        className="pdf-page"
                                                        pageNumber={1}
                                                        renderMode="canvas"
                                                        renderTextLayer={false}
                                                        loading={<Spin />}
                                                        scale={2}
                                                    />
                                                </Document>
                                            </div>

                                        }
                                    >
                                        <Tag
                                            icon={<DownloadOutlined />}
                                            color={newFacilityHealthAndSafetyDocument === null ? 'error' : undefined}
                                        >
                                            {!!existingFacilityHealthAndSafetyDocumentBlob
                                                ?
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={window.URL.createObjectURL(existingFacilityHealthAndSafetyDocumentBlob)}
                                                    style={{ textDecoration: newFacilityHealthAndSafetyDocument === null ? 'line-through' : 'inherit' }}
                                                >
                                                    {existingFacilityHealthAndSafetyDocument.healthAndSafetyDocumentName}
                                                </a>
                                                :
                                                existingFacilityHealthAndSafetyDocument.healthAndSafetyDocumentName
                                            }
                                        </Tag>
                                    </Popover>

                                    &nbsp;
                                    {t('Components.Forms.FacilityConfigForm.existing_document_info', {
                                        dateModified: existingFacilityHealthAndSafetyDocument.dateModified?.toLocaleString(currentLocale, { dateStyle: 'medium', timeStyle: 'medium' }),
                                        modifiedBy: existingFacilityHealthAndSafetyDocument?.modifiedBy,
                                    })}
                                </p>
                            </Col>

                            {!isReadOnly &&
                                <Col>
                                    {newFacilityHealthAndSafetyDocument === null
                                        ?
                                        <Button
                                            size="small"
                                            danger={true}
                                            icon={<UndoOutlined />}
                                            onClick={() => setNewFacilityHealthAndSafetyDocument(undefined)}
                                        >
                                            {t('Components.Forms.FacilityConfigForm.undo_removal')}
                                        </Button>
                                        :
                                        <Button
                                            size="small"
                                            danger={true}
                                            type="primary"
                                            icon={<CloseOutlined />}
                                            onClick={() => setNewFacilityHealthAndSafetyDocument(null)}
                                        >
                                            {t('Components.Forms.FacilityConfigForm.remove_health_and_safety_document_button_text')}
                                        </Button>
                                    }
                                </Col>
                            }
                        </Row>
                        :
                        <p><strong>{t('Components.Forms.FacilityConfigForm.no_document_uploaded')}</strong></p>
                }
            </Form.Item>

            {!isReadOnly &&
                <Form.Item
                    label={t('Components.Forms.FacilityConfigForm.upload_health_and_safety_document_label')}
                >
                    <PdfFileSelector
                        onChange={(file) => setNewFacilityHealthAndSafetyDocument(file)}
                    />
                </Form.Item>
            }

            <Divider>{t('Components.Forms.FacilityConfigForm.evacuation_map_header')}</Divider>

            <Form.Item
                label={t('Components.Forms.FacilityConfigForm.existing_evacuation_map_label')}
            >
                {isLoadingDocuments
                    ?
                    <Spin />
                    :
                    !!existingFacilityEvacuationMapDocument
                        ?
                        <Row
                            align="middle"
                            justify="start"
                        >
                            <Col
                                span={24}
                            >
                                <p>
                                    <Popover
                                        trigger="hover"
                                        placement="bottom"
                                        content={
                                            <div
                                                style={{ maxWidth: '500px', maxHeight: '500px' }}
                                            >
                                                <span>{t('Common.preview')} {existingFacilityEvacuationMapDocument.evacuationMapDocumentName}</span>
                                                <Document
                                                    file={existingFacilityEvacuationMapDocumentBlob}
                                                    className="pdf-document"
                                                >
                                                    <Page
                                                        className="pdf-page"
                                                        pageNumber={1}
                                                        renderMode="canvas"
                                                        renderTextLayer={false}
                                                        loading={<Spin />}
                                                        scale={2}
                                                    />
                                                </Document>
                                            </div>

                                        }
                                    >
                                        <Tag
                                            icon={<DownloadOutlined />}
                                            color={newFacilityEvacuationMapDocument === null ? 'error' : undefined}
                                        >
                                            {!!existingFacilityEvacuationMapDocumentBlob
                                                ?
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={window.URL.createObjectURL(existingFacilityEvacuationMapDocumentBlob)}
                                                    style={{ textDecoration: newFacilityEvacuationMapDocument === null ? 'line-through' : 'inherit' }}
                                                >
                                                    {existingFacilityEvacuationMapDocument.evacuationMapDocumentName}
                                                </a>
                                                :
                                                existingFacilityEvacuationMapDocument.evacuationMapDocumentName
                                            }
                                        </Tag>
                                    </Popover>

                                    &nbsp;
                                    {t('Components.Forms.FacilityConfigForm.existing_document_info', {
                                        dateModified: existingFacilityEvacuationMapDocument.dateModified?.toLocaleString(currentLocale, { dateStyle: 'medium', timeStyle: 'medium' }),
                                        modifiedBy: existingFacilityEvacuationMapDocument?.modifiedBy,
                                    })}
                                </p>
                            </Col>

                            {!isReadOnly &&
                                <Col>
                                    {newFacilityEvacuationMapDocument === null
                                        ?
                                        <Button
                                            size="small"
                                            danger={true}
                                            icon={<UndoOutlined />}
                                            onClick={() => setNewFacilityEvacuationMapDocument(undefined)}
                                        >
                                            {t('Components.Forms.FacilityConfigForm.undo_removal')}
                                        </Button>
                                        :
                                        <Button
                                            size="small"
                                            danger={true}
                                            type="primary"
                                            icon={<CloseOutlined />}
                                            onClick={() => setNewFacilityEvacuationMapDocument(null)}
                                        >
                                            {t('Components.Forms.FacilityConfigForm.remove_evacuation_map_button_text')}
                                        </Button>
                                    }
                                </Col>
                            }
                        </Row>
                        :
                        <p><strong>{t('Components.Forms.FacilityConfigForm.no_document_uploaded')}</strong></p>
                }
            </Form.Item>

            {!isReadOnly &&
                <Form.Item
                    label={t('Components.Forms.FacilityConfigForm.upload_evacuation_map_label')}
                >
                    <PdfFileSelector
                        onChange={(file) => setNewFacilityEvacuationMapDocument(file)}
                    />
                </Form.Item>
            }

            <Form.Item
                style={{ textAlign: 'center' }}
            >
                <Space
                    direction="horizontal"
                >
                    {(showCancel ?? true) &&
                        <AppButton
                            loading={isLoading}
                            onClick={() => onCancel?.()}
                            size="middle"
                        >
                            {cancelText ?? t('Common.cancel')}
                        </AppButton>
                    }

                    <AppButton
                        htmlType="submit"
                        size="middle"
                        loading={isLoading}
                        disabled={isReadOnly}
                    >
                        {submitText ?? t('Common.submit')}
                    </AppButton>
                </Space>

            </Form.Item>
        </Form>
    );
};