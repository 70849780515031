import * as React from 'react';

import { Col, Divider, Row, notification } from 'antd';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import { AnimatedHeader } from '@/components/animatedHeader';
import { PageWrapper } from '@/components/pageWrapper';
import { VendorPerformingWorkAlert } from '@/components/vendorPerformingWorkAlert';
import { VendorSelectForm } from '@/components/forms/vendorSelectForm';
import { WelcomeHeader } from '@/components/pageHeader';
import { Zoom } from 'react-awesome-reveal';
import { isExternalVisitor } from 'drawbridge.shared/models/dataModels/externalVisitor';
import { setVisitor } from '@/store/modules/signInModule';
import { updateExternalVisitorVendor } from '@/store/modules/externalVisitorModule';
import { useApiErrorMessage } from '@/hooks/useApiErrorMessage';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useVendorValidation } from '@/hooks/useVendorValidation';

interface ReturningVisitorVendorUpdatePageProps {

}

export const ReturningVisitorVendorUpdatePage: React.FC<ReturningVisitorVendorUpdatePageProps> = (props) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useApiErrorMessage();

    const vendorValidation = useVendorValidation();
    const appRoutes = useAppRoutes();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isPerformingWork = useAppSelector((state) => state.signIn.isPerformingWork);
    const visitDetails = useAppSelector((state) => state.signIn.visitDetails);
    const visitor = useAppSelector((state) => state.signIn.visitor);

    const [isLoading, setIsLoading] = React.useState(false);

    const externalVisitor = !visitor
        ? undefined
        : visitor.visitorType === 'external'
            ? visitor
            : undefined;

    React.useEffect(() => {
        if (!isPerformingWork || !visitDetails || !isExternalVisitor(externalVisitor)) {
            // There's no way to legitimately get here without these values unless pages were skipped
            // Just go home and reset the sign in state...
            navigate(appRoutes.home.path, { replace: true });
        }
    }, []);

    if (!isPerformingWork || !visitDetails || !isExternalVisitor(externalVisitor)) {
        return <></>;
    }

    return (
        <PageWrapper
            pageTitle={<WelcomeHeader />}
            containerClassName="first-time-visitor-page"
            isNonIdeal={vendorValidation.vendorValidationFailed}
            nonIdealHeader={t('Types.VendorValidationMessage.validation_failed_header')}
            nonIdealActions={[
                { text: t('Types.VendorValidationMessage.validation_failed_restart_button_text'), action: () => navigate(appRoutes.home.path) },
            ]}
            nonIdealSubheader={(
                <div>
                    <div style={{ textAlign: 'center' }}>
                        {t('Types.VendorValidationMessage.validation_failed_message')}
                    </div>

                    <Divider />

                    <ul style={{ listStyleType: 'none' }}>
                        {vendorValidation.localizedMessages.map((localizedMessage, index) => {
                            return (
                                <li key={index}>
                                    {localizedMessage}
                                </li>
                            );
                        })}
                    </ul>

                    <Divider />

                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        {t('Types.VendorValidationMessage.validation_failed_description')}
                    </div>
                </div>
            )}
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col
                    span={24}
                >
                    <AnimatedHeader>
                        {t('Pages.ReturningVisitorVendorUpdatePage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    xxl={16}
                    xl={18}
                    lg={18}
                    md={24}
                    sm={24}
                    xs={24}
                >
                    {isPerformingWork &&
                        <VendorPerformingWorkAlert />
                    }
                    <Zoom
                        duration={200}
                        triggerOnce={true}
                    >
                        <VendorSelectForm
                            isLoading={isLoading}
                            externalVisitor={externalVisitor}
                            onFinish={async (values) => {
                                setIsLoading(true);

                                // Update the visitor so they dont have to redo they're vendor next time
                                const updateExternalVisitorAction = await dispatch(updateExternalVisitorVendor({
                                    externalVisitorId: externalVisitor.id,
                                    vendorId: values.vendor.id,
                                }));

                                if (updateExternalVisitorVendor.rejected.match(updateExternalVisitorAction)) {
                                    notification.error({
                                        message: t('Common.error'),
                                        description: getErrorMessage(updateExternalVisitorAction.payload),
                                    });

                                    setIsLoading(false);

                                    return;
                                }

                                const validationResult = await vendorValidation.getVendorValidationResult({
                                    isPerformingWork: isPerformingWork,
                                    vendorId: values.vendor.id,
                                });

                                if (!validationResult?.isValid) {
                                    setIsLoading(false);

                                    return;
                                }

                                dispatch(setVisitor(updateExternalVisitorAction.payload));

                                setIsLoading(false);

                                // We should never actually get to this page when not performing work, but might
                                // as well keep the flow the same just in case something changes down the road
                                if (isPerformingWork) {
                                    navigate(appRoutes.safety.path);
                                } else {
                                    // navigate(appRoutes.globalHealthAndSafety.path);
                                    navigate(appRoutes.guestWifiPage.path);
                                }
                            }}
                        />
                    </Zoom>

                </Col>
            </Row>
        </PageWrapper>
    );
};