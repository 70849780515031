import { createAction, createReducer } from '@reduxjs/toolkit';

import { Employee } from 'drawbridge.shared/models/dataModels/employee';
import { ExternalVisitor } from 'drawbridge.shared/models/dataModels/externalVisitor';
import { MakeOptional } from '@/types/typescriptHelpers';
import { SafetyQuestionnaire } from 'drawbridge.shared/models/safetyQuestionnaire';
import { SignInState } from '@/store/state/signInState';
import { VisitDetails } from 'drawbridge.shared/models/visitDetails';

export const setVisitor = createAction<MakeOptional<ExternalVisitor, 'id'> | Employee>('signIn/setVisitor');
export const setIsPerformingWork = createAction<boolean | undefined>('signIn/setIsPerformingWork');
export const setSafetyQuestionnaire = createAction<SafetyQuestionnaire>('signIn/setSafetyQuestionnaire');
export const setVisitDetails = createAction<VisitDetails>('signIn/setVisitDetails');
export const setIsRequestingGuestWifi = createAction<boolean>('signIn/setIsRequestingGuestWifi');
export const setHasAcceptedGlobalHealthAndSafety = createAction<boolean>('signIn/setHasAcceptedGlobalHealthAndSafety');
export const setHasAcceptedFacilityHealthAndSafety = createAction<boolean>('signIn/setHasAcceptedFacilityHealthAndSafety');
export const setHasAcceptedGlobalDisclaimer = createAction<boolean>('signIn/setHasAcceptedGlobalDisclaimer');
export const setIsSignedIn = createAction<boolean>('signIn/setIsSignedIn');

export const resetSignInState = createAction('signIn/reset');

const initialState: SignInState = {
    hasAcceptedGlobalDisclaimer: false,
    hasAcceptedGlobalHealthAndSafety: false,
    hasAcceptedFacilityHealthAndSafety: false,
    isPerformingWork: undefined,
    safetyQuestionnaire: undefined,
    isRequestingGuestWifi: undefined,
    visitDetails: undefined,
    visitor: undefined,
    isSignedIn: false,
};

export const signInReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setVisitor, (state, action) => {
            state.visitor = { ...action.payload };
        })
        .addCase(setIsPerformingWork, (state, action) => {
            state.isPerformingWork = action.payload;
        })
        .addCase(setVisitDetails, (state, action) => {
            state.visitDetails = { ...action.payload };
        })
        .addCase(setSafetyQuestionnaire, (state, action) => {
            state.safetyQuestionnaire = { ...action.payload };
        })
        .addCase(setIsRequestingGuestWifi, (state, action) => {
            state.isRequestingGuestWifi = action.payload;
        })
        .addCase(setHasAcceptedGlobalHealthAndSafety, (state, action) => {
            state.hasAcceptedGlobalHealthAndSafety = action.payload;
        })
        .addCase(setHasAcceptedFacilityHealthAndSafety, (state, action) => {
            state.hasAcceptedFacilityHealthAndSafety = action.payload;
        })
        .addCase(setHasAcceptedGlobalDisclaimer, (state, action) => {
            state.hasAcceptedGlobalDisclaimer = action.payload;
        })
        .addCase(setIsSignedIn, (state, action) => {
            state.isSignedIn = action.payload;
        })
        .addCase(resetSignInState, (state, action) => {
            // Never reassign the state object directly, i.e. state = initialState
            // Return the new value directly instead.
            // https://redux-toolkit.js.org/usage/immer-reducers#resetting-and-replacing-state
            return initialState;
        });
});