import * as React from 'react';

import { CameraOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Col, Row, Segmented } from 'antd';

import { AppButton } from '@/components/appButton';
import ReactQrReader from 'react-qr-reader';
import { VisitQrData } from 'drawbridge.shared/models/visitQrData';
import { WebcamMode } from 'drawbridge.shared/models/webcamMode';
import { useAppSelector } from '@/types/reduxHelpers';
import { useTranslation } from 'react-i18next';

interface VisitQrReaderProps {
    shouldScanOnRender?: boolean;
    onReady?: () => void;
    onScan?: (visitQrData: VisitQrData) => void;
    onError?: (err: any) => void;
}

export const VisitQrReader: React.FC<VisitQrReaderProps> = (props) => {
    const { t } = useTranslation();

    const webcamMode = useAppSelector((state) => state.deviceConfig.deviceConfig?.webcamMode);

    const [isScanning, setIsScanning] = React.useState(false);
    const [facingMode, setFacingMode] = React.useState<WebcamMode>(webcamMode ?? 'environment');

    React.useEffect(() => {
        if (props.shouldScanOnRender) {
            setIsScanning(true);
        }

        return () => setIsScanning(false);
    }, []);

    return (
        <Row
            align="middle"
            justify="center"
        >
            {!isScanning &&
                <Col span={24} style={{ textAlign: 'center' }}>
                    <AppButton
                        size="middle"
                        type="default"
                        onClick={() => setIsScanning(true)}
                    >
                        {t('Components.VisitQrReader.start_scanning')}
                    </AppButton>
                </Col>
            }

            {isScanning &&
                <Col
                    xxl={12}
                    xl={12}
                    lg={16}
                    md={16}
                    sm={16}
                    xs={16}
                >
                    <ReactQrReader
                        delay={15}
                        showViewFinder={false}
                        style={{ width: '100%' }}
                        facingMode={facingMode}
                        onLoad={() => {
                            props.onReady?.();
                        }}
                        onScan={async (data) => {
                            if (!!data) {
                                const visitData = JSON.parse(data) as VisitQrData | undefined;

                                if (!!visitData?.visitId && !!visitData?.visitType) {
                                    props.onScan?.(visitData);
                                    setIsScanning(false);
                                }
                            }
                        }}
                        onError={(err) => {
                            props.onError?.(err);

                            setIsScanning(false);
                        }}
                    />
                </Col>
            }

            <Col
                span={24}
                style={{ textAlign: 'center' }}
            >
                <Segmented
                    value={facingMode}
                    options={[{
                        label: 'Back',
                        value: 'environment',
                        icon: <VideoCameraOutlined />,
                    }, {
                        label: 'Front',
                        value: 'user',
                        icon: <CameraOutlined />,
                    }]}
                    onChange={(value) => setFacingMode(value as WebcamMode)}
                />
            </Col>
        </Row>
    );
};