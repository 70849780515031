import * as React from 'react';

import { Col, Row } from 'antd';

import { applicationInfo } from '@utility/applicationInfo';

interface FooterProps {

}

export const Footer: React.FC<FooterProps> = (props) => {

    return (
        <Row>
            <Col>
                Version: {applicationInfo.version}
            </Col>
        </Row>
    );
};