import './linamarLogo.less';

import * as React from 'react';

interface LinamarLogoProps extends React.SVGProps<SVGSVGElement> {
}

export const LinamarLogo: React.FC<LinamarLogoProps> = (props) => {
    const paths: Array<Array<string>> = [
        [
            'M1647', '4532', 'l-1597', '-1597', '468', '-468', '467', '-467', '50', '50', '50', '50', '-415',
            '415', '-415', '415', '1498', '1498', '1497', '1497', '1490', '-1490', 'c819', '-819', '1490',
            '-1494', '1490', '-1500', '0', '-5', '-187', '-197', '-415', '-425', 'l-415', '-415', '50', '-50',
            '50', '-50', '470', '470', '470', '470', '-1598', '1598', '-1597', '1597', '-1598', '-1598z',
        ],
        [
            'M1977', '4202', 'l-1267', '-1267', '1268', '-1268', '1267', '-1267', '293', '292', '292', '293',
            '-487', '487', '-488', '488', '485', '485', '485', '485', '-488', '487', '-487', '488', '490',
            '490', '490', '490', '-293', '293', '-292', '292', '-1268', '-1268z',
        ],
        [
            'M3560', '4315', 'l-415', '-415', '410', '-410', '410', '-410', '417', '417', '418', '418', '-407',
            '407', 'c-225', '225', '-410', '408', '-413', '408', '-3', '0', '-192', '-187', '-420', '-415z',
        ],
        [
            'M4540', '3335', 'l-415', '-415', '413', '-413', '413', '-412', '416', '418', '417', '418', '-410',
            '410', 'c-225', '225', '-411', '409', '-414', '409', '-3', '0', '-192', '-187', '-420', '-415z',
        ],
        [
            'M3573', '2361', 'l-402', '-407', '409', '-409', '410', '-410', '405', '405', '405', '405', '-412', '411', '-413', '411', '-402', '-406z',
        ],
    ];

    return (

        <svg 
            version="1.0" 
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 650.000000 650.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{ width: '100%', height: '100%' }}
            {...props}
        >
            <g 
                className="linamar-logo-group"
                transform="translate(0.000000,650.000000) scale(0.100000,-0.100000)"
                stroke="none"
            >
                {paths.map((path, index) => {
                    return (
                        <path key={`logoPath${index}`} d={path.join(' ')} />
                    );
                })}
            </g>
        </svg>
        
    );
};