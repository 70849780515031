import * as React from 'react';

import { Empty, Select, SelectProps, Spin, notification } from 'antd';
import { useAppDispatch, useAppSelector } from '@/types/reduxHelpers';

import Highlighter from 'react-highlight-words';
import { LabeledValue } from 'antd/lib/select';
import { Visit } from 'drawbridge.shared/models/visit';
import classNames from 'classnames';
import { debounce } from 'throttle-debounce';
import { findVisitsByFacilityIdAndPartialVisitorName } from '@/store/modules/visitModule';
import { useApiErrorMessage } from '@/hooks/useApiErrorMessage';
import { useTranslation } from 'react-i18next';

interface VisitSearchProps extends SelectProps {
    value?: Visit;
    onChange?: (value: Visit | undefined) => void;
}

export const VisitSearch: React.FC<VisitSearchProps> = (props) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useApiErrorMessage();

    const dispatch = useAppDispatch();

    const facilityId = useAppSelector((state) => state.deviceConfig.deviceConfig?.facilityId);

    const [foundVisits, setFoundVisits] = React.useState<Array<Visit>>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');
    const [labeledValue, setLabeledValue] = React.useState<LabeledValue | undefined>(undefined);

    const { ...selectProps } = props;

    const fetchRef = React.useRef(0);

    const triggerChange = (changedValue: Visit | undefined): void => {
        props.onChange?.(changedValue);
    };

    React.useEffect(() => {
        if (!!props.value?.id) {
            setLabeledValue({
                key: `${props.value.visitType}.${props.value.id}`,
                value: `${props.value.visitType}.${props.value.id}`,
                label: (
                    <span>{`${props.value.visitor?.fullName} - ${props.value.visitor?.visitorType === 'employee' ? props.value.visitor.facility?.facilityName : props.value.visitor?.company}`}</span>
                ),
            });
        } else {
            setLabeledValue(undefined);
        }
    }, [props.value]);

    React.useEffect(() => {
        const searchAsync = async (): Promise<void> => {
            fetchRef.current += 1;

            const fetchId = fetchRef.current;

            if (!!searchString && !!facilityId) {
                setIsLoading(true);

                const action = await dispatch(findVisitsByFacilityIdAndPartialVisitorName({ searchString: searchString, facilityId: facilityId }));

                if (findVisitsByFacilityIdAndPartialVisitorName.fulfilled.match(action)) {
                    // Make sure we're getting the results from the last request, and not an old one that took its sweet time to complete
                    if (fetchId === fetchRef.current) {
                        setFoundVisits(
                            [...action.payload]
                                .sort((a, b) => (a.visitor?.fullName ?? '').localeCompare((b.visitor?.fullName) ?? ''))
                        );
                    }
                } else {
                    notification.error({
                        message: t('Common.error'),
                        description: getErrorMessage(action.payload),
                    });
                }

                setIsLoading(false);
            } else {
                setFoundVisits([]);
            }
        };

        searchAsync();
    }, [searchString, facilityId]);

    const debouncedSearch = React.useCallback(debounce(500, (searchString: string) => {
        setSearchString(searchString);
    }), []);

    return (
        <Select<LabeledValue>
            {...selectProps}
            className={classNames('visit-search', selectProps.className)}
            showSearch={true}
            filterOption={false}
            labelInValue={true}
            allowClear={true}
            value={labeledValue}
            loading={isLoading}
            onBlur={() => {
                setSearchString('');
                setFoundVisits([]);
            }}
            onChange={(newValue) => {
                setLabeledValue(newValue);

                const visit = foundVisits.find(x => `${x.visitType}.${x.id}` === newValue.value);

                triggerChange(visit);

                // Clear out search when selected
                setSearchString('');
                setFoundVisits([]);
            }}
            notFoundContent={
                isLoading
                    ? <span><Spin size="small" /> {t('Common.searching')}</span>
                    : !!searchString
                        ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('Components.VisitSearch.no_visits_found')} />
                        : null
            }
            onSearch={(value) => {
                debouncedSearch(value);
            }}
        >
            {foundVisits.map((visit) => {
                return (
                    <Select.Option
                        key={`${visit.visitType}.${visit.id}`}
                        value={`${visit.visitType}.${visit.id}`}
                    >
                        <span>
                            <Highlighter
                                highlightClassName={classNames({ 'search-input-highlight': !!searchString })}
                                searchWords={!!searchString ? [searchString] : []}
                                textToHighlight={`${visit.visitor?.fullName} - ${visit.visitor?.visitorType === 'employee' ? visit.visitor.facility?.facilityName : visit.visitor?.company}`}
                            />
                        </span>
                    </Select.Option>
                );
            })}
        </Select>
    );
};