import * as React from 'react';

import { ApiOutlined } from '@ant-design/icons';
import { PageWrapper } from '@/components/pageWrapper';
import { useTranslation } from 'react-i18next';

interface OfflinePageProps {

}

export const OfflinePage: React.FC<OfflinePageProps> = (props) => {
    const { t } = useTranslation();

    return (
        <PageWrapper
            isNonIdeal={true}
            nonIdealHeader={t('Pages.OfflinePage.header')}
            nonIdealSubheader={t('Pages.OfflinePage.subheader')}
            nonIdealIconType={<ApiOutlined />}
            nonIdealActions={['reload']}
        />
    );
};