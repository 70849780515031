import './signOutBySearchPage.less';

import * as React from 'react';

import { Col, Form, Row } from 'antd';

import { AnimatedHeader } from '@/components/animatedHeader';
import { AppButton } from '@/components/appButton';
import { FarewellHeader } from '@/components/pageHeader';
import { PageWrapper } from '@/components/pageWrapper';
import { SignOutModal } from '@/components/signOutModal';
import { Visit } from 'drawbridge.shared/models/visit';
import { VisitSearch } from '@/components/visitSearch';
import { Zoom } from 'react-awesome-reveal';
import { nameof } from 'ts-simple-nameof';
import { useAppRoutes } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface SignOutBySearchValues {
    visit: Visit;
}
interface SignOutBySearchPageProps {

}

export const SignOutBySearchPage: React.FC<SignOutBySearchPageProps> = (props) => {
    const { t } = useTranslation();

    const [form] = Form.useForm<SignOutBySearchValues>();

    const navigate = useNavigate();
    const appRoutes = useAppRoutes();

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [visit, setVisit] = React.useState<Visit | undefined>(undefined);
    const [isMounted, setIsMounted] = React.useState(false);

    React.useEffect(() => {
        setIsMounted(true);

        return () => setIsMounted(false);
    }, []);

    return (
        <PageWrapper
            pageTitle={<FarewellHeader />}
            containerClassName="sign-out-by-search-page"
        >
            <Row
                align="middle"
                justify="space-around"
                gutter={20}
            >
                <Col
                    span={24}
                >
                    <AnimatedHeader>
                        {t('Pages.SignOutBySearchPage.header')}
                    </AnimatedHeader>
                </Col>

                <Col
                    xxl={16}
                    xl={18}
                    lg={18}
                    md={24}
                    sm={24}
                    xs={24}
                >
                    <Zoom
                        duration={300}
                        triggerOnce={true}
                    >
                        <Form
                            form={form}
                            className="sign-out-by-search-form"
                            layout="vertical"
                            labelAlign="right"
                            autoComplete="off"
                            onFinish={(values) => {
                                setVisit(values.visit);
                                setIsModalOpen(true);
                            }}
                        >
                            <Form.Item
                                label={t('Pages.SignOutBySearchPage.visit_label')}
                                className="visit-details-form-item"
                                name={nameof<SignOutBySearchValues>(x => x.visit)}
                                rules={[{ required: true }]}
                            >
                                <VisitSearch
                                    placeholder={t('Pages.SignOutBySearchPage.visit_placeholder')}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Row>
                                    <Col
                                        span={24}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <AppButton
                                            htmlType="submit"
                                            className="sign-out-by-search-form-button"
                                        >
                                            {t('Pages.SignOutBySearchPage.sign_out_button_text')}
                                        </AppButton>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Zoom>
                </Col>
            </Row>

            {!!visit &&
                <SignOutModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSignedOut={() => {
                        if (isMounted) {
                            setVisit(undefined);
                        }

                        navigate(appRoutes.home.path);
                    }}
                    visit={visit}
                />
            }
        </PageWrapper>
    );
};