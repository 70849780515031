import { Employee } from './employee';
import { MakeOptional } from '../../types/typescriptHelpers';
import { Vendor } from './vendor';

export interface ExternalVisitor {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    phoneNumber?: string;
    email?: string;
    company: string;
    vendorId?: number;
    vendor?: Vendor;
    isDeleted?: boolean;
    dateCreated?: Date;
    createdBy?: string;
    dateModified?: Date;
    modifiedBy?: string;

    readonly visitorType: 'external';
}

export const isExternalVisitor = (visitor: Employee | ExternalVisitor | MakeOptional<ExternalVisitor, 'id'> | undefined): visitor is ExternalVisitor => {
    return visitor?.visitorType === 'external' && !!visitor?.id;
};