import { callApi, callDeviceAuthApi, callUserAuthApi } from '@/utility/api';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { DeviceConfig } from 'drawbridge.shared/models/dataModels/deviceConfig';
import { DeviceConfigState } from '@/store/state/deviceConfigState';
import { DeviceConfigTokenPayload } from 'drawbridge.shared/models/payloads/deviceConfigPayload';
import { HttpMethod } from 'drawbridge.shared/constants/httpMethod';
import { HttpStatusCode } from 'drawbridge.shared/constants/httpStatusCode';
import { MakeOptional } from '@/types/typescriptHelpers';
import { createApiThunk } from '@/types/reduxHelpers';

export const loginDevice = createApiThunk<DeviceConfigTokenPayload, { deviceConfigId: number }>('/deviceConfig/loginDevice', async (args, thunkApi) => {
    const response = await callUserAuthApi<DeviceConfigTokenPayload>('/deviceConfig/loginDevice', HttpMethod.POST, {
        deviceConfigId: args.deviceConfigId,
    });

    return response.data;
});

export const logoutDevice = createApiThunk<void, void>('/deviceConfig/logoutDevice', async (_noInput, thunkApi) => {
    await callDeviceAuthApi('/deviceConfig/logoutDevice', HttpMethod.POST);
});

const deviceRefreshTokenPromise = createAction<Promise<DeviceConfigTokenPayload>>('/deviceConfig/deviceRefreshTokenPromise');
const deviceRefreshComplete = createAction<DeviceConfigTokenPayload>('/deviceConfig/deviceRefreshComplete');
export const refreshDeviceToken = createApiThunk<DeviceConfigTokenPayload, boolean>('/deviceConfig/refreshDeviceToken', (autoLogOut, thunkApi) => {
    const state = thunkApi.getState();
    const deviceRefreshToken = state.deviceConfig.deviceRefreshToken;
    const deviceUUID = state.security.deviceUUID;

    const promise = callApi<DeviceConfigTokenPayload>('/deviceConfig/refreshDeviceToken', HttpMethod.POST, {
        deviceRefreshToken: deviceRefreshToken,
        deviceUUID: deviceUUID,
    }).then((response) => {
        thunkApi.dispatch(deviceRefreshComplete(response.data));

        return response.data;
    }).catch((err) => {
        if (autoLogOut) {
            thunkApi.dispatch(logoutDevice());
        }

        throw (err);
    });

    thunkApi.dispatch(deviceRefreshTokenPromise(promise));

    return promise;
});

export const getDeviceConfigById = createApiThunk<DeviceConfig | undefined, { deviceConfigId: number }>('/deviceConfig/getDeviceConfigById', async (params, thunkApi) => {
    const response = await callDeviceAuthApi<DeviceConfig | undefined>(`/deviceConfig/id/${params.deviceConfigId}`);

    return response.data;
});

export const getDeviceConfigByFacilityAndDeviceName = createApiThunk<DeviceConfig | undefined, { deviceName: string, facilityId: number }>('/deviceConfig/getDeviceConfigByDeviceName', async (params, thunkApi) => {
    const response = await callUserAuthApi<DeviceConfig | undefined>(`/deviceConfig/facility/${params.facilityId}/deviceName/${params.deviceName}`);

    return response.data;
});

export const createDeviceConfig = createApiThunk<DeviceConfig, MakeOptional<DeviceConfig, 'id'>>('/deviceConfig/createDeviceConfig', async (params, thunkApi) => {
    const response = await callUserAuthApi<DeviceConfig>('/deviceConfig/createDeviceConfig', HttpMethod.POST, {
        ...params,
    });

    return response.data;
});

export const updateDeviceConfig = createApiThunk<DeviceConfig, DeviceConfig>('/deviceConfig/updateDeviceConfig', async (deviceConfig, thunkApi) => {
    const response = await callUserAuthApi<DeviceConfig>('/deviceConfig/updateDeviceConfig', HttpMethod.PUT, {
        ...deviceConfig,
    });

    return response.data;
});

export const getAllDeviceConfigs = createApiThunk<Array<DeviceConfig>, void>('/deviceConfig/getAll', async (_noInput, thunkApi) => {
    const response = await callUserAuthApi<Array<DeviceConfig>>('/deviceConfig/getAll');

    return response.data;
});

export const deleteDeviceConfig = createApiThunk<{ id: number }, { deviceConfigId: number }>(`/deviceConfig/deleteDeviceConfig`, async (params, thunkApi) => {
    const response = await callUserAuthApi<{ id: number }>(`/deviceConfig/${params.deviceConfigId}`, HttpMethod.DELETE);

    return response.data;
});

export const setDeviceConfig = createAction<DeviceConfig | undefined>('/deviceConfig/setDeviceConfig');
export const deviceConfigChanged = createAction<DeviceConfig | undefined>('/deviceConfig/deviceConfigUpdated');

const initialState: DeviceConfigState = {
    isLoading: false,
    deviceConfig: undefined,
    deviceAccessToken: undefined,
    deviceRefreshToken: undefined,
    isRefreshingToken: false,
    refreshPromise: undefined,
    hasError: false,
};

export const deviceConfigReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(loginDevice.fulfilled, (state, action) => {
            state.deviceConfig = action.payload.deviceConfig;
            state.deviceAccessToken = action.payload.deviceAccessToken;
            state.deviceRefreshToken = action.payload.deviceRefreshToken;
            state.isRefreshingToken = false;
        })
        .addCase(logoutDevice.fulfilled, (state, action) => {
            state.deviceConfig = undefined;
            state.deviceAccessToken = undefined;
            state.deviceRefreshToken = undefined;
            state.isRefreshingToken = false;
        })
        .addCase(deviceRefreshTokenPromise, (state, action) => {
            state.refreshPromise = action.payload;
            state.isRefreshingToken = true;
        })
        .addCase(refreshDeviceToken.pending, (state, action) => {
            state.isRefreshingToken = true;
        })
        .addCase(refreshDeviceToken.rejected, (state, action) => {
            state.refreshPromise = undefined;
            state.isRefreshingToken = false;

            if (action.payload?.status === HttpStatusCode.UNAUTHORIZED || action.payload?.status === HttpStatusCode.FORBIDDEN) {
                // Lets try only clearing out the saved device auth if we actually got back an 'unauthorized' or 'forbidden' response
                // Otherwise, other API errors will cause the deviceConfig to be wiped out
                state.deviceConfig = undefined;
                state.deviceAccessToken = undefined;
                state.deviceRefreshToken = undefined;
            } else {
                // If we got some other error, set it in state so that we can still show an error screen but not wipe out the
                // device config and make them reconfigure
                state.hasError = true;
            }
        })
        .addCase(deviceRefreshComplete, (state, action) => {
            state.isRefreshingToken = false;
            state.refreshPromise = undefined;
            state.deviceConfig = action.payload.deviceConfig;
            state.deviceAccessToken = action.payload.deviceAccessToken;
            state.deviceRefreshToken = action.payload.deviceRefreshToken;
        })
        .addCase(deviceConfigChanged, (state, action) => {
            state.deviceConfig = action.payload;
        })
        .addCase(getDeviceConfigById.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getDeviceConfigById.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        .addCase(getDeviceConfigById.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(createDeviceConfig.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(createDeviceConfig.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        .addCase(createDeviceConfig.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(setDeviceConfig, (state, action) => {
            state.deviceConfig = action.payload ?? undefined;
        })
        .addCase(updateDeviceConfig.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(updateDeviceConfig.fulfilled, (state, action) => {
            state.isLoading = false;

            if (state.deviceConfig?.id === action.payload.id) {
                state.deviceConfig = action.payload;
            }
        })
        .addCase(updateDeviceConfig.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(deleteDeviceConfig.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(deleteDeviceConfig.fulfilled, (state, action) => {
            state.isLoading = false;

            if (state.deviceConfig?.id === action.payload.id) {
                state.deviceConfig = undefined;
            }
        })
        .addCase(deleteDeviceConfig.rejected, (state, action) => {
            state.isLoading = false;
        });
});