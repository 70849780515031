import { WSIBCertificate } from 'drawbridge.shared/models/wsibCertificate';
import { WSIBState } from '@/store/state/wsibState';
import { callUserAuthApi } from '@/utility/api';
import { createApiThunk } from '@reduxHelpers';
import { createReducer } from '@reduxjs/toolkit';

export const getWsibClearanceByAccountNumber = createApiThunk<WSIBCertificate, string>('wsib/getClearanceByAccountNumber', async (accountNumber, thunkApi) => {
    const response = await callUserAuthApi<WSIBCertificate>(`/wsib/getClearanceByAccountNumber/${accountNumber}`);

    return response.data;
});

const initialState: WSIBState = {
    isLoading: false,
};

export const wsibReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getWsibClearanceByAccountNumber.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getWsibClearanceByAccountNumber.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        .addCase(getWsibClearanceByAccountNumber.rejected, (state, action) => {
            state.isLoading = false;
        });
});