import './signOutModal.less';

import * as React from 'react';

import { Col, Modal, Row, notification } from 'antd';

import { AppButton } from '@/components/appButton';
import { Visit } from 'drawbridge.shared/models/visit';
import { VisitCard } from '@/components/visitCard';
import { signOutVisit } from '@/store/modules/visitModule';
import { useApiErrorMessage } from '@/hooks/useApiErrorMessage';
import { useAppDispatch } from '@/types/reduxHelpers';
import { useTranslation } from 'react-i18next';

interface SignOutModalProps {
    isOpen: boolean;
    onClose?: () => void;
    visit: Visit;
    onSignedOut: () => void;
}

export const SignOutModal: React.FC<SignOutModalProps> = (props) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useApiErrorMessage();

    const dispatch = useAppDispatch();

    const { isOpen, onClose, onSignedOut, visit } = props;

    const [isLoading, setIsLoading] = React.useState(false);
    const [isMounted, setIsMounted] = React.useState(false);

    React.useEffect(() => {
        setIsMounted(true);

        return () => {
            setIsMounted(false);
        };
    }, []);

    return (
        <Modal
            className="sign-out-modal"
            visible={isOpen}
            title={t('Components.SignOutModal.title')}
            onCancel={() => onClose?.()}
            closable={!isLoading}
            maskClosable={!isLoading}
            okButtonProps={{ className: 'sign-out-by-search-confirmation-button' }}
            cancelButtonProps={{ className: 'sign-out-by-search-confirmation-button' }}
            footer={
                <Row
                    align="middle"
                    justify="center"
                    gutter={20}
                >
                    <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={24}
                        style={{ textAlign: 'center' }}
                    >
                        <AppButton
                            style={{ width: '100%' }}
                            size="middle"
                            onClick={() => onClose?.()}
                            disabled={isLoading}
                        >
                            {t('Common.cancel')}
                        </AppButton>
                    </Col>

                    <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={24}
                        style={{ textAlign: 'center' }}
                    >
                        <AppButton
                            style={{ width: '100%' }}
                            size="middle"
                            loading={isLoading}
                            onClick={async () => {
                                if (!visit) {
                                    return;
                                }

                                setIsLoading(true);

                                const action = await dispatch(signOutVisit({
                                    visitId: visit.id ?? -1,
                                    visitType: visit?.visitType,
                                    authMode: 'device',
                                }));

                                if (signOutVisit.fulfilled.match(action)) {
                                    notification.success({
                                        message: t('Components.SignOutModal.sign_out_successful_header'),
                                        description: t('Components.SignOutModal.sign_out_successful_message', { name: visit.visitor?.fullName }),
                                    });

                                    onSignedOut();
                                    onClose?.();

                                } else {
                                    notification.error({
                                        message: t('Common.unexpected_error'),
                                        description: getErrorMessage(action.payload),
                                    });
                                }

                                if (isMounted) {
                                    setIsLoading(false);
                                }
                            }}
                        >
                            {t('Components.SignOutModal.sign_out_button_text')}
                        </AppButton>
                    </Col>
                </Row>
            }
        >
            {!!visit &&
                <VisitCard
                    visit={visit}
                />
            }
        </Modal>
    );
};